import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDgRtpN2cNiAJOSHgB4aa0Ba2VwsXRE2Dk",
  authDomain: "giggy-e267d.firebaseapp.com",
  projectId: "giggy-e267d",
  storageBucket: "giggy-e267d.appspot.com",
  messagingSenderId: "290425126076",
  appId: "1:290425126076:web:24146d04a95de4925ae4b9",
  measurementId: "G-Y9WRQ61LSR"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
