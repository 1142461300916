import React, { useEffect } from 'react';
import Modal from 'react-modal';

const TextInputModal = ({ isOpen, text, onChange, onClose, onSubmit }) => {
  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.key === 'Enter') {
  //       onSubmit();
  //     }
  //   };

  //   if (isOpen) {
  //     window.addEventListener('keydown', handleKeyDown);
  //   } else {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   }

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [isOpen, onSubmit]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onSubmit();
      }
    };
  
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onSubmit]);
  

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Text Input"
      ariaHideApp={false}
      className="text-edit-modal-content"
      overlayClassName="text-edit-overlay"
    >
      <h2>Edit Text</h2>
      <textarea value={text} onChange={onChange} />
      <div>
        <button onClick={onSubmit}>Submit</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </Modal>
  );
};

export default TextInputModal;
