import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import Modal from 'react-modal';
// import { SocketContext } from './App'; // Import the context
import MainTopNav from './MainTopNav';
import LoadingDelight from './LoadingDelight';
import ThumbnailLeftNav from './ThumbnailLeftNav';
import store from './redux/store';
import { v4 as uuidv4 } from 'uuid';
import { 
  setLoadingDelightType
} from './redux/UsersSlice';
import { 
  // fetchSessions, 
  addSession, 
  updateSession,
  addCompositingQueue,
  addGeneratingQueue,
  setSessionThumbnails,
  setSelectedCharacterId,
  setTriggerCreateSessionFromEditor,
  setIsCreatingSession,
  setTriggerGenerateMore,
} from './redux/ThumbnailSessionsSlice';
import { 
  setUserCreditsBalance,
} from './redux/UsersSlice';
import axios from 'axios';
import './ThumbnailTools.css';
import { FaPlusCircle } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleRight, faXmark, faPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons'; // Replace faSomeIcon with the specific icon you're using
// import { reset } from 'canvas-confetti';
// const { v4: uuidv4 } = require('uuid');

Modal.setAppElement('#root');

const ThumbnailCreateUI = ({ 
  manageThumbnailCompositingQueue, 
  saveCanvas, 
  initGenerationChecker 
}) => {
  const thumbnailGenerationCost = 40
  // const userCreditsBalance = useSelector((state) => state.user.userCreditsBalance);
  const autoseed = false
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();
  const { selectedSessionId: paramSessionId } = useParams(); // Rename to avoid conflict
  const [selectedSessionId, setSelectedSessionId] = useState(paramSessionId)
  // const socket = useContext(SocketContext); // Access the socket from context
  const [step, setStep] = useState('1');
  const [priorStep, setPriorStep] = useState('1');
  const [imageError, setImageError] = useState('');

  const selectedCharacterId = useSelector((state) => state.thumbnailSessions.selectedCharacterId);
  const [videoTitle, setVideoTitle] = useState('');
  const [thumbnailPremises, setThumbnailPremises] = useState([]);
  const [thumbnailCaptions, setThumbnailCaptions] = useState([]);
  const [thumbnailImageryMode, setThumbnailImageryMode] = useState(''); //title or url
  const [thumbnailSeedUrl, setThumbnailSeedUrl] = useState('');
  const [thumbnailHasCharacter, setHasCharacter] = useState(false); //true / false
  const [thumbnailSeedVideoId, setThumbnailSeedVideoId] = useState('');

  const [thumbnailSeedPreviewUrl, setThumbnailSeedPreviewUrl] = useState('');
  // const [selectedCharacterUrl, setSelectedCharacterUrl] = useState(null);
  // const [thumbnailText, setThumbnailText] = useState('get rich quick');
  // const [selectedCharacterId, setSelectedCharacterId] = useState(null);
  const [characterUrls, setCharacterUrls] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingSession, setIsEditingSession] = useState(false);
  const [isAddingCustomPremise, setIsAddingCustomPremise] = useState(false); // Track if user is adding a custom premise
  const [customPremiseInput, setCustomPremiseInput] = useState(''); // Track
  const [editingPremiseIndex, setEditingPremiseIndex] = useState(null);
  const [isAddingCustomCaption, setIsAddingCustomCaption] = useState(false);
  const [customCaptionInput, setCustomCaptionInput] = useState('');
  const [editingCaptionIndex, setEditingCaptionIndex] = useState(null);
  const triggerGenerateMore = useSelector((state) => state.thumbnailSessions.triggerGenerateMore);
  
  // const [isLoading, setIsLoading] = useState(false);
  const userId = useSelector(state => state.user.userId);
  const sessions = useSelector(state => state.thumbnailSessions.sessions);
  // const isCreatingSession = useSelector((state) => state.thumbnailSessions.isCreatingSession);
  // const isCreatingNewSession = useSelector((state) => state.thumbnailSessions.isCreatingNewSession);
  // const [isFetchingSession, setIsFetchingSession] = useState(false); //helps dedupe session fetching
  const [isAwaitingThumbnailsForSession, setIsAwaitingThumbnailsForSession] = useState(false);
  const [isGeneratingThumbnailsForSession, setIsGeneratingThumbnailsForSession] = useState(false);
  const sessionThumbnails = useSelector((state) => state.thumbnailSessions.sessionThumbnails);
  const loadingDelightType = useSelector((state) => state.user.loadingDelightType);
  const checkHasSessions = useSelector((state) => state.thumbnailSessions.checkHasSessions);
  const hasModalOpened = useSelector((state) => state.thumbnailSessions.hasModalOpened);
  // const triggerCreateSessionFromEditor = useSelector((state) => state.thumbnailSessions.triggerCreateSessionFromEditor);
  // const isReturningFromEditor = useSelector((state) => state.thumbnailSessions.isReturningFromEditor);
  const [titleError, setTitleError] = useState('');
  const [premiseError, setPremiseError] = useState('');
  const [captionError, setCaptionError] = useState('');
  const [selectedSession, setSelectedSession] = useState();

  // const [generationCheckerInterval, setGenerationCheckerInterval] = useState(null);
  const compositingQueue = useSelector((state) => state.thumbnailSessions.compositingQueue);
  const generatingQueue = useSelector((state) => state.thumbnailSessions.generatingQueue);
  const [hotTip, setHotTip] = useState("");
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth <= 481 : false
  );
  const allowedCharsRegex = /^[\s\S]*$/;

  // const saveModalParams = useCallback(() => {
  //   return new Promise(async (resolve, reject) => {
  //     if (isEditingSession) {
  //       setIsEditingSession(false);
  //       try {
  //         const response = await axios.post('/api/edit-tubethumbsy-session', { 
  //           selectedSessionId,
  //           selectedCharacterId,
  //           thumbnailPremises,
  //           thumbnailCaptions,
  //           thumbnailImageryMode,
  //           thumbnailSeedUrl,
  //           thumbnailHasCharacter,
  //         });
  //         resolve(response); // Resolve the promise with the response data
  //       } catch (error) {
  //         console.error('Error generating more thumbnails:', error);
  //         reject(error); // Reject the promise with the error
  //       }
  //     } else {
  //       resolve('No session is being edited'); // Resolve in case `isEditingSession` is false
  //     }
  //   });
  // }, [
  //   isEditingSession,
  //   selectedSessionId,
  //   selectedCharacterId,
  //   thumbnailPremises,
  //   thumbnailCaptions,
  //   thumbnailImageryMode,
  //   thumbnailSeedUrl,
  //   thumbnailHasCharacter,
  // ]);

  const resetSession = useCallback(() => {
    // console.log('resetSession')
    setStep('1')
    dispatch(setSessionThumbnails([]))
    setSelectedSessionId(undefined)
    // Clear all the states for when user goes to /create to create a new session
    setVideoTitle('')
    // setVideoTitle("Give me 8 minutes, and I'll improve your communication skills by 88%.")
    setThumbnailSeedUrl('')
    setThumbnailPremises([])
    setThumbnailCaptions([])
    setSelectedSession()
    dispatch(setSelectedCharacterId(null))
    // setSelectedCharacterUrl(null)
    setThumbnailImageryMode('')
    setThumbnailSeedPreviewUrl('')
    setHasCharacter(false)
    setHotTip('Create a new session from the left menu')
  },[dispatch])

  const handleStartNewSession = useCallback(async () => {
    console.log('handleStartNewSession')
    resetSession()
    navigate(`/thumbnails/create/new`);
  },[]);
  
  // const closeModal = useCallback(async (isCreatingNewSession) => {
  //   // console.log('closemodal')
  //   dispatch(setIsModalOpen(false));
  //   await saveModalParams(); // Call the memoized saveModalParams function
  //   // if (isCreatingNewSession) {
  //   //   // This eventually redirects back ot most recent session
  //   if (isEditingSession) {
  //     navigate(`/thumbnails/create`);
  //   }
  //   // } else if (sessions && sessions.length>0) {
  //   //   let recentSessionId = sessions[0].sessionId;
  //   //   navigate(`/thumbnails/create/${recentSessionId}`);
  //   // }
  //   setStep('1');
  // }, []);

  useEffect(() => { 
    if (triggerGenerateMore) {
      setTriggerGenerateMore(false)
      setIsLoading(true)
      handleGenerateMore()
    }
  },[triggerGenerateMore])
  
  // useEffect(() => {
  //   // if (!checkHasSessions) {
  //   //   handleStartNewSession()
  //   // } else 
  //   if (window.innerWidth <= 481 && !hasModalOpened) {
  //     handleStartNewSession();
  //     // dispatch(setHasModalOpened(true))
  //     // console.log(hasModalOpened);
  //     // console.log('Modal opened for mobile');
  //   }
  // }, [checkHasSessions, handleStartNewSession, dispatch, hasModalOpened])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 481);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const getSelectedSession = useCallback(async (selectedSessionId) => {
    // console.log('getSelectedSession')
    dispatch(setLoadingDelightType('none'))
    setIsLoading(true)
    const existingSession = sessions.find((session) => session.sessionId === selectedSessionId);
    if (existingSession) {
      dispatch(updateSession(existingSession));
      setSelectedSession(existingSession);
    } else {
      try {
        const response = await axios.post('/api/get-selected-tubethumbsy-session', { selectedSessionId });
        dispatch(addSession(response.data.session));
        setSelectedSession(response.data.session);
      } catch (error) {
        console.error('Error fetching session:', error);
      }
    }
  }, [sessions]);

  const getSecret = async () => {
    const response = await axios.post('/api/get-tubethumbsy-last-50-thumbnails', { selectedSessionId });
    let compositingNeeded = false;
    let sessionThumbnails = [];
    response.data.forEach(element => {
      if (element.thumbnailStatus === 'composited') {
        let timestamp = Date.now();
        element.thumbnailJPEG = `https://tubethumbs-thumbnail-previews.s3.us-west-1.amazonaws.com/${element.thumbnailId}.jpeg?t=${timestamp}`;
      } 
      sessionThumbnails.push(element);
    });
    dispatch(setSessionThumbnails(sessionThumbnails));
  } 
  
  useEffect(() => {
    if (paramSessionId === 'new') {
      resetSession()
      // handleStartNewSession()
    } else if (paramSessionId === 'secretz') {
    console.log(paramSessionId)
    getSecret()
    } else if (paramSessionId && paramSessionId !== 'new' && paramSessionId !== 'secretz') {
      console.log(paramSessionId)
      setSelectedSessionId(paramSessionId); // Set it from params when available
      setHotTip("While we utilize cutting-edge AI technology, it's not always perfect. For optimal results, we recommend generating multiple thumbnail options and fine-tuning details like text using our thumbnail editor. The quality of any photos you uploaded will directly influence the final output.")
      initGenerationChecker()
    } else if (paramSessionId === undefined) {
      console.log(paramSessionId)
      if (checkHasSessions === true) {
        if (sessions && sessions.length > 0) {
          handleGoMostRecentSession()
        } else {
          navigate('/thumbnails/create/new');
        }
      }
    }
  }, [paramSessionId, checkHasSessions]);

  // useEffect(() => {
  //   // console.log(sessions)
  // }, [sessions, selectedSessionId]); // Dependencies

  useEffect(() => {
    if (selectedSessionId && selectedSessionId !=='new' && selectedSessionId !=='secretz') {
      getSelectedSession(selectedSessionId);
      const triggerCreateSessionFromEditor = store.getState().thumbnailSessions.triggerCreateSessionFromEditor;
      if (triggerCreateSessionFromEditor) {
        handleStartNewSession();
        dispatch(setTriggerCreateSessionFromEditor(false));
      }
    }
  }, [selectedSessionId]);

  useEffect(() => {
    // console.log(sessionThumbnails.length)
    // This checks for ungenerated thumbnails and tries again
    let compositedCount = 0
    let sessionId = ''
    if (sessionThumbnails) {
      sessionThumbnails.forEach(element => {
        // console.log(element)
        // Calculate the difference between the server current time and thumbnail creation time
        if (sessionId === '') {
          sessionId = element.sessionId
        }
        if (element.thumbnailStatus === 'generating') {
          const newElement = { ...element }; // Create a shallow copy of the object
          newElement.thumbnailPremises = thumbnailPremises;
          newElement.thumbnailCaptions = thumbnailCaptions;
          newElement.clientRetrievalTime = Date.now();
          dispatch(addGeneratingQueue(newElement));
        } else if (element.thumbnailStatus === 'composited') {
          compositedCount++
        }
      });
      // Close modal and move to session when one thumbnail has been created
      // console.log(isAwaitingThumbnailsForSession)
      if (sessionThumbnails.length===0 && paramSessionId!=='new') {
        setIsLoading(true)
      } else {
        if (!isAwaitingThumbnailsForSession && compositedCount===sessionThumbnails.length) {
          setIsLoading(false)
          setIsGeneratingThumbnailsForSession(false)
        }
        // Odd number triggers closure of loading window
        if (compositedCount % 2 !== 0) {
          setIsLoading(false)
          setIsGeneratingThumbnailsForSession(false)
        }
      }
    }
  }, [sessionThumbnails, isAwaitingThumbnailsForSession]);

  const getThumbnails = useCallback(async (reason) => {
    // console.log('getThumbnails')
    if (userId && selectedSessionId) {
      try {
        const response = await axios.post('/api/get-tubethumbsy-session-thumbnails', { userId, selectedSessionId });
        let compositingNeeded = false;
        let sessionThumbnails = [];
        let compositedCount = 0;
        response.data.forEach(element => {
          if (element.thumbnailStatus === 'composited') {
            compositedCount++
            let timestamp = Date.now();
            element.thumbnailJPEG = `https://tubethumbs-thumbnail-previews.s3.us-west-1.amazonaws.com/${element.thumbnailId}.jpeg?t=${timestamp}`;
          } else if (element.thumbnailStatus === 'generated') {
            compositingNeeded = true;
            dispatch(addCompositingQueue(element));
          }
          sessionThumbnails.push(element);
        });
        dispatch(setSessionThumbnails(sessionThumbnails));
        if (compositingNeeded) {
          manageThumbnailCompositingQueue();
        }
        // if (!isAwaitingThumbnailsForSession && !triggerGenerateMore && compositedCount===response.data.length) {
        //   console.log('close loading due to thumbnails loaded')
        //   setIsLoading(false)
        // }
      } catch (error) {
        console.error('Error fetching thumbnails:', error);
      }
    }
  }, [userId, selectedSessionId, dispatch, manageThumbnailCompositingQueue]);
  
  useEffect(() => {
    if (autoseed) {
      let array = [
        "How to Start a Successful YouTube Channel in 2024",
        "10 Productivity Hacks for Entrepreneurs",
      ]
      let videoTitle = array[Math.floor(Math.random() * array.length)];
      setVideoTitle(videoTitle)
    }
    if (selectedSession) {
      setThumbnailPremises(selectedSession.thumbnailPremises || []);
      setThumbnailCaptions(selectedSession.thumbnailCaptions || []);
      setThumbnailImageryMode(selectedSession.thumbnailImageryMode || '');
      setVideoTitle(selectedSession.sessionTitle || '');
      setThumbnailSeedUrl('https://www.youtube.com/watch?v='+selectedSession.thumbnailSeedVideoId)
      setThumbnailSeedPreviewUrl('https://img.youtube.com/vi/'+selectedSession.thumbnailSeedVideoId+'/hqdefault.jpg')
      dispatch(setSelectedCharacterId(selectedSession.characterId))
      // Get thumbnails for this session
      getThumbnails('selectedSession update');
    }
  }, [selectedSession]); // Dependencies

  useEffect(() => {
    if (step === '2a') {
      setPriorStep('1')
    } else if (step === '2b' || step === '2c') {
      setPriorStep('2a')
    } else if (step === '3a') {
      setPriorStep('2a')
    } else if (step === '3b' || step === '3c') {
      setPriorStep('3a')
    } else if (step === '4') {
      setPriorStep('3a')
    }
  }, [step])
  
  const handleSetVideoTitle = () => {
    // Remove all line breaks from the video title
    const sanitizedTitle = videoTitle.replace(/\n/g, '').trim();
  
    // Check length and prevent excess length (>150 chars)
    if (sanitizedTitle.length < 10 || sanitizedTitle.length > 100) {
      setTitleError('Title must be between 10 and 100 characters long.');
      return;
    }
  
    if (!allowedCharsRegex.test(sanitizedTitle)) {
      setTitleError('Title contains invalid characters.');
      return;
    }
  
    if (sanitizedTitle.length === 0) {
      setTitleError('Title cannot be empty or whitespace only.');
      return;
    }
  
    // Check for abusive or code-like input using a regex
    const forbiddenPattern = /<script>|<\/script>|<[^>]*>|(alert\(|eval\()/i;
    if (forbiddenPattern.test(sanitizedTitle)) {
      setTitleError('Invalid input detected. Please avoid using special characters or code.');
      return;
    }
  
    // Proceed to the next step
    setStep('2a');
  };

  const handleSetHasCharacter = async (thumbnailHasCharacter) => {
    setHasCharacter(thumbnailHasCharacter)
    if (thumbnailHasCharacter) {
      let characterUrls = []
      try {
        const response = await axios.post('/api/get-tubethumbsy-characters', { userId });
        // console.log(response.data.characters)
        response.data.characters.forEach(element => {
          let characterURL = characterIdToUrl(element)
          characterUrls.push(characterURL)
        });
        setCharacterUrls(characterUrls);
      } catch (error) {
        console.error('Error fetching character URLs:', error);
      }
      if (characterUrls.length > 0) {
        setStep('2b');
      } else {
        setStep('2c')
      }
    } else {
      dispatch(setSelectedCharacterId(null))
      // await handleStartSession()
      setStep('3a');
    }
  }

  const characterIdToUrl = (characterId) => {
    let characterURL = 'https://tubethumbs-characters.s3.us-west-1.amazonaws.com/'+characterId+'.png'
    return (characterURL)
  }

  const handleSelectCharacter = async (selectedCharacterUrl) => {
    // console.log('handleSelectCharacter')
    // console.log(selectedCharacterUrl)
    let characterId
    if (selectedCharacterUrl === null) {
      characterId = 'none'
    } else {
      characterId = selectedCharacterUrl.split('/').pop().replace('.png', '');
    }
    // console.log('setSelectedCharacterId from selection')
    // console.log(characterId)
    dispatch(setSelectedCharacterId(characterId));
    // setSelectedCharacterUrl(selectedCharacterUrl); 
    setStep('3a');
  };

  const handleSubmitCharacter = async () => {
    // console.log('handleSubmitCharacter')
    // Proceed if validation passes
    setTitleError('');
    setStep('3a');
  }

  const handleGenerateThumbnailPremises = async () => {
    dispatch(setLoadingDelightType('premiseCaptionBrainstorm'))
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post('/api/generate-tubethumbsy-premises', { 
          videoTitle,
          selectedCharacterId
         });
        setThumbnailPremises(response.data);
        resolve(response.data); // Resolves with the response data
      } catch (error) {
        console.error('Error fetching character URLs:', error);
        reject(error); // Rejects the promise in case of an error
      }
    });
  };

  const handleGenerateThumbnailCaptions = async () => {
    dispatch(setLoadingDelightType('premiseCaptionBrainstorm'))
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post('/api/generate-tubethumbsy-captions', { videoTitle });
        setThumbnailCaptions(response.data);
        resolve(response.data); // Resolves with the response data
      } catch (error) {
        console.error('Error fetching character URLs:', error);
        reject(error); // Rejects the promise in case of an error
      }
    });
  };

  const handleSelectImageMode = async (mode) => {
    setThumbnailImageryMode(mode);
    // console.log(mode)
    // console.log(thumbnailPremises)
    if (mode === 'title') {
      if (thumbnailPremises.length===0) {
        dispatch(setLoadingDelightType('premiseCaptionBrainstorm'))
        setIsLoading(true);
        await handleGenerateThumbnailPremises()
        setIsLoading(false);
      }
      setStep('3b')
    } else {
      setThumbnailPremises([])
      setStep('3c')
    }
  };
  
  const extractYouTubeVideoID = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const handleThumbnailURLChange = (e) => {
    const url = e.target.value;
    setThumbnailSeedUrl(url);
    const videoID = extractYouTubeVideoID(url);
    
    if (videoID) {
        const previewUrl = `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`;
        setThumbnailSeedPreviewUrl(previewUrl);
        setTitleError(''); // Clear error if valid YouTube URL is entered
    } else {
        setThumbnailSeedPreviewUrl('');
        setTitleError('Please enter a valid YouTube video URL.');
    }
};

  const handleSetSeedVideo = async () => {
    if (!thumbnailSeedPreviewUrl) {
      setTitleError('Please provide a valid YouTube video URL.');
      return;
    }

    const youtubeUrlRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    if (!youtubeUrlRegex.test(thumbnailSeedUrl)) {
      setTitleError('Please enter a valid YouTube video URL.');
      return;
    }

    // Check if the preview image URL is valid
    const img = new Image();
    img.src = thumbnailSeedPreviewUrl; // Set the source of the image
    img.onload = async () => {
      // If the image loads successfully, proceed to the next step
      dispatch(setLoadingDelightType('seedDelights'))
      setIsLoading(true)
      let thumbnailSeedVideoId = getYouTubeVideoId(thumbnailSeedUrl)
      setThumbnailSeedVideoId(thumbnailSeedVideoId)
      const response = await axios.post('/api/put-tubethumbsy-seed', { 
        thumbnailSeedVideoId, 
        videoTitle 
      });
      if (thumbnailCaptions.length === 0) {
        await handleGenerateThumbnailCaptions(); // Generate captions if not done yet
      }
      if (response.data === 'success') {
        setStep('4');
        setIsLoading(false)
      }
    };
    img.onerror = () => {
        // If the image fails to load, show an error message
        setTitleError('The preview image URL is invalid or not accessible. Please check the YouTube URL.');
    };
  };

  function getYouTubeVideoId(url) {
    // Regular expressions to match different YouTube URL formats
    const regExpShort = /^https?:\/\/(?:youtu\.be)\/([a-zA-Z0-9_-]{11})/;
    const regExpLong = /^https?:\/\/(?:www\.)?youtube\.com\/.*[?&]v=([a-zA-Z0-9_-]{11})/;
    const regExpEmbed = /^https?:\/\/(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/;
    const regExpShorts = /^https?:\/\/(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]{11})/;
    let match = url.match(regExpShort);
    if (match && match[1]) {
      return match[1]; // Return ID from youtu.be format
    }
  
    match = url.match(regExpLong);
    if (match && match[1]) {
      return match[1]; // Return ID from youtube.com/watch?v= format
    }
  
    match = url.match(regExpEmbed);
    if (match && match[1]) {
      return match[1]; // Return ID from youtube.com/embed/ format
    }
  
    match = url.match(regExpShorts);
    if (match && match[1]) {
      return match[1]; // Return ID from youtube.com/shorts/ format
    }
  
    // Return null if no match found
    return null;
  }

  const handleRemovePremise = (premise) => {
    setThumbnailPremises((prevPremises) => {
      const updatedPremises = prevPremises.filter((item) => item !== premise);
      if (updatedPremises.length <= 5) {
        setPremiseError('');
      }
      return updatedPremises;
    });
  };

  const handleSubmitPremise = async () => {
    if (thumbnailPremises.length === 0) {
      setPremiseError('You must have at least one idea for imagery.');
    } else {
      if (thumbnailCaptions.length===0) {
        dispatch(setLoadingDelightType('premiseCaptionBrainstorm'))
        setIsLoading(true)
        await handleGenerateThumbnailCaptions()
        setIsLoading(false)
      }
      setStep('4'); // Proceed to the next step if validation passes
    }
  };

  const handleCustomPremiseSubmit = (e) => {
    e.preventDefault();
    const maxLength = 150;
  
    // Validate input
    if (customPremiseInput.trim() === '' || customPremiseInput.length > maxLength) {
      setPremiseError(`Imagery idea must be between 1 and ${maxLength} characters.`);
      return;
    }
  
    if (editingPremiseIndex !== null) {
      // Update premise at the editing index
      setThumbnailPremises((prevPremises) => 
        prevPremises.map((premise, index) => index === editingPremiseIndex ? customPremiseInput : premise)
      );
      setEditingPremiseIndex(null);
    } else {
      // Add new premise
      setThumbnailPremises((prevPremises) => [...prevPremises, customPremiseInput]);
    }
  
    // Reset the input state
    setIsAddingCustomPremise(false);
    setCustomPremiseInput('');
    setPremiseError('');
  };

  const handleAddCaption = () => {
    if (thumbnailCaptions.length >= 5) {
      setCaptionError('You can only add up to 5 captions ');
    } else {
      setIsAddingCustomCaption(true);
      setCustomCaptionInput('');
      setCaptionError(''); // Clear any existing error
    }
  };

  const handleRemoveCaption = (caption) => {
    setThumbnailCaptions((prevCaptions) =>
      prevCaptions.filter((item) => item !== caption)
    );
    if (thumbnailCaptions.length <= 5) {
      setCaptionError('');
    }
  };
  
  const handleCustomCaptionSubmit = (e) => {
    e.preventDefault();
    const maxLength = 150;
  
    // Validate input
    if (customCaptionInput.trim() === '' || customCaptionInput.length > maxLength) {
      setCaptionError(`Caption must be between 1 and ${maxLength} characters.`);
      return;
    }
  
    if (editingCaptionIndex !== null) {
      // Update caption at the editing index
      setThumbnailCaptions((prevCaptions) => 
        prevCaptions.map((caption, index) => index === editingCaptionIndex ? customCaptionInput : caption)
      );
      setEditingCaptionIndex(null);
    } else {
      // Add new caption
      setThumbnailCaptions((prevCaptions) => [...prevCaptions, customCaptionInput]);
    }
  
    // Reset the input state
    setIsAddingCustomCaption(false);
    setCustomCaptionInput('');
    setCaptionError('');
  };

  const handleSubmitCaptionSelection = async () => {
    dispatch(setLoadingDelightType('default'))
    setIsLoading(true)
    createSessionAndProceed();
    // if (isEditingSession) {
    //   // await saveModalParams()
    //   // handleGenerateMore();
    //   // closeModal(true);
    // } else {
    // }
  }

  const createSessionAndProceed = async () => {
    console.log('createSessionAndProceed')
    const selectedCharacterId = store.getState().thumbnailSessions.selectedCharacterId; // Accessing layers from the correct slice
    const userCreditsBalance = store.getState().user.userCreditsBalance; // Accessing layers from the correct slice
    if (userCreditsBalance - thumbnailGenerationCost < 0) {
      navigate('/pricing?q=nocredits');
    } else {
      dispatch(setIsCreatingSession(true)); // Start showing the loading spinner
      setIsAwaitingThumbnailsForSession(true); // Start showing the loading spinner
      setIsGeneratingThumbnailsForSession(true)
      let thumbnails = []
      dispatch(setSessionThumbnails(thumbnails));
      let generateReason='createThumbnailSession'
      try {
        const response = await axios.post('/api/generate-tubethumbsy', { 
          userId, 
          videoTitle,
          // videoDescription,
          thumbnailPremises,
          thumbnailCaptions,
          selectedCharacterId,
          thumbnailImageryMode,
          generateReason,
          thumbnailSeedVideoId
        });
        let updatedSession = response.data
        // console.log(updatedSession)
        if (updatedSession === 'Insufficient Credits') {
        navigate('/pricing?q=nocredits');
        } else {
          // console.log('session created')
          dispatch(setUserCreditsBalance(updatedSession.userCreditsBalance))
          let thumbnailIds = updatedSession.thumbnailIds
          let thumbnailPlaceholders = []
          for (let i=0; i<thumbnailIds.length; i++) {
            let thumbnail = {
              thumbnailId: thumbnailIds[i],
              thumbnailStatus: 'generating',
              clientThumbnailCreationTime: Date.now() //use sessionCreationTime for now
            }
            thumbnailPlaceholders.push(thumbnail)
          }
          dispatch(setSessionThumbnails(thumbnailPlaceholders))
          dispatch(updateSession(updatedSession)); // Update the session in the Redux store
          setSelectedSession(updatedSession);
          dispatch(setIsCreatingSession(false)); 
          setIsAwaitingThumbnailsForSession(false)
          // dispatch(updateSession(updatedSession));
          // setTimeout(() => {
          //   console.log('socket fail check from createSessionAndProceed')
          //   getThumbnails();
          // }, 30000);
          navigate(`/thumbnails/create/${updatedSession.sessionId}`);
          initGenerationChecker()
          // closeModal()
          // Ensures the session state updates before navigating
          // setSelectedSessionId(updatedSession.sessionId);
          // dispatch(updateSessionAndNavigate(updatedSession));
        }
    } catch (error) {
        console.error('Error creating session:', error);
      } 
    }
  };

  // const updateSessionAndNavigate = (updatedSession) => {
  //   return (dispatch) => {
  //     dispatch(addSession(updatedSession));
  //     // Navigate only after state is updated
  //     return Promise.resolve().then(() => {
  //     });
  //   };
  // };

  const handleUploadAreaClick = (e) => {
    // console.log(fileInputRef.current)
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (event) => {
    // Access the file from the event
    const file = event.target.files[0]; 
  
    // Ensure there is a file selected
    if (file) {
      handleFileChange(file); // Pass the file to the handler function
    } else {
      console.log('No file selected');
    }
  };
  
  
  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };
  
  const handleFileChange = (file) => {
    const maxFileSize = 20 * 1024 * 1024; // 1MB in bytes
    const validImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'heic', 'heif', 'webp'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
  
    if (file && validImageExtensions.includes(fileExtension)) {
      if (file.size > maxFileSize) {
        alert("File is too large. Please select an image smaller than 20MB.");
        setImageFile(null);
        setImagePreview(null);
      } else {
        setImageFile(file);
        setImageError('');
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      alert("Please select a valid image file (JPEG, PNG, GIF, HEIC, or HEIF).");
      setImageFile(null);
      setImagePreview(null);
    }
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   const validImageTypes = ['image/jpeg', 'image/png', 'image/gif']; // Valid image types
  
  //   if (file && validImageTypes.includes(file.type)) {
  //     setImageFile(file);
  //     setImageError(''); // Clear any previous error
  //   } else {
  //     setImageFile(null); // Reset the file if it's invalid
  //     setImageError('Invalid file type. Please upload a JPEG, PNG, or GIF image.');
  //   }
  // };

  const handleFileDragOver = (e) => {
    e.preventDefault();
  };
  
  const handleUploadCharacter = async (event) => {
    event.preventDefault();
    if (!imageFile) {
      console.log('no image')
      setImageError('Please upload a valid image file.');  // Set error message if no image is uploaded
      // return;
    } else {
      dispatch(setLoadingDelightType('character'));
      setIsLoading(true);
      try {
        const compressedImage = await compressImage(imageFile);
        const formData = new FormData();
        formData.append('image', compressedImage); 
        formData.append('userId', userId);
        let response = await axios.post('/api/create-tubethumbsy-character', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        let characterURL = characterIdToUrl(response.data);
        dispatch(setSelectedCharacterId(response.data));
        setCharacterUrls((prevUrls) => [...prevUrls, characterURL]);
        setIsLoading(false);
        setStep('3a');
      } catch (error) {
        console.error('Error uploading character:', error);
      }
    }
  };

  // Helper function to compress the image before upload
  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxFileSize = 1000 * 1024; // 500KB
          const maxWidth = 1000; // Max width for resizing
          const maxHeight = 1000; // Max height for resizing
          let width = img.width;
          let height = img.height;

          // Scale down the image while maintaining the aspect ratio
          if (width > maxWidth || height > maxHeight) {
            if (width > height) {
              height = Math.floor((height * maxWidth) / width);
              width = maxWidth;
            } else {
              width = Math.floor((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          // Resize and draw the image on the canvas
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          // Compress the image (default quality: 70%)
          let base64Image = canvas.toDataURL('image/jpeg', 0.7);
          let blob = dataURItoBlob(base64Image);

          // Check the size and adjust compression if still too large
          if (blob.size > maxFileSize) {
            let newQuality = maxFileSize / blob.size;
            newQuality = Math.max(0, Math.min(1, newQuality)); // Ensure newQuality is between 0 and 1
            base64Image = canvas.toDataURL('image/jpeg', newQuality);
            blob = dataURItoBlob(base64Image);
          }
          resolve(blob); // Return the compressed image
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Helper function to convert base64/URI to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  // const handleSetCompositeTemplate = () => {
  //   let compositeMethods = {
  //     captionParams: {
  //       caption: "Secrets to Giant Dragon Fruits",
  //       captionPosition: "left",
  //       primaryThemeColorHexCode: "#4CAF50",
  //       primaryThemeColorPlainEnglishTwoWords: "Vibrant Green",
  //       secondaryThemeColorHexCode: "#FF4081",
  //       secondaryThemeColorPlainEnglishTwoWords: "Bright Pink",
  //       fontColor: 'white',
  //       strokeType: 'stroke', //shadow vs stroke
  //       strokeColor: '#4CAF50'
  //     },
  //     personMethod: "autogen",
  //     premiseHasPerson: true,
  //     textMethod: "composite",
  //     userHasSelectedText: true
  //   };
  //   let thumbnailBackgroundJPG = 'https://tubethumbs-bg.s3.us-west-1.amazonaws.com/7b112eb7-70b1-4cc9-a517-81b68f0aa76f.jpg';
  //   // let thumbnailId = 'abc'
    
  //   // Make a shallow copy of the sessionThumbnails array
  //   for (let i=0; i<4; i++) {
  //     let thumbnailId = uuidv4()
  //     let sendObject = {
  //         thumbnailId: thumbnailId,
  //         thumbnailBackgroundJPG: thumbnailBackgroundJPG,
  //         thumbnailStatus: 'generated',
  //         compositeMethods: compositeMethods
  //     };
  //     let sessionThumbnails = [...store.getState().thumbnailSessions.sessionThumbnails];
  //     // Add the new thumbnail to the copied array
  //     sessionThumbnails.push(sendObject);
  //     // Dispatch the updated array
  //     dispatch(setSessionThumbnails(sessionThumbnails));
  //     dispatch(addCompositingQueue(sendObject));
  //   }
  // };

  const handleGenerateMore = async () => {
    const userCreditsBalance = store.getState().user.userCreditsBalance; // Accessing layers from the correct slice
    if (userCreditsBalance - thumbnailGenerationCost < 0) {
      navigate('/pricing?q=nocredits');
    } else {
      dispatch(setLoadingDelightType('default'))
      setIsLoading(true)
      setIsAwaitingThumbnailsForSession(true)
      const selectedCharacterId = store.getState().thumbnailSessions.selectedCharacterId; // Accessing layers from the correct slice
      let generateReason = 'generateMoreThumbnails'
      try {
        const response = await axios.post('/api/generate-tubethumbsy', { 
          userId, 
          selectedSessionId,
          videoTitle,
          // videoDescription,
          thumbnailPremises,
          thumbnailCaptions,
          selectedCharacterId,
          thumbnailImageryMode,
          thumbnailSeedUrl,
          thumbnailSeedPreviewUrl,
          generateReason
        });
        const updatedSession = response.data;
        // console.log(updatedSession)
        if (updatedSession === 'Insufficient Credits') {
          navigate('/pricing?q=nocredits');
        } else {
          let thumbnailIds = updatedSession.thumbnailIds;
          let thumbnailPlaceholders = [];
          for (let i = 0; i < thumbnailIds.length; i++) {
            let thumbnail = {
              thumbnailId: thumbnailIds[i],
              thumbnailStatus: 'generating',
              clientThumbnailCreationTime: Date.now() // use sessionCreationTime for now
            };
            thumbnailPlaceholders.push(thumbnail);
          }
          
          // Merge the new placeholders with the existing sessionThumbnails
          const updatedThumbnails = [...sessionThumbnails, ...thumbnailPlaceholders];
          dispatch(setSessionThumbnails(updatedThumbnails));
          dispatch(updateSession(updatedSession)); // Update the session in the Redux store
          setSelectedSession(updatedSession);
          setIsAwaitingThumbnailsForSession(false)
        }
    } catch (error) {
        console.error('Error generating more thumbnails:', error);
      }
    }
  }

  // const handleEditClick = () => {
  //   setStep('2a');  // Set the modal to open at step 2
  //   handleStartNewSession();  // Open the modal
  //   setIsEditingSession(true)
  // };

  // const handleTestLocalCredits = () => {
  //   dispatch(setUserCreditsBalance(9000))
  // }

  const handleThumbnailClick = (thumbnail) => {
    const isMobile = window.innerWidth <= 768; // Threshold for mobile view
  
    // Ensure sessionThumbnails is defined and is an array
    if (!Array.isArray(sessionThumbnails)) {
      console.error('sessionThumbnails is undefined or not an array');
      alert('An error occurred. Please refresh the page.');
      return;
    }
  
    // Check if all thumbnails in sessionThumbnails are composited
    const allThumbnailsComposited = sessionThumbnails.every(
      (t) => t.thumbnailStatus === 'composited'
    );
  
    if (!allThumbnailsComposited) {
      alert('You may only edit your thumbnails once all thumbnails are generated. Please wait until they are ready.');
      return; // Stop the function if any thumbnail is not composited
    }
  
    if (!isMobile) {
      // Proceed with the check for the specific clicked thumbnail
      if (thumbnail.thumbnailStatus === 'composited') {
        navigate(`/thumbnails/editor/${thumbnail.thumbnailId}`); // Only navigate if not mobile and composited
      } else {
        console.log('This thumbnail is not ready yet.');
      }
    } else {
      // Optionally show a message or perform a different action on mobile
      alert("Editing thumbnails is not currently available on your mobile device.");
    }
  };

  const handleGenerateMarketing = async () => {
    // console.log('handleGenerateMarketing')
    const response = await axios.post('/api/generate-tubethumbsy-for-marketing', { 
      userId, 
    });
    const updatedSession = response.data;
    dispatch(updateSession(updatedSession)); // Update the session in the Redux store
    setSelectedSession(updatedSession);
  }

  const handleAdvancedSettings = () => {
    navigate('/thumbnails/settings/'+selectedSessionId);
  }

  const handleGoMostRecentSession = () => {
    navigate('/thumbnails/create/' + sessions[0].sessionId);
  }

  // const test = async () => {
  //   await axios.post('/api/test', { 
  //     userId, 
  //   });
  // }

  return (
    <div className="universal-container">
      <MainTopNav />
      <ThumbnailLeftNav handleStartNewSession={handleStartNewSession} saveCanvas={saveCanvas} resetSession={resetSession} />
      <div className="universal-content-container thumbnails-content-container">
        <div className="thumbnails-main-content">
          {isLoading && (
            <>
              <div className='thumbnail-create-loading'>
                Giggy is on it !
                <br/>
                <div>
                  <img src="../../logo-dancing-gray.gif" alt="loading"/>
                </div>
                <div>
                  {isGeneratingThumbnailsForSession && (
                    <p>Generations usually take around a minute</p>
                  )}
                  <LoadingDelight type={loadingDelightType}/>
                </div>
              </div>
            </>
          )}
          {paramSessionId !== 'new' && (
            <>
              <div className='mobile-hot-tip-banner'>
                <strong>Note:</strong> For full access to all features, please use Giggy on a desktop or laptop. While you can create thumbnails from our mobile experience, text editing and accessing past creations aren’t available.
              </div>
              <div className='sessions-UI'>
                {isMobile && (
                  <div className='sessions-UI-button-wrapper'>
                    <button onClick={() => handleStartNewSession(true)} >
                      <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px', fontWeight: 'bold', fontSize: '12px' }} />
                      CREATE NEW PROJECT
                    </button>
                  </div>
                )}
                <div className='sessions-UI-header'>
                  <h1>
                    {selectedSessionId
                      ? sessions.find(s => s.sessionId === selectedSessionId)?.sessionTitle || ''
                      : ''}
                  </h1>
                  <h2>Pro tips for you:</h2>
                  <ul>
                    <li>Our AI tech is powerful, but not perfect. It generally takes 10-15 thumbnails for creators to reach their desired output.</li>
                    <li>If your character isn't quite looking right, try uploading another photo.</li>
                    <li>Fine-tune text with our desktop thumbnail editor—just click on a thumbnail to start editing.</li>
                    <li>Don't be afraid to experiment! Often quirky or unexpected thumbnails drive the most engagement on YouTube.</li>
                  </ul>
                  <div>
                    {/* <p> */}
                      {/* Your thumbnails are created based on {thumbnailPremises.length} imagery ideas, {thumbnailCaptions.length} captions, and you have  {selectedCharacterId === 'none' ? 'opted not to select a photo as a reference.' : 'are based on a character you uploaded.'}   */}
                    {/* </p> */}
                    <div className='sessions-UI-header-buttons-wrapper'>
                      <button onClick={() => handleGenerateMore()} >
                        <div className="button-content">
                          <span className="main-text">GENERATE MORE</span>
                          <span className="credits-text">(40 Credits)</span>
                          <img src="../../sparkles.gif" alt="sparkles" className="wand-button" />
                        </div>
                      </button>
                      <button onClick={handleAdvancedSettings}>ADVANCED SETTINGS</button>
                          {/* <button onClick={handleEditClick} >EDIT SETTINGS</button> */}
                      {userId === 'fd4edd12-35b1-4824-9ceb-4aebb568beb3' && (
                        <div>
                          {/* <button onClick={test}>===============test===================================</button> */}
                          <button onClick={handleGenerateMarketing}>===============generate marketing===================================</button>
                          {/* <button onClick={handleSetCompositeTemplate}>Composite</button> */}
                          {/* <button onClick={handleTestLocalCredits}>boost credits</button> */}
                        </div>
                      )}
                      </div>
                    </div>
                </div>
                <div className="thumbnails-list">
                  {sessionThumbnails && sessionThumbnails.map((thumbnail) => (
                    <div className="thumbnail-item" key={thumbnail.thumbnailId}>
                      <div className="loading-spinner-container" onClick={() => handleThumbnailClick(thumbnail)} >
                        {/* The spinner runs persistently */}
                        <div className="loading-spinner"></div>
                        {/* Image overlays the spinner when available */}
                        {thumbnail.thumbnailStatus === 'composited' && (
                          <img
                            src={thumbnail.thumbnailJPEG}
                            alt="Thumbnail"
                            className="thumbnail-image"
                            style={{ opacity: thumbnail.thumbnailJPEG ? 1 : 0 }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {paramSessionId === 'new' && (
            <div className='thumbnail-create-new-content'>
              {isMobile && sessions.length > 0 && (
                <div style={{ height: '20px'}}>
                  <button 
                    onClick={handleGoMostRecentSession} 
                    className='thumbnail-create-mobile-x-button'
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              )}
              {step !== '0' && (
                <div className="step-indicator">
                  <div className="step-wrapper">
                    <div className={`step ${step === '1' ? 'active' : ''}`}>1</div>
                    <div className="step-label">Your Video</div>
                  </div>
                  <div className="step-wrapper">
                    <div className={`step ${step === '2a' || step === '2b' || step === '2c' ? 'active' : ''}`}>2</div>
                    <div className="step-label">Character</div>
                  </div>
                  <div className="step-wrapper">
                    <div className={`step ${step === '3a' || step === '3b' || step === '3c' ? 'active' : ''}`}>3</div>
                    <div className="step-label">Imagery</div>
                  </div>
                  <div className="step-wrapper">
                    <div className={`step ${step === '4' ? 'active' : ''}`}>4</div>
                    <div className="step-label">Caption</div>
                  </div>
                </div>
              )}
              {step === '1' && (
                <>
                  <div className='steps step1'>
                    <h2>Let's Make A Thumbnail</h2>
                    <p>Tell us your video title so we understand what you're creating</p>
                    <textarea
                      value={videoTitle}
                      onChange={(e) => setVideoTitle(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault(); // Prevent default newline behavior
                          handleSetVideoTitle(); // Call the function to go to the next step
                        }
                      }}
                      placeholder="Enter your YouTube video title (required)"
                      className={titleError ? 'error' : ''}
                      rows="5" 
                    />
                    {titleError && <p className='thumbnail-create-new-content-error-validation'>{titleError}</p>}
                    <div className="thumbnail-create-new-content-bottom-right-wrapper">
                      <div className="thumbnail-create-new-content-bottom-right">
                        <button onClick={handleSetVideoTitle}>
                          NEXT
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === '2a' && (
                <div className='steps step1'>
                  <h2>Character</h2>
                  <p>Would you like to feature a character in your thumbnail?</p>
                  <div className="thumbnail-imagery-mode-selection-container">
                    <div
                      onClick={() => handleSetHasCharacter(true)}
                      className={`thumbnail-imagery-mode-selection-item`}
                    >
                      <h3>Option 1: Include a character</h3>
                      <p>Upload a photo of a person or an avatar, and we'll seamlessly integrate them into your thumbail.</p>
                      <div style={{width:'100%',textAlign:'center'}}>
                        <img src='../../thumbnail-creation-character.png' alt='character'/>
                      </div>
                    </div>
                    <div
                      onClick={() => handleSetHasCharacter(false)}
                      className={`thumbnail-imagery-mode-selection-item`}
                    >
                      <h3>Option 2: No character</h3>
                      <p>Create a visually striking thumbnail without a person as the focal point.
                      </p>
                      <div style={{width:'100%',textAlign:'center', marginTop:'10px'}}>
                        <img src='../../thumbnail-creation-no-character.png' alt='character'/>
                      </div>
                    </div>
                  </div>
                  <p className='thumbnail-modal-disclaimer'>Ensure you have the necessary legal rights to any referenced or uploaded content. For more details, review our <a href="/terms" target="_blank">Terms of Service</a></p>
                  <div className="thumbnail-create-new-content-bottom-right-wrapper">
                    <div className="thumbnail-create-new-content-bottom-right">
                      <button className="thumbnail-create-new-back-button" onClick={() => setStep(priorStep)}>BACK</button>
                    </div>
                  </div>
                </div>
              )}
              {step === '2b' && (
                <>
                  <div className="steps step1">
                    <h2>Select a character</h2>
                    <p>Your appearance, pose, and attire will influence the look and feel of your thumbnail. Choose wisely to make the right impression!</p>
                    <div className="thumbnails-characters-grid">
                      {/* Highlight FaUserAltSlash if no character is selected and not editing */}
                      <div
                        key="add-character"
                        className="thumbnails-character-option"
                        onClick={() => setStep('2c')}
                      >
                        <FaPlusCircle className='thumbnails-character-option-icon' />
                        <p>Add Character</p> {/* Caption for FaPlusCircle */}
                      </div>
                      {/* Iterate over character URLs and highlight the selected one if editing */}
                      {characterUrls.map((url) => {
                        const characterId = url.split('/').pop().replace('.png', ''); // Extract the ID part from the URL
                        return (
                          <div key={url} className={`thumbnails-character-image-wrapper ${(selectedCharacterId === characterId) ? 'character-selected' : ''}`}>
                            <img
                              src={url}
                              alt="Character"
                              className={`thumbnails-character-image`}
                              onClick={() => handleSelectCharacter(url)} // Pass the full URL for processing
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="thumbnail-create-new-content-bottom-right-wrapper">
                      <div className="thumbnail-create-new-content-bottom-right">
                        <button className="thumbnail-create-new-back-button" onClick={() => setStep(priorStep)}>
                          BACK
                        </button>
                        <button onClick={handleSubmitCharacter}>
                            NEXT
                          </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === '2c' && (
                <>
                  <div className="steps step1">
                    <h2>Upload Your Photo</h2>
                    <p>Your appearance, pose, and attire will influence the look and feel of your thumbnail. Choose wisely to make the right impression!
                    </p>
                    <div className={`modal-content ${isMobile ? 'mobile-layout' : 'desktop-layout'}`}>
                      {/* Left Side: Ideal Photo Conditions */}
                      <div className="ideal-photo-conditions">
                        <h3>Best Practices & Examples:</h3>
                        <ul className="photo-conditions-list">
                          <li>✔️ Well-lit environment</li>
                          <li>✔️ Full upper body in frame</li>
                          <li>✔️ Neutral background</li>
                          <li>✔️ Avoid harsh shadows or direct sunlight</li>
                        </ul>
                        <div className="placeholder-image">
                          {/* Placeholder for the ideal photo */}
                          <img src="../../thumbnail-ideal-photo-1.png" alt="Ideal example" />
                          <img src="../../thumbnail-ideal-photo-2.png" alt="Ideal example" />
                          <img src="../../thumbnail-ideal-photo-3.png" alt="Ideal example" />
                        </div>
                      </div>

                      {/* Right Side: Upload Area 
                      Note: For whatever reason ios doesnt support the label, ie. clicking on it and then picking media doesnt work
                      */}
                      <div 
                          className="upload-area"
                          onClick={handleUploadAreaClick}
                      // Trigger file input click
                          onDrop={handleFileDrop}
                          onDragOver={handleFileDragOver}
                          style={{cursor:"pointer"}}
                        >
                        {isMobile && (
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            onChange={handleFileInputChange}
                            className='upload-area-input'
                            // ref={fileInputRef}  this breaks iphone upload
                          />
                        )}  
                        {!isMobile && (
                          <div>
                            <input
                              type="file"
                              id="fileInput"
                              accept="image/*"
                              onChange={handleFileInputChange}
                              className='upload-area-input'
                              ref={fileInputRef}  
                            />
                            Drag and drop your file here, or click to upload.
                          </div>
                        )} 
                        {imageFile && (
                          <>
                            <img
                              src={imagePreview}
                              alt="Preview"
                              className="upload-preview-image"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {imageError && <p className="thumbnail-create-new-content-error-validation">{imageError}</p>}
                    <div className="thumbnail-create-new-content-bottom-right-wrapper">
                      <div className="thumbnail-create-new-content-bottom-right">
                        <button onClick={() => setStep(priorStep)} className="thumbnail-create-new-back-button">BACK</button>
                        <button onClick={handleUploadCharacter}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === '3a' && (
                <div className='steps step1'>
                  <h2>Imagery</h2>
                  <p>How would you like to inspire the visuals for your thumbnail?</p>
                  <div className="thumbnail-imagery-mode-selection-container">
                    <div
                      onClick={() => handleSelectImageMode('url')}
                      className={`thumbnail-imagery-mode-selection-item ${thumbnailImageryMode === 'url' ? 'selected' : ''}`}
                    >
                      <h3>Option 1: Generate based on a reference thumbnail</h3>
                      <p>Use an existing thumbnail as inspiration to craft a visually engaging design </p>
                      <div className='thumbnail-imagery-mode-seeded'>
                        <img src="../../thumbnail-creation-seeded.png" alt='Seeded'/>
                      </div>
                    </div>
                    <div
                      onClick={() => handleSelectImageMode('title')}
                      className={`thumbnail-imagery-mode-selection-item ${thumbnailImageryMode === 'title' ? 'selected' : ''}`}
                    >
                      <h3>Option 2: Generate ideas based on my video title</h3>
                      <p>For example, "Top 10 Travel Destinations in 2024" may lead to these visual suggestions which we'll base our generations on:</p>
                      <ul>
                          <li>A stunning aerial view of an exotic beach with crystal-clear waters.</li>
                          <li>A mesmerizing close-up of a unique dish representing a top destination.</li>
                          <li>A breathtaking sunrise over the Eiffel Tower.</li>
                          <li>A vibrant street market bustling with locals and tourists.</li>
                      </ul>
                    </div>
                  </div>
                  <p className='thumbnail-modal-disclaimer'>Ensure you have the necessary legal rights to any referenced or uploaded content, per our <a href="/terms" target="_blank">Terms of Service.</a>We <a href="/terms" target="_blank">respect your privacy.</a></p>
                    <div className="thumbnail-create-new-content-bottom-right-wrapper">
                      <div className="thumbnail-create-new-content-bottom-right">
                      <button className="thumbnail-create-new-back-button" onClick={() => setStep(priorStep)}>BACK</button>
                    </div>
                  </div>
                </div>
              )}
              {step === '3b' && (
                <>
                  <div className="steps step2">
                    <h2>Thumbnail Imagery</h2>
                    <p>Describe what you want to see in your thumbnail. We recommend auditioning at least a couple options</p>
                    <div className="thumbnail-premises-container">
                      {thumbnailPremises && thumbnailPremises.map((premise, index) => (
                        <div key={index} className="thumbnail-premise">
                          {editingPremiseIndex === index ? (
                            <form onSubmit={handleCustomPremiseSubmit} className="thumbnail-premise">
                              <input
                                type="text"
                                value={customPremiseInput}
                                onChange={(e) => setCustomPremiseInput(e.target.value)}
                                autoFocus
                                placeholder="Edit your imagery idea"
                                className='thumbnail-premise-custom-input'
                              />
                              <div className='thumbnail-premise-custom-input-button-wrapper'>
                                <button type="submit" className="button-submit">
                                  <FontAwesomeIcon icon={faCircleRight} size='lg' className='thumbnail-premise-custom-input-go' />
                                </button>
                                <button
                                  type="button"
                                  className="button-cancel"
                                  onClick={() => {
                                    setEditingPremiseIndex(null);
                                    setCustomPremiseInput('');
                                  }}
                                >
                                </button>
                              </div>
                            </form>
                          ) : (
                            <div className="thumbnail-premise-item" onClick={() => {
                              if (!isAddingCustomPremise) { // Prevent editing if adding new premise
                                setEditingPremiseIndex(index);
                                setCustomPremiseInput(premise); // Load current premise into input
                              }
                            }}>
                              {premise} <FontAwesomeIcon icon={faPenToSquare} size='sm' />
                            </div>
                          )}
                          <button onClick={() => handleRemovePremise(premise)}>
                            <FontAwesomeIcon icon={faCircleXmark} size='lg' />
                          </button>
                        </div>
                      ))}
                      {!isAddingCustomPremise && editingPremiseIndex === null && (
                        <div
                          className="thumbnail-premise add-premise"
                          onClick={() => {
                            if (thumbnailPremises.length < 5) {
                              setIsAddingCustomPremise(true);
                              setCustomPremiseInput(''); // Reset input for new premise
                              setPremiseError('');
                            } else {
                              setPremiseError('You can add up to 5 imagery ideas only.');
                            }
                          }}
                          style={{ width: '100%' }}
                        >
                          <FaPlusCircle size={12} />
                          <span style={{ marginLeft: '8px' }}>ADD MORE</span>
                        </div>
                      )}
                      {isAddingCustomPremise && (
                        <form onSubmit={handleCustomPremiseSubmit} className="thumbnail-premise">
                          <input
                            type="text"
                            value={customPremiseInput}
                            onChange={(e) => setCustomPremiseInput(e.target.value)}
                            autoFocus
                            placeholder="Enter your own imagery idea"
                            className='thumbnail-premise-custom-input'
                          />
                          <div className='thumbnail-premise-custom-input-button-wrapper'>
                            <button type="submit" className="button-submit">
                              <FontAwesomeIcon icon={faCircleRight} size='lg' className='thumbnail-premise-custom-input-go' />
                            </button>
                            <button
                              type="button"
                              className="button-cancel"
                              onClick={() => {
                                setIsAddingCustomPremise(false);
                                setCustomPremiseInput('');
                              }}
                            >
                              <FontAwesomeIcon icon={faCircleXmark} size='lg' />
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                    <div className="thumbnail-create-new-content-bottom-right-wrapper">
                      <div className="thumbnail-create-new-content-bottom-right">
                      {premiseError && <p className="thumbnail-create-new-content-error-validation">{premiseError} </p>}
                      {!isAddingCustomPremise && (
                        <>
                          {!isEditingSession && (
                            <button className="thumbnail-create-new-back-button" onClick={() => setStep(priorStep)}>
                              BACK
                            </button>
                          )}
                          <button onClick={() => handleSubmitPremise()}>
                            NEXT
                          </button>
                        </>
                      )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === '3c' && (
                <div className="steps step1 step3">
                  <h2>Reference Video URL</h2>
                  <p>Enter a YouTube video URL, and we'll use it as inspiration to craft a visually engaging design</p>
                  <input
                    type="text"
                    value={thumbnailSeedUrl}
                    onChange={handleThumbnailURLChange}
                    placeholder="Enter YouTube video URL"
                    className={titleError ? 'error' : ''}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSetSeedVideo();
                      }
                    }}
                  />
                  {titleError && <p className='thumbnail-create-new-content-error-validation'>{titleError}</p>}
              
                  {thumbnailSeedPreviewUrl && (
                    <div className="thumbnail-seed-preview-container">
                      <img src={thumbnailSeedPreviewUrl} alt="YouTube Thumbnail Preview" />
                    </div>
                  )}
                  <p className='thumbnail-modal-disclaimer'>Ensure you have the necessary legal rights to any referenced or uploaded content. For more details, review our <a href="/terms" target="_blank">Terms of Service</a></p>
                  <div className="thumbnail-create-new-content-bottom-right-wrapper">
                    <div className="thumbnail-create-new-content-bottom-right">
                      <button className="thumbnail-create-new-back-button" onClick={() => setStep(priorStep)}>BACK</button>
                      <button onClick={() => handleSetSeedVideo()}>NEXT</button>
                    </div>
                  </div>
                </div>
              )}
              {step === '4' && (
                <>
                  <div className="steps step2">
                    <h2>Thumbnail Captions</h2>
                    <p>Tell us what text you want on your thumbnail. We recommend auditioning at least a couple options</p>
                    <div className="thumbnail-premises-container">
                      {thumbnailCaptions && thumbnailCaptions.map((caption, index) => (
                        <div key={index} className="thumbnail-premise">
                          {editingCaptionIndex === index ? (
                            <form onSubmit={handleCustomCaptionSubmit} className="thumbnail-premise">
                              <input
                                type="text"
                                value={customCaptionInput}
                                onChange={(e) => setCustomCaptionInput(e.target.value)}
                                autoFocus
                                placeholder="Edit your caption"
                                className='thumbnail-premise-custom-input'
                              />
                              <div className='thumbnail-premise-custom-input-button-wrapper'>
                                <button type="submit" className="button-submit">
                                  <FontAwesomeIcon icon={faCircleRight} size='lg' className='thumbnail-premise-custom-input-go' />
                                </button>
                                <button
                                  type="button"
                                  className="button-cancel"
                                  onClick={() => {
                                    setEditingCaptionIndex(null);
                                    setCustomCaptionInput('');
                                  }}
                                >
                                </button>
                              </div>
                            </form>
                          ) : (
                            <div className="thumbnail-premise-item" onClick={() => {
                              if (!isAddingCustomCaption) { // Prevent editing if adding new caption
                                setEditingCaptionIndex(index);
                                setCustomCaptionInput(caption); // Load current caption into input
                              }
                            }}>
                              {caption} <FontAwesomeIcon icon={faPenToSquare} size='sm' />
                            </div>
                          )}
                          <button onClick={() => handleRemoveCaption(caption)}>
                            <FontAwesomeIcon icon={faCircleXmark} size='lg' />
                          </button>
                        </div>
                      ))}

                      {!isAddingCustomCaption && editingCaptionIndex === null && (
                        <div
                          className="thumbnail-premise add-premise"
                          onClick={() => {
                            if (thumbnailCaptions.length < 5) {
                              setIsAddingCustomCaption(true);
                              setCustomCaptionInput(''); // Reset input for new caption
                              setCaptionError('');
                            } else {
                              setCaptionError('You can add up to 5 captions only.');
                            }
                          }}
                          style={{ width: '100%' }}
                        >
                          <FaPlusCircle size={12} />
                          <span style={{ marginLeft: '8px' }}>ADD MORE</span>
                        </div>
                      )}

                      {isAddingCustomCaption && (
                        <form onSubmit={handleCustomCaptionSubmit} className="thumbnail-premise">
                          <input
                            type="text"
                            value={customCaptionInput}
                            onChange={(e) => setCustomCaptionInput(e.target.value)}
                            autoFocus
                            placeholder="Enter your own caption"
                            className='thumbnail-premise-custom-input'
                          />
                          <div className='thumbnail-premise-custom-input-button-wrapper'>
                            <button type="submit" className="button-submit">
                              <FontAwesomeIcon icon={faCircleRight} size='lg' className='thumbnail-premise-custom-input-go' />
                            </button>
                            <button
                              type="button"
                              className="button-cancel"
                              onClick={() => {
                                setIsAddingCustomCaption(false);
                                setCustomCaptionInput('');
                              }}
                            >
                              <FontAwesomeIcon icon={faCircleXmark} size='lg' />
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                    <div className="thumbnail-create-new-content-bottom-right-wrapper">
                      <div className="thumbnail-create-new-content-bottom-right">
                        {captionError && <p className="thumbnail-create-new-content-error-validation">{captionError}</p>}
                        {!isAddingCustomCaption && (
                        <>
                          <button onClick={() => setStep(priorStep)} className="thumbnail-create-new-back-button">
                            BACK
                          </button>
                          <button onClick={() => handleSubmitCaptionSelection()} className="create-thumbnails-btn">
                          <div className="button-content">
                            <span className="main-text">{isEditingSession ? "GENERATE MORE" : "CREATE THUMBNAILS"}</span>
                            <span className="credits-text">(40 Credits)</span>
                            <img src="../../sparkles.gif" alt="sparkles" className="wand-button" />
                          </div>
                        </button>
                        </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThumbnailCreateUI;
