import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  canvasData: null,
  file: null,
  selectedObject: null,
  // shapeStrokeColor: '#FFFFFF',
  strokeColor: '#FFFFFF',
  strokeWidth: 0,
  shapeStrokeWidth: 0,
  strokeOpacity: 1,
  fillOpacity: 1,
  shadowColor: '#000000',
  shadowBlur: 0,
  textFontSize: 20,
  fontFamily: 'Arial Black',
  // shadowOffsetX: 0,
  // shadowOffsetY: 0,
  // textStrokeColor: '#FFFFFF',
  // textStrokeWidth: 0,
  textBackgroundColor: '#FFFFFF',
  textBackgroundOpacity: 0,
  fillColor: '#FFFFFF',
  // textFillColor: '#000000',
  textFont: 'Arial',
  isTextInputModalOpen: false,
  currentTextGroup: null,
  newText: '',
  // imageStrokeColor: '#FFFFFF',
  // imageStrokeWidth: 0,
  cutoutGroup: null,
  clipboard: null,
  imageCount: 0,
  rectangleCount: 0,
  circleCount: 0,
  triangleCount: 0,
  originalLayerIndex: null,
  layers: [],
  selectedLayerId: null,
  selectedObjectType: null,
  historyIndex: 0,
  historyArray: [],
  currentLayers: [],
  isMouseDown: false,
  triggerRefresh: false,
};

const checkIsAtHead = (state) => {
  const historyArray = current(state.historyArray);
  const historyIndex = state.historyIndex;
  if (historyIndex === historyArray.length - 1) {
    // console.log('at head');
    // let returnObject = {
    //   isAtHead: true
    // }
    return(true);
  } else {
    // console.log('behind head');
    // let returnObject = {
    //   isAtHead: false
    // }
    return(false);
  }
}

const writeHistory = (state, newLayers) => {
  let newHistoryArray = [];
  if (checkIsAtHead(state)) {
    // If at head, push new layer to end of array
    newHistoryArray = [...state.historyArray, newLayers];
  } else {
    // If not at head, remake the array up until history index
    let currentHistoryArray = state.historyArray;
    let fillHistoryArray = [];
    for (let i = 0; i < state.historyIndex + 1; i++) {
      fillHistoryArray.push(currentHistoryArray[i]);
    }
    newHistoryArray = [...fillHistoryArray, newLayers];
  }
  // Filter out undefined items from the history array
  // newHistoryArray = newHistoryArray.filter(item => item !== undefined);
  state.historyIndex += 1;
  state.historyArray = newHistoryArray;
  state.currentLayers = newLayers;
}


const thumbnailCanvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    
    setNewCanvas(state, action) {
      state.currentLayers = [];
      state.historyArray = [];
      state.layers = [];
    },
    setLoadCanvas(state, action) {
      // console.log('setLoadCanvas')
      state.triggerRefresh = true
      // console.log(action.payload)
      state.historyArray = [action.payload];
      state.currentLayers = action.payload;
      // loadCanvas(state, action.payload)
    },
    setCanvasData(state, action) {
      state.canvasData = action.payload;
    },
    setFile(state, action) {
      state.file = action.payload;
    },
    setSelectedObject(state, action) {
      state.selectedObject = action.payload
      state.selectedLayerId = action.payload
      if (action.payload === null) {
        state.selectedObjectType = null
      }
    },
    setsSelectedObjectType(state, action) {
      state.selectedObjectType = action.payload
    },
    setLeftNavStrokeColor(state, action) {
      state.strokeColor = action.payload
    },
    setLeftNavStrokeWidth(state, action) {
      state.strokeWidth = action.payload
    },
    setLeftNavStrokeOpacity(state, action) {
      state.strokeOpacity = action.payload;
    },
    setLeftNavShadowColor(state, action) {
      state.shadowColor = action.payload;
    },
    setLeftNavShadowBlur(state, action) {
      state.shadowBlur = action.payload;
    },
    setLeftNavFillColor(state, action) {
      state.fillColor = action.payload
    },
    setLeftNavFillOpacity(state, action) {
      state.fillOpacity = action.payload;
    },
    setLeftNavTextBackgroundColor(state, action) {
      state.textBackgroundColor = action.payload
    },
    setLeftNavTextBackgroundOpacity(state, action) {
      state.textBackgroundOpacity = action.payload;
    },
    setLeftNavFontFamily(state, action) {
      state.fontFamily = action.payload;
    },
    setTextFont(state, action) {
      state.textFont = action.payload;
    },
    setIsTextInputModalOpen(state, action) {
      state.isTextInputModalOpen = action.payload;
    },
    setCurrentTextGroup(state, action) {
      state.currentTextGroup = action.payload;
    },
    setNewText(state, action) {
      state.newText = action.payload;
    },
    setTextFontSize(state, action) {
      state.textFontSize = action.payload;
    },
    setClipboard(state, action) {
      state.clipboard = action.payload;
    },
    incrementObjectCount(state, action) {
      let type = action.payload;
      if (type==="image") {
        state.imageCount += 1;
      } else if (type==="rectangle") {
        state.rectangleCount += 1;
      } else if (type==="circle") {
        state.circleCount += 1;
      } else if (type==="triangle") {
        state.triangleCount += 1;
      }
    },
    addLayer(state, action) {
      let newLayers = [action.payload, ...state.currentLayers]
      writeHistory(state, newLayers)
    },
    moveLayer(state, action) {
      let dragIndex = action.payload.dragIndex;
      let hoverIndex = action.payload.hoverIndex;
      let newLayers = [...current(state.currentLayers)]; // Create a new copy of the array
      // Swapping the values
      let temp = newLayers[dragIndex];
      newLayers[dragIndex] = newLayers[hoverIndex];
      newLayers[hoverIndex] = temp;
      state.triggerRefresh = true;
      writeHistory(state, newLayers)
    },
    setUpdateLayers(state, action) {
      let newLayers = action.payload.slice().reverse()
      writeHistory(state, newLayers)
    },
    setRemoveLayer(state, action) {
      const layerId = action.payload;
      state.layers = state.layers.filter((layer) => layer.id !== layerId);
      state.currentLayers = state.currentLayers.filter((layer) => layer.id !== layerId);
      writeHistory(state, state.currentLayers);
    },
    setIsMouseDown(state, action) {
      state.isMouseDown = action.payload;
    },
    setTriggerRefresh(state, action) {
      state.triggerRefresh = action.payload;
    },
    undo(state) {
      // Remove undefined items from the historyArray
      // console.log(state.historyArray)
      state.historyArray = state.historyArray.filter(item => item !== undefined);
      if (state.historyIndex > 0) {
        state.historyIndex -= 1;
        state.currentLayers = state.historyArray[state.historyIndex];
      }
      state.triggerRefresh = true;
    },
    redo(state) {
      if (state.historyIndex < state.historyArray.length - 1) {
        state.historyIndex += 1;
        state.currentLayers = state.historyArray[state.historyIndex];
        // state.currentLayers = current(state.historyArray[state.historyIndex]);
      } else {
        // console.log('at head')
      }
      state.triggerRefresh = true
    },
  },
});

export const {
  setLoadCanvas,
  setCanvasData,
  setFile,
  setSelectedObject,
  setsSelectedObjectType,
  // setSelectedLayerId,
  setLeftNavStrokeColor,
  setLeftNavStrokeWidth,
  setLeftNavShadowColor,
  setLeftNavShadowBlur,
  setLeftNavFillColor,
  setLeftNavStrokeOpacity,
  setLeftNavFontFamily,
  setLeftNavFillOpacity,
  setLeftNavTextBackgroundColor,
  setLeftNavTextBackgroundOpacity,
  // setTextStrokeColor,
  // setTextStrokeWidth,
  // setTextFillColor,
  setTextFont,
  setIsTextInputModalOpen,
  setCurrentTextGroup,
  setNewText,
  setTextFontSize,
  // setImageStrokeColor,
  // setImageStrokeWidth,
  // setShapeFillColor,
  // setCutoutGroup,
  setClipboard,
  incrementObjectCount,
  // incrementImageCount,
  // incrementRectangleCount,
  addLayer,
  // setLayers,
  moveLayer,
  setUpdateLayers,
  setRemoveLayer,
  undo,
  redo,
  setIsMouseDown,
  setTriggerRefresh,
  setNewCanvas,
} = thumbnailCanvasSlice.actions;

export default thumbnailCanvasSlice.reducer;
