import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { useSelector } from 'react-redux'; // Import to access redux store
import confetti from 'canvas-confetti';
import axios from 'axios';

const PaymentSuccess = () => {
  // const [paymentStatus, setPaymentStatus] = useState(null);
  // const [balanceBefore, setBalanceBefore] = useState(0);
  // const [balanceAfter, setBalanceAfter] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  // Get the session_id from the query parameters
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');
  const userId = useSelector(state => state.user.userId);

  const handleSuccessfulPayment = useCallback((session) => {
    console.log('Payment was successful!', session);
  
    // Trigger confetti effect
    confetti({
      particleCount: 100,
      spread: 100,
      origin: { y: 0.9 },
      scalar: 3.0,
    });
  
    // Ensure the balance values are numbers
    const before = parseFloat(session.userCreditsBalanceBefore);
    const after = parseFloat(session.userCreditsBalanceAfter);
  
    // setBalanceBefore(before);
    // setBalanceAfter(after);
    setCurrentBalance(before); // Start from the before balance
  
    // Start the balance animation
    animateBalance(before, after);
  }, []);
  
  const checkSession = useCallback(async () => {
    if (sessionId) {
      try {
        const response = await axios.post('/api/finalize-checkout-session', {
          sessionId: sessionId, 
          userId: userId, 
        });
        const session = response.data;
        if (session.message === 'success' || session.message === 'duplicate') {
          // setPaymentStatus('Payment Successful');
          handleSuccessfulPayment(session);
        }
      } catch (error) {
        console.error('Error fetching session:', error);
      }
    }
  }, [sessionId, userId, handleSuccessfulPayment]);

  useEffect(() => {
    if (userId && sessionId) {
      checkSession();
    }
  }, [sessionId, userId, checkSession]);

  const animateBalance = (before, after) => {
    const increment = 1;
    let current = before;

    const interval = setInterval(() => {
      current += increment;
      if ((increment > 0 && current >= after) || (increment < 0 && current <= after)) {
        current = after;
        clearInterval(interval); // Stop the animation once it reaches the target balance
      }
      setCurrentBalance(current); // Update balance on the screen, fixed to 2 decimal places
    }, 5); // Update every 50ms for a smooth transition
  };

  const handleNavigate = () => {
    navigate('/thumbnails/create/');
  }

  return (
    <div className="thumbnail-publish-container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {currentBalance === 0 && (
        <img src='../logo-dancing-transparent.gif' alt='loading' style={{width:'100px'}}/>
      )}
      {currentBalance > 0 && (
        <div style={{ textAlign: 'center' }}> {/* Center content inside */}
          <h1 style={{fontSize:'24px', color:"#666"}}>Welcome to the Giggy community!</h1>
            <h2 style={{fontSize:'20px', marginTop:"20px"}}>
            Your credit balance: <span style={{backgroundColor: '#1ba51b', borderRadius:'20px', padding: '5px 10px', display: 'inline-block' , width: '75px', color: 'white'}}>{currentBalance}</span>
            </h2>
            <img src='../payment-success.png' alt='success' style={{width:'200px', borderRadius:'10px',marginTop:'20px'}}/>
            <br/>
            <button style={{marginTop:'20px', fontSize:'14px',padding:"15px 40px"}} onClick={handleNavigate}>Go make some thumbnails</button>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess;
