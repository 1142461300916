import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// import './ThumbnailCreate.css';
import './ThumbnailTools.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPlus } from '@fortawesome/free-solid-svg-icons';
// import { 
//   setIsCreatingNewSession
// } from './redux/ThumbnailSessionsSlice';

const ThumbnailLeftNav = ({ openModal, handleNavigateToSession, resetSession }) => {
  const sessions = useSelector((state) => state.thumbnailSessions.sessions);
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();
  
  const handleSessionClick = (sessionId) => {
    if (location.pathname.startsWith('/thumbnails/editor')) {
      let params = {
        createNew: false,
        sessionId: sessionId
      };
      handleNavigateToSession(params);
    } else {
      navigate(`/thumbnails/create/${sessionId}`);
    }
    // After saving, navigate to the new session
  };

  const triggerCreateNewSession = async () => {
    if (location.pathname.startsWith('/thumbnails/editor')) {
      // dispatch(setIsCreatingNewSession(true))
      let params = { createNew: true, sessionId: 'new' };
      handleNavigateToSession(params);
    } else {
      console.log('open modal from leftnav')
      if (location.pathname.startsWith('/thumbnails/create/new')) {
        openModal(true); // Ensure modal opens in all cases
        // dispatch(setIsCreatingNewSession(true))
    } else {
      navigate(`/thumbnails/create/new`);

    }
    }
    // resetSession();
  };

  return (
    <div className="thumbnails-left-nav">
      <h2>Your Thumbnails</h2>
      <button onClick={triggerCreateNewSession} className='thumbnails-left-nav-button'>
      <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px', fontWeight: 'bold', fontSize: '12px' }} />
      CREATE NEW PROJECT
      </button>
      {sessions && sessions.map((session, index) => (
        <div key={session.sessionId || index}>  {/* Use session.sessionId if available, otherwise fallback to index */}
          <button
            onClick={() => handleSessionClick(session.sessionId)}
            className="thumbnails-left-nav-thumbnails"
          >
            <div className='thumbnails-left-nav-thumbnails-wrapper'>
              <FontAwesomeIcon icon={faImage} style={{marginRight: '8px', fontSize: '14px', position: 'relative', top: '1px'}} /> 
              {session.sessionTitle}
            </div>
          </button>
        </div>
      ))}

    </div>
  );
};

export default ThumbnailLeftNav;
