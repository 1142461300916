import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { useSelector } from 'react-redux'; // Import to access redux store
import confetti from 'canvas-confetti';

const ThumbnailPublish = () => {
  const { thumbnailId } = useParams();
  const navigate = useNavigate(); // Initialize navigate
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  
  // Access user subscription status from redux
  const userSubscription = useSelector((state) => state.user.userSubscription);

  useEffect(() => {
    const timestamp = Date.now();
    const url = `https://tubethumbs-thumbnail-full.s3.us-west-1.amazonaws.com/${thumbnailId}.jpeg?t=${timestamp}`;
    setThumbnailUrl(url);
  }, [thumbnailId]);

  const handleImageLoad = () => {
    // Trigger confetti animation when the image has fully loaded
    confetti({
      particleCount: 100,
      spread: 100,
      origin: { y: 0.9 },
      scalar: 3.0, // Increase this value to make the confetti particles bigger
    });
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = thumbnailUrl;
    link.download = `thumbnail_${thumbnailId}.jpeg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleViewPlans = () => {
    navigate('/pricing'); // Navigate to the pricing page
  };

  return (
    <div className="thumbnail-publish-container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {thumbnailUrl && (
        <>
          {userSubscription==='free' && (
            <div style={{ fontSize:'12px', marginTop: '10px', fontWeight: 'bold', color: 'red', textAlign: 'center' }}>
              {/* <p>You do not have commercial use rights for this image until you become a paying subscriber.</p> */}
              <button 
                onClick={handleViewPlans}  // Call handleViewPlans on click
                style={{ padding: '15px 25px', marginTop:'10px', fontSize:'12px', fontWeight:700 }}
              >
                View Subscription Plans
              </button>
            </div>
          )}
          {userSubscription!=='free' && (
            <button 
            onClick={handleDownload} 
            style={{ padding: '10px 20px', marginTop:'10px' }}
            >
              Download Thumbnail
            </button>
          )}
          <img 
            src={thumbnailUrl} 
            alt="Final Thumbnail" 
            onLoad={handleImageLoad}  // Trigger confetti when image loads
            style={{ maxWidth: '400px', height: 'auto' }} 
          />
          {/* Show the commercial rights notice if the user is not a subscriber */}
        </>
      )}
    </div>
  );
};

export default ThumbnailPublish;
