import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error details, or send to an error monitoring service
    console.error('Error caught in ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Get the current path and count the number of slashes
      const path = window.location.pathname;
      const slashCount = (path.match(/\//g) || []).length;

      // Build the image path by prepending the correct number of '../' based on the slash count
      const imgSrc = '../'.repeat(slashCount - 1) + 'error.gif';

      // Inline styles for centering and styling the error message and image
      const errorStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: '200px',
        backgroundColor: '#eee',
      };

      const imgStyle = {
        width: '250px',
        height: '250px',
        marginBottom: '20px',
      };

      return (
        <div style={errorStyle}>
          <div style={imgStyle}>
            <img src={imgSrc} alt="Error" style={{ width: '250px' }} />
          </div>
          <h1 style={{ fontSize: '24px' }}>Uh-oh! Looks like something went off-script.</h1>
          <p style={{ fontSize: '16px', lineHeight: '22px', marginTop: '20px' }}>
            Don’t worry, we’re on it! 🎬💥 Why not try refreshing the page or come back a little later?
            <br />
            Good things are on the way! 🚀
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
