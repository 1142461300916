import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLogin } from './redux/UsersSlice';
import { auth, googleProvider } from './firebase';
import { signInWithPopup } from 'firebase/auth';
import axios from 'axios';
import './Register.css'; // Import the CSS file

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSignup, setIsSignup] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [formError, setFormError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  // Unified function for signing in with Google
  const signInWithGoogle = async (apiEndpoint, termsCheck = false) => {
    setFormError('');
    setLoading(true);

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const response = await axios.post(apiEndpoint, {
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      });
      return response;
    } catch (error) {
      handleFirebaseErrors(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Error handling for Firebase-related issues
  const handleFirebaseErrors = (error) => {
    if (error.code === 'auth/cancelled-popup-request') {
      console.warn('Popup was closed or blocked. Please try again.');
    } else if (error.message.includes('Cross-Origin-Opener-Policy')) {
      console.warn(
        'Cross-Origin-Opener-Policy error detected. Please check your browser and server configurations.'
      );
    } else {
      console.error('Error with Google Authentication', error);
      setFormError('An error occurred. Please try again later.');
    }
  };

  const handleGoogleLogin = async () => {
    const response = await signInWithGoogle('/api/user-login');
    if (response?.data?.message === 'exists') {
      const userData = {
        userId: response.data.user.userId,
        userDisplayName: response.data.user.userDisplayName,
        userEmail: response.data.user.userEmail,
        userCreditsBalance: response.data.user.userCreditsBalance,
      };
      dispatch(setLogin(userData));
      navigate('/thumbnails/create');
    } else if (response?.data?.message === 'signup_required') {
      setIsSignup(true);
      alert("You aren't a member. Let's sign you up instead.");
    } else {
      setFormError('Login failed. Please try again.');
    }
  };

  const handleGoogleSignup = async () => {
    if (!termsAccepted) {
      setFormError('You must accept the terms of use and privacy policy');
      return null;
    }
    const response = await signInWithGoogle('/api/user-create', true);
    if (response?.data?.message === 'signup_success' || response?.data?.message === 'exists') {
      const userData = {
        userId: response.data.user.userId,
        userDisplayName: response.data.user.userDisplayName,
        userEmail: response.data.user.userEmail,
        userCreditsBalance: response.data.user.userCreditsBalance,
      };
      dispatch(setLogin(userData));
      navigate('/thumbnails/create');
    } else {
      setFormError('Signup failed. Please try again.');
    }
  };

  return (
    <div className="container">
      <div className="card">
        <img src="login-register.png" alt="login" className="header-image" />
        <h2>{isSignup ? 'Sign Up' : 'Welcome Back'}</h2>
        <h3>{isSignup ? 'Create your free Giggy account' : 'Login to Giggy to continue creating'}</h3>
        <button 
          onClick={isSignup ? handleGoogleSignup : handleGoogleLogin} 
          className="googleBtn" 
          disabled={loading}
        >
          <img src="google-logo.png" alt="Google icon" className="googleIcon" />
          {loading ? 'Processing...' : isSignup ? 'Sign Up with Google' : 'Log in with Google'}
        </button>
        {formError && <p className="errorText">{formError}</p>}
        {isSignup && (
          <div className="termsContainer">
            <input
              type="checkbox"
              id="terms"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            <label htmlFor="terms">
              I accept the{' '}
              <a href="/terms" target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>{' '}
              and{' '}
              <a href="/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </label>
          </div>
        )}
        <p>
          {isSignup ? 'Already have an account?' : "Don't have an account?"}{' '}
          <span className="toggleLink" onClick={() => setIsSignup(!isSignup)}>
            {isSignup ? 'Login here.' : 'Sign up here.'}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Register;
