import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ThumbnailTools.css';  // Reusing your existing styles
import MainTopNav from './MainTopNav';
// import ThumbnailsLeftNav from './ThumbnailsLeftNav';
import { useDispatch } from 'react-redux';
import './Account.css'; // Import the CSS file
import { setLogout } from './redux/UsersSlice';
const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setLogout()); // Dispatch the LOGOUT action to clear the state
    navigate('/'); // Navigate to the homepage or login page
  };

  const test = () => {
    // Placeholder for future functionality
  };

  return (
    <div className="universal-container">
      <MainTopNav />
      {/* Left Navigation */}
      <div className="account-left-nav">
        <h2>Account Options</h2>
        <button
          onClick={() => test()}
          className="thumbnails-left-nav-thumbnails"
        >
          <div className='thumbnails-left-nav-thumbnails-wrapper'>
            Account Details
          </div>
        </button>
        <button
          onClick={handleLogout}  // Call handleLogout on click
          className="thumbnails-left-nav-thumbnails"
        >
          <div className='thumbnails-left-nav-thumbnails-wrapper'>
            Logout
          </div>
        </button>
      </div>
      <div className="universal-content-container account-content-container">
        <div className="thumbnails-main-content">
          {/* Right Content */}
          <div className="account-details-content">
          </div>
          <div className='sessions-UI'>
            <div className='sessions-UI-header'>
              <h1>Your Account</h1>
              <p>You can log out on the left.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
