import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import './ThumbnailTools.css';
import './styles.css';
// import ThumbnailEditorTopNav from './ThumbnailEditorTopNav';
// import ThumbnailEditorLeftNav from './ThumbnailEditorLeftNav';
// import ThumbnailEditorRightNav from './ThumbnailEditorRightNav';
import TextInputModal from './TextInputModal';
import { fabric } from 'fabric';
import store from './redux/store';
import MainTopNav from './MainTopNav';
import ThumbnailLeftNav from './ThumbnailLeftNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareFull, faCircle, faFont, faTrashCan, faRotateLeft, faRotateRight } from '@fortawesome/free-solid-svg-icons'; // Replace faSomeIcon with the specific icon you're using
import {
  setLoadCanvas,
  setSelectedObject,
  setLeftNavStrokeColor,
  setLeftNavStrokeWidth,
  setLeftNavShadowColor,
  setLeftNavFillOpacity,
  setLeftNavStrokeOpacity,
  setLeftNavShadowBlur,
  setLeftNavFillColor,
  setLeftNavTextBackgroundColor,
  setLeftNavTextBackgroundOpacity,
  setLeftNavFontFamily,
  setIsTextInputModalOpen,
  setCurrentTextGroup,
  setNewText,
  setClipboard,
  addLayer,
  moveLayer,
  setRemoveLayer,
  // setSelectedLayerId,
  undo,
  redo,
  setTriggerRefresh,
} from './redux/ThumbnailCanvasSlice';

import { 
  setTriggerCreateSessionFromEditor
} from './redux/ThumbnailSessionsSlice';

const ItemType = 'LAYER';

const Layer = ({ layer, index, moveLayer, selectedLayerId }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: ItemType,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveLayer(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index, id: layer.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className={`layer ${layer.id === selectedLayerId ? 'selected' : ''}`}
      onMouseEnter={() => (document.body.style.cursor = 'move')}
      onMouseLeave={() => (document.body.style.cursor = 'default')}
    >
      <span>{layer.name}</span>
    </div>
  );
};

const ThumbnailEditor = ({
  canvasRef, 
  fabricCanvasRef,
  fabricObjectsRef,
  identifyObjectType,
  debouncedUpdateLayers,
  handleAddShape, 
  handleAddText,
  // refreshCanvas,
  saveCanvas,
  canvasInit,
}) => {
  // console.log('ThumbnailEditor props:', { canvasRef, fabricObjectsRef, layers, handleLoadImage, handleAddShape, handleAddText, saveCanvas, canvasInit });

  const { selectedThumbnailId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const selectedObject = useSelector((state) => state.thumbnailCanvas.selectedObject);
  const isTextInputModalOpen = useSelector((state) => state.thumbnailCanvas.isTextInputModalOpen);
  const currentTextGroup = useSelector((state) => state.thumbnailCanvas.currentTextGroup);
  const newText = useSelector((state) => state.thumbnailCanvas.newText);
  const clipboard = useSelector((state) => state.thumbnailCanvas.clipboard);
  // const originalLayerIndex = useSelector((state) => state.thumbnailCanvas.originalLayerIndex);
  const layers = useSelector((state) => state.thumbnailCanvas.layers);
  const selectedLayerId = useSelector((state) => state.thumbnailCanvas.selectedLayerId);
  const currentLayers = useSelector((state) => state.thumbnailCanvas.currentLayers);
  const strokeWidth = useSelector((state) => state.thumbnailCanvas.strokeWidth);
  const strokeColor = useSelector((state) => state.thumbnailCanvas.strokeColor);
  const strokeOpacity = useSelector((state) => state.thumbnailCanvas.strokeOpacity);
  const shadowColor = useSelector((state) => state.thumbnailCanvas.shadowColor);
  const shadowBlur = useSelector((state) => state.thumbnailCanvas.shadowBlur);
  const fillColor = useSelector((state) => state.thumbnailCanvas.fillColor);
  const fillOpacity = useSelector((state) => state.thumbnailCanvas.fillOpacity);
  const textBackgroundColor = useSelector((state) => state.thumbnailCanvas.textBackgroundColor);
  const textBackgroundOpacity = useSelector((state) => state.thumbnailCanvas.textBackgroundOpacity);
  const fontFamily = useSelector((state) => state.thumbnailCanvas.fontFamily);
  const selectedObjectType = useSelector((state) => state.thumbnailCanvas.selectedObjectType);
  const [hotTip] = useState("Hot tip: Here you can add shapes, and edit or add text. Text isn't editable if it is generated inside the image");

  const handleDeleteObject = useCallback(() => {
    console.log('handleDeleteObject');
    const canvas = fabricCanvasRef.current;
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      const id = activeObject.id;
      canvas.remove(activeObject);
      fabricObjectsRef.current.delete(id);
      dispatch(setRemoveLayer(id)); // Triggering the setRemoveLayer action
      dispatch(setSelectedObject(null));
    }
  }, [dispatch, fabricCanvasRef, fabricObjectsRef]);

  const handleCopy = useCallback(() => {
    const canvas = fabricCanvasRef.current;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().clone((cloned) => {
        dispatch(setClipboard(cloned));
      });
    }
  }, [dispatch, fabricCanvasRef]);
  
  const handlePaste = useCallback(() => {
    const canvas = fabricCanvasRef.current;
    if (clipboard) {
      clipboard.clone((clonedObj) => {
        canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        if (clonedObj.type === 'activeSelection') {
          clonedObj.canvas = canvas;
          clonedObj.forEachObject((obj) => {
            canvas.add(obj);
          });
          clonedObj.setCoords();
        } else {
          canvas.add(clonedObj);
        }
        canvas.setActiveObject(clonedObj);
        canvas.requestRenderAll();
        const id = Date.now().toString();
        fabricObjectsRef.current.set(id, clonedObj);
        dispatch(addLayer({ id, name: `Pasted ${layers.length + 1}` }));
      });
    }
  }, [clipboard, dispatch, layers, fabricCanvasRef, fabricObjectsRef]);

  const getJSON = async (abortController) => {
    try {
      await canvasInit();
      const timestamp = new Date().getTime();
      const response = await fetch(`https://tubethumbs-thumbnail-jsons.s3.us-west-1.amazonaws.com/${selectedThumbnailId}.json?t=${timestamp}`, { signal: abortController.signal });
      const result = await response.json();
      dispatch(setLoadCanvas(result));
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    if (!selectedThumbnailId) return;
  
    const abortController = new AbortController();
    getJSON(abortController);
  
    return () => {
      abortController.abort();
    };
  }, [selectedThumbnailId]);

  useEffect(() => {
    // console.log(isLoading)
  }, [isLoading]);

  const refreshCanvas = useCallback(async () => {
    // console.log('refreshCanvas')
    // console.log(currentLayers)
    return new Promise(async (resolve, reject) => {
        const canvas = fabricCanvasRef.current;
        if (!canvas) {
            console.error("Canvas not initialized");
            reject("Canvas not initialized");
            return;
        }
        const addObjectsToCanvas = async () => {
            for (const layer of currentLayers.slice().reverse()) {
                const objectData = layer.object;
                let object = null;
                switch (objectData.type) {
                    case 'image':
                      const img = await new Promise((resolve) => {
                        fabric.Image.fromURL(
                            objectData.src,
                            (img) => {
                                img.set({ ...objectData, id: layer.id });
                                fabricObjectsRef.current.set(layer.id, img);
                                resolve(img);
                            },
                            { crossOrigin: 'anonymous' }
                        );
                      });
                      fabricObjectsRef.current.set(layer.id, img);
                      break;
                    case 'group':
                        const objects = await Promise.all(objectData.objects.map(async obj => {
                            switch (obj.type) {
                              case 'image':
                                return new Promise((resolve) => {
                                    fabric.Image.fromURL(
                                        obj.src,
                                        (img) => {
                                            img.set({ ...obj });
                                            resolve(img);
                                        },
                                        { crossOrigin: 'anonymous' }
                                    );
                                });
                                case 'path':
                                    return new fabric.Path(obj.path, obj);
                                case 'textbox':
                                    return new fabric.Text(obj.text, obj);
                                case 'text':
                                  return new fabric.Text(obj.text, obj);
                                default:
                                    console.error(`Unsupported object type: ${obj.type}`);
                                    return null;
                            }
                        })).then(results => results.filter(obj => obj !== null));
                        object = new fabric.Group(objects, { ...objectData, id: layer.id });
                        // canvas.add(object);
                        fabricObjectsRef.current.set(layer.id, object);
                        break;
                    case 'rect':
                        object = new fabric.Rect({ ...objectData, id: layer.id });
                        // canvas.add(object);
                        fabricObjectsRef.current.set(layer.id, object);
                        break;
                    case 'textbox':
                        object = new fabric.Text(objectData.text, { ...objectData, id: layer.id });
                        // canvas.add(object);
                        fabricObjectsRef.current.set(layer.id, object);
                        break;
                    default:
                        console.error(`Unsupported object type: ${objectData.type}`);
                        break;
                }
            }
        };

        try {
            await addObjectsToCanvas();
            canvas.clear();  // Clear the canvas after all objects are ready to be added
            currentLayers.slice().reverse().forEach(layer => {
                const object = fabricObjectsRef.current.get(layer.id);
                if (object) {
                    canvas.add(object);
                }
            });
            canvas.renderAll();  // Final render after all objects are added
            resolve();
        } catch (error) {
            reject(error);
        }
    });
  }, [currentLayers, fabricObjectsRef, fabricCanvasRef]);
  
  useEffect(() => {
    // console.log(currentLayers)
    // console.log('CurrentLayers changed, not refreshing yet')
    if (store.getState().thumbnailCanvas.triggerRefresh) {
      // console.log('Refreshing now')
      // console.log(store.getState().thumbnailCanvas.currentLayers)
      refreshCanvas(store.getState().thumbnailCanvas.currentLayers).then(() => {
        setIsLoading(false); // Set loading to false after canvas refresh
      });
      dispatch(setTriggerRefresh(false));
    }
  }, [currentLayers, refreshCanvas, dispatch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete') {
        handleDeleteObject();
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        handleCopy();
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        handlePaste();
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleDeleteObject, handleCopy, handlePaste, clipboard]);

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.key === 'Delete') {
  //       handleDeleteObject();
  //     }
  //   };

  //   // Attach the event listener
  //   window.addEventListener('keydown', handleKeyDown);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [handleDeleteObject]); // Add handleDeleteObject to the dependency array

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.key === 'Delete') {
  //       handleDeleteObject();
  //     } else if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
  //       handleCopy();
  //     } else if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
  //       handlePaste();
  //     }
  //   };

  //   // Attach the event listener
  //   window.addEventListener('keydown', handleKeyDown);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [clipboard]); // Add clipboard to the dependency array

  

  // const handleObjectCleared = () => {
  //   if (selectedObject && originalLayerIndex !== null) {
  //     const object = fabricObjectsRef.current.get(selectedObject);
  //     if (object) {
  //       fabricCanvasRef.current.moveTo(object, originalLayerIndex);
  //     }
  //   }
  //   dispatch(setSelectedObject(null));
  //   // dispatch(setSelectedLayerId(null));
  // };

  // const handleKeyDown = (e) => {
  //   if (e.key === 'Delete' || e.key === 'Backspace') {
  //     handleDeleteObject();
  //   } else if (e.ctrlKey && e.key === 'c') {
  //     handleCopy();
  //   } else if (e.ctrlKey && e.key === 'v') {
  //     handlePaste();
  //   } else if (e.ctrlKey && e.key === 'z') {
  //     dispatch(undo());
  //   } else if (e.ctrlKey && e.key === 'y') {
  //     dispatch(redo());
  //   }
  // };

  // const handleMouseDown = () => {
  //   // console.log('handleMouseDown')
  //   setIsMouseDown(true);
  // }

  // const handleMouseUp = () => {
  //   // console.log('handleMouseUp')npm 
  //   setIsMouseDown(false);
  //   // updateLayers(); // Update layers on mouse release
  // };
  
  const handleBack = async () => {
    // await refreshCanvas()
    setIsLoading(true);
    fabricCanvasRef.current.setZoom(1);
    await saveCanvas(selectedThumbnailId, 'preview', 'editor');
    navigate('/thumbnails/create');
  };

  const handleNavigateToSession = async (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        fabricCanvasRef.current.setZoom(1);
        await saveCanvas(selectedThumbnailId, 'preview', 'editor');
        
        if (params.createNew) {
          dispatch(setTriggerCreateSessionFromEditor(true));
        }  
        navigate(`/thumbnails/create/${params.sessionId}`);
        
        resolve(); // Resolving the promise when all operations are done.
      } catch (error) {
        reject(error); // Rejecting the promise if an error occurs.
      }
    });
  };
  
  // const handleTextGroupMouseDown = (group, event) => {
  //   if (event.e.detail === 2) {
  //     dispatch(setCurrentTextGroup(group));
  //     dispatch(setNewText(group.getObjects()[1].text));
  //     dispatch(setIsTextInputModalOpen(true));
  //   }
  // };

  function hexToRgba(hex, alpha) {
    // Remove the hash if it's there
    hex = hex.replace(/^#/, '');
  
    // Convert 3-digit hex to 6-digit hex
    if (hex.length === 3) {
      hex = hex.split('').map(hexChar => hexChar + hexChar).join('');
    }
  
    // Convert hex to RGB values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    // Return the RGBA string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  const handleTextSubmit = () => {
    const id = uuidv4();
    if (currentTextGroup) {
      const [strokeObj, fillObj] = currentTextGroup.getObjects();
      fabricCanvasRef.current.remove(currentTextGroup);
      const oldGroupId = currentTextGroup.id;
      let left = currentTextGroup.left;
      let top = currentTextGroup.top;

      strokeObj.set('text', newText);
      fillObj.set('text', newText);
      let groupobj = {
        left: left,
        top: top,
        selectable: true,
        evented: true,
        id: id,
    };
    // Have to create a new group and then destroy the old group because the width of the group doesn't auto adjust to the new text otherwise
      const group = new fabric.Group([strokeObj, fillObj], groupobj);
      fabricCanvasRef.current.add(group);
      fabricObjectsRef.current.set(id, group);
      const serializedGroup = group.toObject();
      const newLayer = { id, name: newText, object: serializedGroup };
      dispatch(addLayer(newLayer));
      dispatch(setRemoveLayer(oldGroupId));
      fabricCanvasRef.current.setActiveObject(group) 
      fabricCanvasRef.current.renderAll();
      dispatch(setIsTextInputModalOpen(false));
      dispatch(setCurrentTextGroup(null));
    }
  };

  const handleTextFontChange = (event) => {
    console.log('handleTextFontChange')
    // const font = event.target.value;
    // dispatch(setLeftNavFontFamily(font));
    // const object = fabricObjectsRef.current.get(selectedObject);
    // if (object) {
    //   const textStroke = object.getObjects()[0];
    //   const textFill = object.getObjects()[1];
    //   textStroke.set('fontFamily', font);
    //   textFill.set('fontFamily', font);
    // // Have to create a new group and then destroy the old group because the width of the group doesn't auto adjust to the new text otherwise

    //   fabricCanvasRef.current.setActiveObject(object); //Keeps object selected
    //   fabricCanvasRef.current.renderAll();
    // }
    const font = event.target.value;
    console.log(font)
    const currentTextGroup = fabricObjectsRef.current.get(selectedObject);
    const id = uuidv4();
    console.log(currentTextGroup)
    if (currentTextGroup) {
      const [strokeObj, fillObj] = currentTextGroup.getObjects();
      fabricCanvasRef.current.remove(currentTextGroup);
      const oldGroupId = currentTextGroup.id;
      let left = currentTextGroup.left;
      let top = currentTextGroup.top;

      strokeObj.set('fontFamily', font);
      fillObj.set('fontFamily', font);
      let groupobj = {
        left: left,
        top: top,
        selectable: true,
        evented: true,
        id: id,
    };
    // Have to create a new group and then destroy the old group because the width of the group doesn't auto adjust to the new text otherwise
      const group = new fabric.Group([strokeObj, fillObj], groupobj);
      fabricCanvasRef.current.add(group);
      fabricObjectsRef.current.set(id, group);
      const serializedGroup = group.toObject();
      const newLayer = { id, name: newText, object: serializedGroup };
      dispatch(addLayer(newLayer));
      dispatch(setRemoveLayer(oldGroupId));
      fabricCanvasRef.current.setActiveObject(group) 
      fabricCanvasRef.current.renderAll();
      // dispatch(setIsTextInputModalOpen(false));
      dispatch(setCurrentTextGroup(null));
      dispatch(setLeftNavFontFamily(font));
    }
  };

  const handleStrokeColorChange = (event) => {
    const selectedObject = store.getState().thumbnailCanvas.selectedObject;
    const object = fabricObjectsRef.current.get(selectedObject);
    if (object) {
      const color = event.target.value;
      const opacity = 1
      const rgbaColor = hexToRgba(color, opacity)
      let type = identifyObjectType(object)
      if (type === 'shape') {
        object.set('stroke', rgbaColor);
      } else if (type === 'textbox') {
        const textStroke = object.getObjects()[0];
        textStroke.set('stroke', rgbaColor);
      } else if (type === 'image') {
        const path = object.getObjects().find((obj) => obj.type === 'path');
        path.set('stroke', rgbaColor);
      }
      fabricCanvasRef.current.setActiveObject(object); //Keeps object selected
      fabricCanvasRef.current.renderAll();
      debouncedUpdateLayers();
      dispatch(setLeftNavStrokeColor(color));
      dispatch(setLeftNavStrokeOpacity(opacity));
    }
  };

  const handleStrokeWidthChange = (event) => {
    const width = parseInt(event.target.value, 10);
    const selectedObject = store.getState().thumbnailCanvas.selectedObject;
    const object = fabricObjectsRef.current.get(selectedObject);
    if (object) {
      let type = identifyObjectType(object)
      if (type === 'shape') {
        object.set('strokeWidth', width);
      } else if (type === 'textbox') {
        // console.log(object.getObjects()[0].type)
        const textStroke = object.getObjects()[0];
        const textFill = object.getObjects()[1];
        // console.log(textStroke)
        textStroke.set('strokeWidth', width);
        textFill.set({
          left: textStroke.left,
          top: textStroke.top,
        });
      } else if (type === 'image') {
        const imagePath = object.getObjects().find((obj) => obj.type === 'path');
        const image = object.getObjects().find((obj) => obj.type === 'image');
        // Calculate the offset
        const oldCenter = imagePath.getCenterPoint();
        imagePath.set('strokeWidth', width);
        const newCenter = imagePath.getCenterPoint();
        const offsetX = (newCenter.x - oldCenter.x)/2;
        const offsetY = (newCenter.y - oldCenter.y)/2;
  
        image.set({
          left: image.left + offsetX,
          top: image.top + offsetY,
        });
      }
      fabricCanvasRef.current.setActiveObject(object); //Keeps object selected
      fabricCanvasRef.current.renderAll();
      debouncedUpdateLayers();
      dispatch(setLeftNavStrokeWidth(width));
    }
  };

  const handleShadowColorChange = (event) => {
    const color = event.target.value;
    const selectedObject = store.getState().thumbnailCanvas.selectedObject;
    const object = fabricObjectsRef.current.get(selectedObject);
    if (object) {
      let type = identifyObjectType(object)
      if (type === 'shape') {
        object.set({
          shadow: {
            color: color,
            offsetX: 0,
            offsetY: 0,
            blur: shadowBlur,
          },
        });
      } else if (type === 'image') {
        const path = object.getObjects()[0];
        path.set({
          shadow: {
            color: color,
            offsetX: 0,
            offsetY: 0,
            blur: shadowBlur,
          },
        });
      } else if (type === 'textbox') {
        const textStroke = object.getObjects()[0];
        textStroke.set({
          shadow: {
            color: color,
            offsetX: 0,
            offsetY: 0,
            blur: shadowBlur,
          },
        });
      }
      let blur = {}
      blur.target = {}
      blur.target.value = shadowBlur
      //weird bug that changinging color in itself doesn't render properly
      //but changing the shadow blur after the color works
      //so this forces a shadow blur change which both updates the color and maintains the blur
      handleShadowBlurChange(blur)
      dispatch(setLeftNavShadowColor(color));
    }
  };
  
  const handleShadowBlurChange = (event) => {
    const blur = parseInt(event.target.value, 10);
    const selectedObject = store.getState().thumbnailCanvas.selectedObject;
    const object = fabricObjectsRef.current.get(selectedObject);
    if (object) {
      let type = identifyObjectType(object)
      if (type === 'shape') {
        object.set({
          shadow: {
            color: shadowColor,
            offsetX: 0,
            offsetY: 0,
            blur: blur,
          },
        });
      } else if (type === 'image') {
        const image = object.getObjects()[1];
        image.set({
          shadow: {
            color: shadowColor,
            offsetX: 0,
            offsetY: 0,
            blur: blur,
          },
        });
      } else if (type === 'textbox') {
        const textFill = object.getObjects()[1];
        textFill.set({
          shadow: {
            color: shadowColor,
            offsetX: 0,
            offsetY: 0,
            blur: blur,
          },
        });
      }
      fabricCanvasRef.current.setActiveObject(object); //Keeps object selected
      let originalScale = object.scaleX 
      object.scale(originalScale * 1.01);
      object.setCoords();
      fabricCanvasRef.current.renderAll();
      object.scale(originalScale);
      object.setCoords();
      fabricCanvasRef.current.renderAll();
      debouncedUpdateLayers();
      dispatch(setLeftNavShadowBlur(blur));
    }
  };

  const handleFillColorChange = (event) => {
    // const width = parseInt(event.target.value, 10);
    const color = event.target.value;
    const selectedObject = store.getState().thumbnailCanvas.selectedObject;
    const object = fabricObjectsRef.current.get(selectedObject);
    if (object) {
      let type = identifyObjectType(object)
      if (type === 'shape') {
        const fillOpacity = store.getState().thumbnailCanvas.fillOpacity; // Retain the fill opacity state
        object.set({
          fill: color,
          opacity: fillOpacity
        });
      } else if (type === 'textbox') {
        const textFill = object.getObjects()[1];
        textFill.set('fill', color);
        // }
      }
      fabricCanvasRef.current.setActiveObject(object); //Keeps object selected
      fabricCanvasRef.current.renderAll();
      debouncedUpdateLayers();
      dispatch(setLeftNavFillColor(color));
    }
  };

  const handleStrokeOpacityChange = (event) => {
    const opacity = parseFloat(event.target.value);
    const selectedObject = store.getState().thumbnailCanvas.selectedObject;
    const object = fabricObjectsRef.current.get(selectedObject);
    if (object) {
      let type = identifyObjectType(object)
      if (type === 'shape') {
        const strokeColor = new fabric.Color(object.stroke);
        const rgbaColor = strokeColor.setAlpha(opacity).toRgba(); // Convert the color to RGBA with the new opacity
        object.set('stroke', rgbaColor);
      } else if (type === 'textbox') {
        const textStroke = object.getObjects()[0];
        const strokeColor = new fabric.Color(textStroke.stroke);
        const rgbaColor = strokeColor.setAlpha(opacity).toRgba();
        textStroke.set('stroke', rgbaColor);
      } else if (type === 'image') {
        const imagePath = object.getObjects().find((obj) => obj.type === 'path');
        const strokeColor = new fabric.Color(object.stroke);
        const rgbaColor = strokeColor.setAlpha(opacity).toRgba(); // Convert the color to RGBA with the new opacity
        imagePath.set('stroke', rgbaColor);  // Set the stroke color with updated RGBA
      }
      fabricCanvasRef.current.setActiveObject(object); //Keeps object selected
      fabricCanvasRef.current.renderAll();  // Re-render the canvas
      debouncedUpdateLayers();
      dispatch(setLeftNavStrokeOpacity(opacity));
    }
  };
  
  const handleFillOpacityChange = (event) => {
    const opacity = parseFloat(event.target.value);
    const selectedObject = store.getState().thumbnailCanvas.selectedObject;
    const object = fabricObjectsRef.current.get(selectedObject);
    if (object) {
      let type = identifyObjectType(object)
      if (type === 'shape') {
        const currentFillColor = object.get('fill');
        const fillColor = new fabric.Color(currentFillColor).setAlpha(opacity).toRgba();
        object.set('fill', fillColor);
      } else if (type === 'textbox') { 
        const textFill = object.getObjects()[1];
        const strokeColor = new fabric.Color(textFill.stroke);
        const rgbaColor = strokeColor.setAlpha(opacity).toRgba();
        textFill.set('fill', rgbaColor);
      } else if (type === 'image') { 
        const imageFill = object.getObjects()[1];
        console.log('image opacity')
        // const strokeColor = new fabric.Color(imageFill.stroke);
        // const rgbaColor = strokeColor.setAlpha(opacity).toRgba();
        imageFill.set('opacity', opacity);
      }
      fabricCanvasRef.current.setActiveObject(object); //Keeps object selected
      fabricCanvasRef.current.renderAll();
      debouncedUpdateLayers();
      dispatch(setLeftNavFillOpacity(opacity));
    }
};

const handleTextBackgroundColorChange = (event) => {
  const selectedObject = store.getState().thumbnailCanvas.selectedObject;
    const object = fabricObjectsRef.current.get(selectedObject);
    if (object) {
      const color = event.target.value;
      const opacity = 1
      const rgbaColor = hexToRgba(color, opacity)
      let type = identifyObjectType(object)
      if (type === 'textbox') {
        const textStroke = object.getObjects()[0];
        textStroke.set('backgroundColor', rgbaColor);
      } 
      fabricCanvasRef.current.setActiveObject(object); //Keeps object selected
      fabricCanvasRef.current.renderAll();
      debouncedUpdateLayers();
      dispatch(setLeftNavTextBackgroundColor(color));
      dispatch(setLeftNavTextBackgroundOpacity(opacity));
    }
};

const handleTextBackgroundOpacityChange = (event) => {
  const opacity = parseFloat(event.target.value);
  const selectedObject = store.getState().thumbnailCanvas.selectedObject;
  const object = fabricObjectsRef.current.get(selectedObject);
  if (object) {
    let type = identifyObjectType(object)
    if (type === 'textbox') { 
      const textStroke = object.getObjects()[0];
      const backgroundColor = new fabric.Color(textStroke.backgroundColor);
      const rgbaColor = backgroundColor.setAlpha(opacity).toRgba();
      textStroke.set('backgroundColor', rgbaColor);
    } 
    fabricCanvasRef.current.setActiveObject(object); //Keeps object selected
    fabricCanvasRef.current.renderAll();
    debouncedUpdateLayers();
    dispatch(setLeftNavTextBackgroundOpacity(opacity));
  }
};

const handleMirrorImageY = () => {
  console.log("Mirror Image button clicked");
  const selectedObject = store.getState().thumbnailCanvas.selectedObject;
  const object = fabricObjectsRef.current.get(selectedObject);
  if (object && object.type === 'group') {
    console.log("Selected object is a group, mirroring the group");
    object.set('flipX', !object.flipX); // Flip the group on the Y axis
    object.setCoords(); // Update the group's coordinates
    fabricCanvasRef.current.renderAll(); // Re-render the canvas to apply changes
    debouncedUpdateLayers(); // Update layers to reflect the new state
  } else {
    console.log("No group selected or object is not a group");
  }
};

const handleMirrorImageX = () => {
  console.log("Mirror Image button clicked");
  const selectedObject = store.getState().thumbnailCanvas.selectedObject;
  const object = fabricObjectsRef.current.get(selectedObject);
  if (object && object.type === 'group') {
    console.log("Selected object is a group, mirroring the group");
    object.set('flipY', !object.flipY); // Flip the group on the Y axis
    object.setCoords(); // Update the group's coordinates
    fabricCanvasRef.current.renderAll(); // Re-render the canvas to apply changes
    debouncedUpdateLayers(); // Update layers to reflect the new state
  } else {
    console.log("No group selected or object is not a group");
  }
};


  const handleMoveLayer = (dragIndex, hoverIndex) => {
    // console.log('handleMoveLayer')
    let payload = {
      dragIndex: dragIndex,
      hoverIndex: hoverIndex
    }
    dispatch(moveLayer(payload))
  };

  const handlePublish = async () => {
    console.log('handlePublish')
    setIsLoading(true)
    let saveReturns = await saveCanvas(selectedThumbnailId, 'full','editor')    
    console.log(saveReturns)
    if (saveReturns === 'success') {
      navigate(`/thumbnails/publish/${selectedThumbnailId}`);
  }
  };

  const defaultRectangle = {
    type: 'rectangle',
    left: 100,
    top: 100,
    fill: '#000000',
    width: 100,
    height: 100,
    stroke: '#ffffff',
    strokeWidth: 10,
    shadow: {
      color: '#000000',
      blur: 0,
      offsetX: 0,
      offsetY: 0,
    }
  }

  const defaultEllipse = {
    type: 'ellipse',
    left: 100,
    top: 100,
    fill: '#000000',
    rx: 50,
    ry: 50,
    stroke: '#ffffff',
    strokeWidth: 10,
    shadow: {
      color: '#000000',
      blur: 0,
      offsetX: 0,
      offsetY: 0,
    }
  }

  const defaultTriangle = {
    type: 'triangle',
    left: 100,
    top: 100,
    fill: '#000000',
    width: 100,
    height: 100,
    stroke: '#ffffff',
    strokeWidth: 10,
    shadow: {
      color: '#000000',
      blur: 0,
      offsetX: 0,
      offsetY: 0,
    }
  }

  const defaultText = {
    caption: 'Text',
    left: 100,
    top: 100,
    fontColor: '#fff',
    fontOpacity: 100,
    // width: 100,
    // height: 100,
    strokeColor: '#000',
    strokeWidth: 10,
    fontWeight: 600,
    backgroundColor: '#000',
    backgroundOpacity: 0,
    shadow: {
      color: '#000000',
      blur: 0,
      offsetX: 0,
      offsetY: 0,
    },
    fontFamily: "Arial Black",
    width: 1500,
    textAlign: 'left',
    skewY: 0,
    fontSize: 120,
  }
  
  return (
    <div className="universal-container">
      <MainTopNav />
      <ThumbnailLeftNav handleNavigateToSession={handleNavigateToSession}/> 
      <div className="universal-content-container thumbnails-content-container">
        <div className="thumbnails-main-content">
        <div className='hot-tip-banner'>
            {hotTip}
          </div>
          <div className='editor-content-container'>
            <div className="editor-left-column">
              <h2>Editor</h2>
                {selectedObjectType === 'image' && (
                  <div className="editor-element-editor">
                    <h3>Image</h3>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="imageStrokeOpacity">Opacity</label>
                      <input
                        type="range"
                        id="imageFillOpacity"
                        min="0"
                        max="1"
                        step="0.01"
                        value={fillOpacity}
                        onChange={handleFillOpacityChange}
                      />
                    </div>
                    <div className='editor-image-button-wrapper'> 
                      <button onClick={handleMirrorImageY}>Mirror Y</button>
                      <button onClick={handleMirrorImageX}>Mirror X</button>
                    </div>
                    <div className="editor-label-color-wrapper">
                      <h3>Stroke</h3>
                      <div className="editor-label-wrapper">
                        <input
                          type="color"
                          id="imageStrokeColor"
                          value={strokeColor}
                          onChange={handleStrokeColorChange}
                        />
                      </div>
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="textStrokeWidth">Width</label>
                      <input
                        type="range"
                        id="imageStrokeWidth"
                        min="0"
                        max="20"
                        value={strokeWidth}
                        onChange={handleStrokeWidthChange}
                      />
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="imageFillOpacity">Opacity:</label>
                      <input
                        type="range"
                        id="imageStrokeOpacity"
                        min="0"
                        max="1"
                        step="0.01"
                        value={strokeOpacity}
                        onChange={handleStrokeOpacityChange}
                      />
                    </div>
                    <div className='editor-spacer' />
                    <div className="editor-label-color-wrapper">
                      <h3>Shadow</h3>
                      <div className="editor-label-wrapper">
                        <input
                          type="color"
                          id="shadowColor"
                          value={shadowColor}
                          onChange={handleShadowColorChange}
                        />
                      </div>
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="imageFillOpacity">Blur</label>
                      <input
                        type="range"
                        id="shadowBlur"
                        min="0"
                        max="100"
                        value={shadowBlur}
                        onChange={handleShadowBlurChange}
                      />
                    </div>
                  </div>
                )}
                {selectedObjectType === 'textbox' && (
                  <div className="editor-element-editor">
                    {/* Text Fill Section */}
                    <div className="editor-label-color-wrapper">
                      <h3>Text Fill</h3>
                      <input
                        type="color"
                        id="textFillColor"
                        value={fillColor}
                        onChange={handleFillColorChange}
                        className="editor-color-picker"
                      />
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="shapeFillOpacity">Opacity</label>
                      <input
                        type="range"
                        id="shapeFillOpacity"
                        min="0"
                        max="1"
                        step="0.01"
                        className="editor-slider-picker"
                        value={fillOpacity}
                        onChange={handleFillOpacityChange}
                      />
                    </div>
                    <div className='editor-spacer' />
                    <div className="editor-label-color-wrapper">
                      <h3>Stroke</h3>
                      <div className="editor-label-wrapper">
                        <input
                          type="color"
                          id="textStrokeColor"
                          value={strokeColor}
                          onChange={handleStrokeColorChange}
                          className="editor-color-picker"
                        />
                      </div>
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="textStrokeWidth">Width</label>
                      <input
                        type="range"
                        id="textStrokeWidth"
                        min="0"
                        max="20"
                        value={strokeWidth}
                        onChange={handleStrokeWidthChange}
                        className="editor-slider-picker"
                      />
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="textStrokeOpacity">Opacity</label>
                      <input
                        type="range"
                        id="textStrokeOpacity"
                        min="0"
                        max="1"
                        step="0.01"
                        value={strokeOpacity}
                        onChange={handleStrokeOpacityChange}
                        className="editor-slider-picker"
                      />
                    </div>
                    <div className='editor-spacer' />
                    <div className="editor-label-color-wrapper">
                      <h3>Background</h3>
                      <input
                        type="color"
                        id="textBackgroundColor"
                        value={textBackgroundColor}
                        onChange={handleTextBackgroundColorChange}
                        className="editor-color-picker"
                      />
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="shapeBackgroundOpacity">Opacity</label>
                      <input
                        type="range"
                        id="shapeBackgroundOpacity"
                        min="0"
                        max="1"
                        step="0.01"
                        value={textBackgroundOpacity}
                        onChange={handleTextBackgroundOpacityChange}
                        className="editor-slider-picker"
                      />
                    </div>
                    <div className='editor-spacer' />
                    <div className="shadow-editor">
                      <div className="editor-label-wrapper">
                      <h3>Shadow</h3>
                        <input
                          type="color"
                          id="shadowColor"
                          value={shadowColor}
                          onChange={handleShadowColorChange}
                          className="editor-color-picker"
                        />
                      </div>
                      <div className="editor-label-wrapper">
                        <label className="editor-label" htmlFor="shadowBlur">Shadow</label>
                        <input
                          type="range"
                          id="shadowBlur"
                          min="0"
                          max="100"
                          value={shadowBlur}
                          onChange={handleShadowBlurChange}
                          className="editor-slider-picker"
                        />
                      </div>
                    </div>
                    <div className='editor-spacer' />
                    <div className="editor-label-wrapper">
                      <h3>Font</h3>
                      <select id="textFont" value={fontFamily} onChange={handleTextFontChange} className="editor-select-picker">
                        <option value="Anton">Anton</option>
                        <option value="Arial">Arial</option>
                        <option value="Archivo Black">Archivo Black</option>
                        <option value="Bangers">Bangers</option>
                        <option value="Bebas Neue">Bebas Neue</option>
                        <option value="Calibri">Calibri</option>
                        <option value="Courier New">Courier New</option>
                        <option value="Fira Sans">Fira Sans</option>
                        <option value="Garamond">Garamond</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Handjet">Handjet</option>
                        <option value="Helvetica">Helvetica</option>
                        <option value="Impact">Impact</option>
                        <option value="Indie Flower">Indie Flower</option>
                        <option value="Inter">Inter</option>
                        <option value="Lato">Lato</option>
                        <option value="League Spartan">League Spartan</option>
                        <option value="Lilita One">Lilita One</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Noto Sans Display">Noto Sans Display</option>
                        <option value="Open Sans">Open Sans</option>
                        <option value="Oswald">Oswald</option>
                        <option value="Playfair Display">Playfair Display</option>
                        <option value="Poppins">Poppins</option>
                        <option value="Raleway">Raleway</option>
                        <option value="Roboto">Roboto</option>
                        <option value="Satisfy">Satisfy</option>
                        <option value="Squada One">Squada One</option>
                        <option value="Tahoma">Tahoma</option>
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Trebuchet MS">Trebuchet MS</option>
                        <option value="Verdana">Verdana</option>
                      </select>
                    </div>
                  </div>
                )}
                  {selectedObjectType === 'shape' && (
                  <div className="editor-element-editor">
                    <div className="editor-label-color-wrapper">
                      <h3>Shape Fill</h3>
                      <input
                        type="color"
                        id="shapeFillColor"
                        value={fillColor}
                        onChange={handleFillColorChange}
                      />
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="shapeFillOpacity">Fill Opacity:</label>
                      <input
                        type="range"
                        id="shapeFillOpacity"
                        min="0"
                        max="1"
                        step="0.01"
                        value={fillOpacity}
                        onChange={handleFillOpacityChange}
                      />
                    </div>
                    <div className='editor-spacer' />
                    <div className="editor-label-color-wrapper">
                      <h3>Stroke</h3>
                      <input
                        type="color"
                        id="shapeStrokeColor"
                        value={strokeColor}
                        onChange={handleStrokeColorChange}
                      />
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="shapeStrokeWidth">Width</label>
                      <input
                        type="range"
                        id="shapeStrokeWidth"
                        min="0"
                        max="20"
                        value={strokeWidth}
                        onChange={handleStrokeWidthChange}
                      />
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="shapeStrokeOpacity">Opacity</label>
                      <input
                        type="range"
                        id="shapeStrokeOpacity"
                        min="0"
                        max="1"
                        step="0.01"
                        value={strokeOpacity}
                        onChange={handleStrokeOpacityChange}
                      />
                    </div>
                    <div className='editor-spacer' />
                    <div className="shadow-editor">
                    <div className="editor-label-color-wrapper">
                      <h3>Shadow</h3>
                        <input
                          type="color"
                          id="shadowColor"
                          value={shadowColor}
                          onChange={handleShadowColorChange}
                        />
                    </div>
                    <div className="editor-label-wrapper">
                      <label className="editor-label" htmlFor="shadowBlur">Blur</label>
                        <input
                          type="range"
                          id="shadowBlur"
                          min="0"
                          max="100"
                          value={shadowBlur}
                          onChange={handleShadowBlurChange}
                        />
                      </div>
                    </div>
                  </div>
                  )}
                  <div className="layers-panel">
                    <h3>Layers</h3>
                    <DndProvider backend={HTML5Backend}>
                      <div className="layers-list">
                        {currentLayers.map((layer, index) => (
                          <Layer
                            key={layer.id || index}
                            layer={layer}
                            index={index}
                            moveLayer={handleMoveLayer}
                            selectedLayerId={selectedLayerId}
                          />
                        ))}
                      </div>
                    </DndProvider>
                  </div>
              </div>
              <div className='editor-main-content'>
                {isLoading && (
                  <div className="loading-overlay">
                    <img src="../../logo-dancing-transparent.gif" alt='loading' style={{width:'75px'}}/>
                  </div>
                )}
                <div className="editor-navbar">
                  <div className="editor-navbar-button-wrapper">
                    <button className="tooltip-button" onClick={() => handleAddShape(defaultRectangle)}>
                      <FontAwesomeIcon icon={faSquareFull} />
                      <span className="tooltip-text">Add Rectangle</span>
                    </button>
                    <button className="tooltip-button" onClick={() => handleAddShape(defaultEllipse)}>
                      <FontAwesomeIcon icon={faCircle} />
                      <span className="tooltip-text">Add Circle</span>
                    </button>
                    <button className="tooltip-button" onClick={() => handleAddShape(defaultTriangle)}>
                      {/* triangle */}
                      <div style={{
                        width: 0,
                        height: 0,
                        borderLeft: '10px solid transparent',
                        borderRight: '10x solid transparent',
                        borderBottom: '18px solid white',
                      }} />
                      <span className="tooltip-text">Add Triangle</span>
                    </button>
                    <button className="tooltip-button" onClick={() => handleAddText(defaultText)}>
                      <FontAwesomeIcon icon={faFont} />
                      <span className="tooltip-text">Add Text</span>
                    </button>
                    <button className="tooltip-button" onClick={handleDeleteObject}>
                      <FontAwesomeIcon icon={faTrashCan} />
                      <span className="tooltip-text">Delete</span>
                    </button>
                    <button className="tooltip-button" onClick={() => dispatch(undo())}>
                      <FontAwesomeIcon icon={faRotateLeft} />
                      <span className="tooltip-text">Undo</span>
                    </button>
                    <button className="tooltip-button" onClick={() => dispatch(redo())}>
                      <FontAwesomeIcon icon={faRotateRight} />
                      <span className="tooltip-text">Redo</span>
                    </button>
                  </div>
                  <div className='editor-navbar-back-publish'>
                    <button onClick={handleBack} className='thumbnail-create-modal-back-button'>SAVE AND BACK</button>
                    <button onClick = {handlePublish}>PUBLISH</button>
                  </div>
                </div>
                <div className="canvas-container">
                  <div className="custom-canvas" >
                    <canvas ref={canvasRef} />
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <TextInputModal
        isOpen={isTextInputModalOpen}
        text={newText}
        onChange={(e) => dispatch(setNewText(e.target.value))}
        onClose={() => dispatch(setIsTextInputModalOpen(false))}
        onSubmit={handleTextSubmit}
      />
    </div>
  );
};

export default ThumbnailEditor;
