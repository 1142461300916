import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  setTriggerGenerateMore, 
  setSelectedCharacterId, 
  updateSession } from './redux/ThumbnailSessionsSlice';
import MainTopNav from './MainTopNav';
import './ThumbnailTools.css';
import './ThumbnailSettings.css';
import { FaPlusCircle } from 'react-icons/fa';
import { faCircleXmark, faCircleRight, faPenToSquare } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingDelight from './LoadingDelight';
import { 
  setLoadingDelightType
} from './redux/UsersSlice';

const ToggleButtonGroup = ({ options, selectedOption, setSelectedOption }) => (
  <div className="toggle-button-group">
    {options.map((option) => (
      <button
        key={option}
        className={`toggle-button ${selectedOption === option ? 'selected' : ''}`}
        onClick={() => setSelectedOption(option)}
      >
        {option}
      </button>
    ))}
  </div>
);

const ThumbnailSettings = () => {
  const dispatch = useDispatch();
  const { selectedSessionId } = useParams(); 
  const navigate = useNavigate();

  // States for session data
  const userId = useSelector(state => state.user.userId);
  const selectedCharacterId = useSelector((state) => state.thumbnailSessions.selectedCharacterId);
  // const [selectedCharacterId, setSelectedCharacterId] = useState(null);
  const [characterUrls, setCharacterUrls] = useState([]);
  const [thumbnailPremises, setThumbnailPremises] = useState([]);
  const [thumbnailCaptions, setThumbnailCaptions] = useState([]);
  const [characterBodyType, setCharacterBodyType] = useState('');
  const [characterEthnicity, setCharacterEthnicity] = useState('');
  const [characterSkinTone, setCharacterSkinTone] = useState('');
  const [characterGender, setCharacterGender] = useState('');
  const [characterHairColor, setCharacterHairColor] = useState('');
  const [characterHairLengthInInches, setCharacterHairLengthInInches] = useState('');
  const [characterFacialHairstyle, setCharacterFacialHairstyle] = useState('');
  const [characterHairstyle, setCharacterHairstyle] = useState('');
  const [characterOutfit, setCharacterOutfit] = useState('');

  const [thumbnailImageStyle, setThumbnailImageStyle] = useState('');
  const [characterFaceExpression, setCharacterFaceExpression] = useState('');
  const [characterPose, setCharacterPose] = useState('');

  const [isAddingCustomPremise, setIsAddingCustomPremise] = useState(false);
  const [customPremiseInput, setCustomPremiseInput] = useState('');
  const [editingPremiseIndex, setEditingPremiseIndex] = useState(null);
  const [isAddingCustomCaption, setIsAddingCustomCaption] = useState(false);
  const [customCaptionInput, setCustomCaptionInput] = useState('');
  const [editingCaptionIndex, setEditingCaptionIndex] = useState(null);
  const [premiseError, setPremiseError] = useState('');
  const [captionError, setCaptionError] = useState('');
  const [isUploadAreaVisible, setIsUploadAreaVisible] = useState(false);
  const [isUploadingCharacter, setIsUploadingCharacter] = useState(false);
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth <= 481 : false
  );
  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageError, setImageError] = useState('');
  const genderOptions = ['Male', 'Female'];
  const bodyTypeOptions = ['Slim', 'Athletic', 'Average', 'Curvy', 'Obese'];

  const imageStyles = [
    { name: 'Realistic', prompt: 'photographic shot with 85mm f/1.4 lens', src: '/settings/imageStyles/realistic.jpg' },
    { name: 'Drawing', prompt: 'drawing', src: '/settings/imageStyles/drawing.jpg' },
    { name: 'Pixel art', prompt: 'pixelart', src: '/settings/imageStyles/pixel-art.jpg' },
    { name: 'Cartoon 3D', prompt: 'disney style 3d', src: '/settings/imageStyles/cartoon3d.jpg' },
    { name: 'Realistic 3D', prompt: 'gaming style 3d', src: '/settings/imageStyles/gaming3d.jpg' },
  ];

  const faceExpressions = [
    { name: 'Shock', prompt: 'eyes widened to the size of saucers, pupils dilated, mouth gaping in a silent scream', src: '/settings/faces/shock.jpg' },
    { name: 'Smile', prompt: 'Smiling with mouth closed', src: '/settings/faces/smile.jpg' },
    { name: 'Grimace', prompt: 'Teeth angrily clenched', src: '/settings/faces/grimace.jpg' },
    { name: 'Laugh', prompt: 'Laughing look with wide, squinted eyes, a large gaping grin with teeth fully visible', src: '/settings/faces/big-smile.jpg' },
    { name: 'Neutral', prompt: 'Neutral expression', src: '/settings/faces/neutral.jpg' },
    { name: 'Sad', prompt: 'In pain, tears dripping down cheeks, slightly squinted eyes, furrowed brows, and a clenched jaw', src: '/settings/faces/sad.jpg' },
    { name: 'Angry', prompt: 'Extremely angry with forehead creased, eyes furrowed, mouth open with a wide painful grimace', src: '../../settings/faces/anger.jpg' },
    { name: '=P', prompt: 'Tongue sticking out', src: '../../settings/faces/tongue.jpg' },
  ];

  const poses = [
    { name: 'Hands against face', prompt: 'Hands against cheeks', src: '../../settings/poses/hands-against-face.jpg' },
    { name: 'Relaxed', prompt: 'Arms relaxed', src: '../../settings/poses/relaxed.jpg' },
    { name: 'Thumbs up', prompt: 'Thumbs up', src: '../../settings/poses/thumbs-up.jpg' },
    { name: 'Pointing', prompt: 'Pointing at the main object of the image', src: '../../settings/poses/pointing.jpg' },
    { name: 'Arms crossed', prompt: 'Arms crossed', src: '../../settings/poses/arms-crossed.jpg' },
    { name: 'Covering mouth', prompt: 'Hand covering mouth', src: '../../settings/poses/hand-covering-mouth.jpg' },
    { name: 'Hands on head', prompt: 'Hands on head', src: '../../settings/poses/hands-on-head.jpg' },
    { name: 'Arms outward', prompt: 'Arms outward', src: '../../settings/poses/arms-out.jpg' },
    { name: 'Flexing', prompt: 'Flexing arms', src: '../../settings/poses/flexing.jpg' },
    { name: 'Hand on cheek', prompt: 'One hand against cheek', src: '../../settings/poses/hand-on-cheek.jpg' },
    { name: 'Hands on hips', prompt: 'Hands on hips', src: '../../settings/poses/hands-on-hips.jpg' },
    { name: 'Pointing up', prompt: 'Finger pointing up', src: '../../settings/poses/pointing-up.jpg' },
  ];
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 481);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch session details on component mount
  useEffect(() => {
    if (userId && selectedSessionId) {
      const fetchSessionData = async () => {
        try {
          const response = await axios.post('/api/get-selected-tubethumbsy-session', { selectedSessionId });
          const sessionData = response.data.session;
          console.log(sessionData);
          
          dispatch(setSelectedCharacterId(sessionData.characterId || null));
          setThumbnailPremises(sessionData.thumbnailPremises || []);
          setThumbnailCaptions(sessionData.thumbnailCaptions || []);
          setThumbnailImageStyle(sessionData.thumbnailImageStyle || '');
          setCharacterFaceExpression(sessionData.characterFaceExpression || '');
          setCharacterPose(sessionData.characterPose || '');
          setCharacterGender(sessionData.characterGender.charAt(0).toUpperCase() + sessionData.characterGender.slice(1) || '');
          setCharacterBodyType(sessionData.characterBodyType.charAt(0).toUpperCase() + sessionData.characterBodyType.slice(1) || '');
          setCharacterFacialHairstyle(sessionData.characterFacialHairstyle || '');
          setCharacterHairColor(sessionData.characterHairColor || '');
          setCharacterHairLengthInInches(parseInt(sessionData.characterHairLengthInInches) || '');
          setCharacterHairstyle(sessionData.characterHairstyle || '');
          setCharacterOutfit(sessionData.characterOutfit || '');

          fetchCharacters();
        } catch (error) {
          console.error('Error fetching session data:', error);
        }
      };
      fetchSessionData();
    }
  }, [selectedSessionId, userId]);

  const fetchCharacters = async () => {
    try {
      const response = await axios.post('/api/get-tubethumbsy-characters', { userId });
      const urls = response.data.characters.map(characterIdToUrl);
      setCharacterUrls(urls);
    } catch (error) {
      console.error('Error fetching characters:', error);
    }
  };

  const handleCustomPremiseSubmit = (e) => {
    e.preventDefault();
    const maxLength = 150;
  
    // Check if there are already 5 premises
    if (thumbnailPremises.length >= 5) {
      setPremiseError('You can only add up to 5 premises.');
      return;
    }
  
    // Validate input
    if (customPremiseInput.trim() === '' || customPremiseInput.length > maxLength) {
      setPremiseError(`Imagery idea must be between 1 and ${maxLength} characters.`);
      return;
    }
  
    if (editingPremiseIndex !== null) {
      // Update premise at the editing index
      setThumbnailPremises((prevPremises) =>
        prevPremises.map((premise, index) => index === editingPremiseIndex ? customPremiseInput : premise)
      );
      setEditingPremiseIndex(null);
    } else {
      // Add new premise
      setThumbnailPremises((prevPremises) => [...prevPremises, customPremiseInput]);
    }
  
    // Reset the input state
    setIsAddingCustomPremise(false);
    setCustomPremiseInput('');
    setPremiseError('');
  };

  const handleRemovePremise = (premise) => {
    setThumbnailPremises((prevPremises) => {
      const updatedPremises = prevPremises.filter((item) => item !== premise);
      if (updatedPremises.length <= 5) {
        setPremiseError('');
      }
      return updatedPremises;
    });
  };

  const handleRemoveCaption = (caption) => {
    setThumbnailCaptions((prevCaptions) =>
      prevCaptions.filter((item) => item !== caption)
    );
    if (thumbnailCaptions.length <= 5) {
      setCaptionError('');
    }
  };
  
  const handleCustomCaptionSubmit = (e) => {
    e.preventDefault();
    const maxLength = 150;
  
    // Check if there are already 5 captions
    if (thumbnailCaptions.length >= 5) {
      setCaptionError('You can only add up to 5 captions.');
      return;
    }
  
    // Validate input
    if (customCaptionInput.trim() === '' || customCaptionInput.length > maxLength) {
      setCaptionError(`Caption must be between 1 and ${maxLength} characters.`);
      return;
    }
  
    if (editingCaptionIndex !== null) {
      // Update caption at the editing index
      setThumbnailCaptions((prevCaptions) =>
        prevCaptions.map((caption, index) => index === editingCaptionIndex ? customCaptionInput : caption)
      );
      setEditingCaptionIndex(null);
    } else {
      // Add new caption
      setThumbnailCaptions((prevCaptions) => [...prevCaptions, customCaptionInput]);
    }
  
    // Reset the input state
    setIsAddingCustomCaption(false);
    setCustomCaptionInput('');
    setCaptionError('');
  };

  const characterIdToUrl = (characterId) => {
    return `https://tubethumbs-characters.s3.us-west-1.amazonaws.com/${characterId}.png`;
  };

  const handleSelectCharacter = async (url) => {
    const characterId = url.split('/').pop().replace('.png', '');
    // setSelectedCharacterId(characterId);
    dispatch(setSelectedCharacterId(characterId));
    try {
      // Make the API call to fetch character data
      const response = await axios.post('/api/get-selected-character', { characterId });
      const characterData = response.data;
      // Update the state with the new character's details
      setCharacterGender(characterData.characterGender.charAt(0).toUpperCase() + characterData.characterGender.slice(1) || '');
      setCharacterBodyType(characterData.characterBodyType.charAt(0).toUpperCase() + characterData.characterBodyType.slice(1) || '');
      setCharacterHairColor(characterData.characterHairColor);
      setCharacterHairLengthInInches(characterData.characterHairLengthInInches);
      setCharacterHairstyle(characterData.characterHairstyle);
      setCharacterEthnicity(characterData.characterEthnicity);
      setCharacterSkinTone(characterData.characterSkinTone);
      setCharacterFacialHairstyle(characterData.characterFacialHairstyle);
    } catch (error) {
      console.error('Error fetching character details:', error);
    }
  };

  const handleUploadAreaClick = (e) => {
    // console.log(fileInputRef.current)
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (event) => {
    // Access the file from the event
    const file = event.target.files[0]; 
  
    // Ensure there is a file selected
    if (file) {
      handleFileChange(file); // Pass the file to the handler function
    } else {
      console.log('No file selected');
    }
  };
  
  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };
  
  const handleFileChange = (file) => {
    const maxFileSize = 20 * 1024 * 1024; // 1MB in bytes
    const validImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'heic', 'heif', 'webp'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
  
    if (file && validImageExtensions.includes(fileExtension)) {
      if (file.size > maxFileSize) {
        alert("File is too large. Please select an image smaller than 20MB.");
        setImageFile(null);
        setImagePreview(null);
      } else {
        setImageFile(file);
        setImageError('');
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      alert("Please select a valid image file (JPEG, PNG, GIF, HEIC, or HEIF).");
      setImageFile(null);
      setImagePreview(null);
    }
  };

  const handleFileDragOver = (e) => {
    e.preventDefault();
  };

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxFileSize = 1000 * 1024; // 500KB
          const maxWidth = 1000; // Max width for resizing
          const maxHeight = 1000; // Max height for resizing
          let width = img.width;
          let height = img.height;
  
          // Scale down the image while maintaining the aspect ratio
          if (width > maxWidth || height > maxHeight) {
            if (width > height) {
              height = Math.floor((height * maxWidth) / width);
              width = maxWidth;
            } else {
              width = Math.floor((width * maxHeight) / height);
              height = maxHeight;
            }
          }
  
          // Resize and draw the image on the canvas
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
  
          // Compress the image (default quality: 70%)
          let base64Image = canvas.toDataURL('image/jpeg', 0.7);
          let blob = dataURItoBlob(base64Image);
  
          // Check the size and adjust compression if still too large
          if (blob.size > maxFileSize) {
            let newQuality = maxFileSize / blob.size;
            newQuality = Math.max(0, Math.min(1, newQuality)); // Ensure newQuality is between 0 and 1
            base64Image = canvas.toDataURL('image/jpeg', newQuality);
            blob = dataURItoBlob(base64Image);
          }
          resolve(blob); // Return the compressed image
        };
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleUploadCharacter = async (event) => {
    setIsUploadingCharacter(true)
    event.preventDefault();
    if (!imageFile) {
      console.log('no image')
      setImageError('Please upload a valid image file.');  // Set error message if no image is uploaded
      // return;
    } else {
      dispatch(setLoadingDelightType('character'));
      // setStep('0');
      try {
        const compressedImage = await compressImage(imageFile);
        const formData = new FormData();
        formData.append('image', compressedImage); 
        formData.append('userId', userId);
    
        let response = await axios.post('/api/create-tubethumbsy-character', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        let characterURL = characterIdToUrl(response.data);
        // dispatch(setSelectedCharacterId(response.data));
        setCharacterUrls((prevUrls) => [...prevUrls, characterURL]);
        // setStep('2a');
      } catch (error) {
        console.error('Error uploading character:', error);
      }
      setIsUploadAreaVisible(false)
      setIsUploadingCharacter(false)
    }
  };

  const handleBackOnly = () => {
    navigate('/thumbnails/create/'+selectedSessionId);
  }

  const handleSaveSettings = async () => {
    // console.log(selectedCharacterId)
    try {
      const response = await axios.post('/api/edit-tubethumbsy-session', {
        userId, 
        selectedSessionId,
        characterId: selectedCharacterId,
        thumbnailPremises,
        thumbnailCaptions,
        thumbnailImageStyle,
        characterFaceExpression,
        characterPose,
        characterBodyType,
        characterGender,
        characterHairColor,
        characterHairLengthInInches,
        characterHairstyle,
        characterFacialHairstyle,
        characterEthnicity,
        characterSkinTone,
        characterOutfit,
      });
      console.log(response.data)
      if (response.data === 'success') {
        dispatch(setTriggerGenerateMore(true))
        navigate('/thumbnails/create/'+selectedSessionId);
      }
      // dispatch(updateSession(response.data.session));
      // alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving settings:', error);
    }
  };

  const handleAddCharacter = () => {
    setIsUploadAreaVisible(true)
  } 

  return (
    <div className="universal-container">
      {isUploadingCharacter && (
        <div className="loading-overlay">
          <img src="../../logo-dancing-transparent.gif" alt="loading" style={{ width: '75px' }} />
        </div>
      )}
      <MainTopNav />
      <div className="universal-content-container thumbnails-settings-container">
        <div className="thumbnails-settings-wrapper">
          <h1>Imagery</h1>
          <div className="steps step2">
            <div style={{ height: '20px' }} />
            <h2>Visual Prompts</h2>
            <p>Note any physical descriptions of characters here will be overridden by the settings below</p>
            <div className="thumbnail-premises-container">
              {thumbnailPremises &&
                thumbnailPremises.map((premise, index) => (
                  <div key={index} className="thumbnail-premise">
                    {editingPremiseIndex === index ? (
                      <form onSubmit={handleCustomPremiseSubmit} className="thumbnail-premise">
                        <input
                          type="text"
                          value={customPremiseInput}
                          onChange={(e) => setCustomPremiseInput(e.target.value)}
                          autoFocus
                          placeholder="Edit your imagery idea"
                          className="thumbnail-premise-custom-input"
                        />
                        <div className="thumbnail-premise-custom-input-button-wrapper">
                          <button type="submit" className="button-submit">
                            <FontAwesomeIcon icon={faCircleRight} size="lg" className="thumbnail-premise-custom-input-go" />
                          </button>
                          <button
                            type="button"
                            className="button-cancel"
                            onClick={() => {
                              setEditingPremiseIndex(null);
                              setCustomPremiseInput('');
                           
                            }}
                            >
                              <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                            </button>
                          </div>
                        </form>
                      ) : (
                        <div
                          className="thumbnail-premise-item"
                          onClick={() => {
                            if (!isAddingCustomPremise) {
                              setEditingPremiseIndex(index);
                              setCustomPremiseInput(premise);
                            }
                          }}
                        >
                          {premise} <FontAwesomeIcon icon={faPenToSquare} size="sm" />
                        </div>
                      )}
                      <button onClick={() => handleRemovePremise(premise)}>
                        <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                      </button>
                    </div>
                  ))}
                {premiseError && <p className="thumbnail-create-modal-content-error-validation">{premiseError}</p>}
                {!isAddingCustomPremise && editingPremiseIndex === null && (
                  <div
                    className="thumbnail-premise add-premise"
                    onClick={() => {
                      if (thumbnailPremises.length < 5) {
                        setIsAddingCustomPremise(true);
                        setCustomPremiseInput(''); // Reset input for new premise
                        setPremiseError('');
                      } else {
                        setPremiseError('You can add up to 5 imagery ideas only.');
                      }
                    }}
                    style={{ width: '100%' }}
                  >
                    <FaPlusCircle size={12} />
                    <span style={{ marginLeft: '8px' }}>ADD MORE</span>
                  </div>
                )}
                {isAddingCustomPremise && (
                  <form onSubmit={handleCustomPremiseSubmit} className="thumbnail-premise">
                    <input
                      type="text"
                      value={customPremiseInput}
                      onChange={(e) => setCustomPremiseInput(e.target.value)}
                      autoFocus
                      placeholder="Enter your own imagery idea"
                      className="thumbnail-premise-custom-input"
                    />
                    <div className="thumbnail-premise-custom-input-button-wrapper">
                      <button type="submit" className="button-submit">
                        <FontAwesomeIcon icon={faCircleRight} size="lg" className="thumbnail-premise-custom-input-go" />
                      </button>
                      <button
                        type="button"
                        className="button-cancel"
                        onClick={() => {
                          setIsAddingCustomPremise(false);
                          setCustomPremiseInput('');
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <div style={{ height: '20px' }} />
             {/* <div>
              <h2>Visual Style</h2>
              <div className="thumbnails-characters-grid thumbnails-settings-grid">
                {imageStyles.map((style) => (
                  <div key={style.name}>
                    <div>
                      <div
                        className={`thumbnails-settings-poses-wrapper ${
                          thumbnailImageStyle === style.prompt ? 'character-selected' : ''
                        }`}
                        onClick={() => setThumbnailImageStyle(style.prompt)}
                      >
                        <img src={style.src} alt={style.name} className="thumbnails-settings-poses-wrapper-img" />
                      </div>
                      <p>{style.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
            {/* Character Selector */}
            <div style={{ height: '20px' }} />
            <div className="steps step1">
              <h1>Character Details</h1>
              <h2>Character Selection</h2>
              <div className="thumbnails-characters-grid">
                <div
                  key="add-character"
                  className="thumbnails-character-option"
                  onClick={() => handleAddCharacter()}
                >
                  <FaPlusCircle className="thumbnails-character-option-icon" />
                  <p>Add Character</p>
                </div>
                {characterUrls.map((url) => {
                  const characterId = url.split('/').pop().replace('.png', '');
                  return (
                    <div
                      key={url}
                      className={`thumbnails-character-image-wrapper ${
                        selectedCharacterId === characterId ? 'character-selected' : ''
                      }`}
                    >
                      <img
                        src={url}
                        alt="Character"
                        className="thumbnails-character-image"
                        onClick={() => handleSelectCharacter(url)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {isUploadAreaVisible && (
              <div>
                <div
                  className="upload-area"
                  onClick={handleUploadAreaClick}
                  onDrop={handleFileDrop}
                  onDragOver={handleFileDragOver}
                  style={{ cursor: 'pointer' }}
                >
                  {isMobile ? (
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      onChange={handleFileInputChange}
                      className="upload-area-input"
                    />
                  ) : (
                    <div>
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/*"
                        onChange={handleFileInputChange}
                        className="upload-area-input"
                        ref={fileInputRef}
                      />
                      Drag and drop your file here, or click to upload.
                    </div>
                  )}
                  {imageFile && (
                    <img src={imagePreview} alt="Preview" className="upload-preview-image" />
                  )}
                </div>
                <button className="thumbnail-settings-upload-button" onClick={handleUploadCharacter}>Upload</button>
              </div>
            )}
            {/* Character Settings */}
            <div style={{ height: '20px' }} />
            <div className={`character-settings ${(!selectedCharacterId || selectedCharacterId === 'none') ? 'character-settings-semi-transparent' : ''}`}>
              <h2>Sex</h2>
              <ToggleButtonGroup
                options={genderOptions}
                selectedOption={characterGender}
                setSelectedOption={setCharacterGender}
              />
              <div style={{ height: '20px' }} />
              <h2>Body Type</h2>
              <ToggleButtonGroup
                options={bodyTypeOptions}
                selectedOption={characterBodyType}
                setSelectedOption={setCharacterBodyType}
              />
              <div style={{ height: '20px' }} />
              <h2>Character Outfit</h2>
              <div className="thumbnail-settings-hair-row">
                <label>Outfit:</label>
                <input
                  type="text"
                  value={characterOutfit}
                  onChange={(e) => setCharacterOutfit(e.target.value)}
                  placeholder="Enter outfit description"
                />
              </div>
              <div style={{ height: '20px' }} />
              <h2>Hair</h2>
              <div className="thumbnail-settings-hair-row">
                <label>Hair Color:</label>
                <input
                  type="text"
                  value={characterHairColor}
                  onChange={(e) => setCharacterHairColor(e.target.value)}
                  placeholder="Enter hair color"
                />
              </div>
              <div className="thumbnail-settings-hair-row">
                <label>Hairstyle:</label>
                <input
                  type="text"
                  value={characterHairstyle}
                  onChange={(e) => setCharacterHairstyle(e.target.value)}
                  placeholder="Enter hairstyle"
                />
              </div>
              <div className="thumbnail-settings-hair-row">
                <label>Hair Length:</label>
                <div className="hair-length-slider-container">
                  <input
                    type="range"
                    min="0"
                    max="24"
                    step="1"
                    value={characterHairLengthInInches}
                    onChange={(e) => setCharacterHairLengthInInches(e.target.value)}
                    className="hair-length-slider"
                  />
                  <span className="hair-length-value">{characterHairLengthInInches} in</span>
                </div>
              </div>
              <div className="thumbnail-settings-hair-row">
                <label>Facial Hair:</label>
                <input
                  type="text"
                  value={characterFacialHairstyle}
                  onChange={(e) => setCharacterFacialHairstyle(e.target.value)}
                  placeholder="Enter facial hairstyle"
                />
              </div>
              <div style={{ height: '20px' }} />
              <h2>Face Expression</h2>
              <div className="thumbnails-characters-grid thumbnails-settings-grid">
                {faceExpressions.map((expression) => (
                  <div key={expression.name}>
                    <div>
                      <div
                        className={`thumbnails-character-image-wrapper ${
                          characterFaceExpression === expression.prompt ? 'character-selected' : ''
                        }`}
                        onClick={() => setCharacterFaceExpression(expression.prompt)}
                      >
                        <img src={expression.src} alt={expression.name} className="thumbnails-character-image" />
                      </div>
                      <p>{expression.name}</p>
                    </div>
                  </div>
                ))}
              </div>
              <h2>Pose</h2>
              <div className="thumbnails-characters-grid thumbnails-settings-grid">
                {poses.map((pose) => (
                  <div key={pose.name}>
                    <div>
                      <div
                        className={`thumbnails-settings-poses-wrapper ${
                          characterPose === pose.prompt ? 'character-selected' : ''
                        }`}
                        onClick={() => setCharacterPose(pose.prompt)}
                      >
                        <img src={pose.src} alt={pose.name} className="thumbnails-settings-poses-wrapper-img" />
                      </div>
                      <p>{pose.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
              <div style={{ height: '20px' }} />
            <div className="steps step2">
              <h1>Captions</h1>
              <div style={{ height: '20px' }} />
              <div className="thumbnail-premises-container">
                {thumbnailCaptions &&
                  thumbnailCaptions.map((caption, index) => (
                    <div key={index} className="thumbnail-premise">
                      {editingCaptionIndex === index ? (
                        <form onSubmit={handleCustomCaptionSubmit} className="thumbnail-premise">
                          <input
                            type="text"
                            value={customCaptionInput}
                            onChange={(e) => setCustomCaptionInput(e.target.value)}
                            autoFocus
                            placeholder="Edit your caption"
                            className="thumbnail-premise-custom-input"
                          />
                          <div className="thumbnail-premise-custom-input-button-wrapper">
                            <button type="submit" className="button-submit">
                              <FontAwesomeIcon icon={faCircleRight} size="lg" className="thumbnail-premise-custom-input-go" />
                            </button>
                            <button
                              type="button"
                              className="button-cancel"
                              onClick={() => {
                                setEditingCaptionIndex(null);
                                setCustomCaptionInput('');
                              }}
                            />
                          </div>
                        </form>
                      ) : (
                        <div
                          className="thumbnail-premise-item"
                          onClick={() => {
                            if (!isAddingCustomCaption) {
                              setEditingCaptionIndex(index);
                              setCustomCaptionInput(caption);
                            }
                          }}
                        >
                          {caption} <FontAwesomeIcon icon={faPenToSquare} size="sm" />
                        </div>
                      )}
                      <button onClick={() => handleRemoveCaption(caption)}>
                        <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                      </button>
                    </div>
                  ))}
                {captionError && <p className="thumbnail-create-modal-content-error-validation">{captionError}</p>}
                {!isAddingCustomCaption && editingCaptionIndex === null && (
                  <div
                    className="thumbnail-premise add-premise"
                    onClick={() => {
                      if (thumbnailCaptions.length < 5) {
                        setIsAddingCustomCaption(true);
                        setCustomCaptionInput(''); // Reset input for new caption
                        setCaptionError('');
                      } else {
                        setCaptionError('You can add up to 5 captions only.');
                      }
                    }}
                    style={{ width: '100%' }}
                  >
                    <FaPlusCircle size={12} />
                    <span style={{ marginLeft: '8px' }}>ADD MORE</span>
                  </div>
                )}
                {isAddingCustomCaption && (
                  <form onSubmit={handleCustomCaptionSubmit} className="thumbnail-premise">
                    <input
                      type="text"
                      value={customCaptionInput}
                      onChange={(e) => setCustomCaptionInput(e.target.value)}
                      autoFocus
                      placeholder="Enter your own caption"
                      className="thumbnail-premise-custom-input"
                    />
                    <div className="thumbnail-premise-custom-input-button-wrapper">
                      <button type="submit" className="button-submit">
                        <FontAwesomeIcon icon={faCircleRight} size="lg" className="thumbnail-premise-custom-input-go" />
                      </button>
                      <button
                        type="button"
                        className="button-cancel"
                        onClick={() => {
                          setIsAddingCustomCaption(false);
                          setCustomCaptionInput('');
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="thumbnail-settings-save">
          <div className='thumbnail-settings-save-wrapper'>
            <button onClick={handleBackOnly} className="thumbnail-settings-back-button">BACK</button>
            <button onClick={() => handleSaveSettings()} className="create-thumbnails-btn">
              <div className="button-content">
                <span className="main-text">SAVE AND GENERATE MORE</span>
                <span className="credits-text">(40 Credits)</span>
                <img src="../../sparkles.gif" alt="sparkles" className="wand-button" />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
      
};

export default ThumbnailSettings;
