import React from 'react';
import './About.css'; // Ensure this file imports the relevant styles
import MainTopNav from './MainTopNav';

const Help = () => {
  return (
    <div className="universal-container">
      <div className="universal-content-container">
        <div className="splash-page-container">
          <div className="splash-page">
          <MainTopNav />
          <div className="animation-wrapper">
              <div className="data-text">
              <h2>Need Assistance? We Can Help!</h2>
                <p>Got a question or need support? Drop us an email at <a href="mailto:hello@giggy.ai">hello@giggy.ai</a>, and our team will get back to you as soon as possible.</p>
                <p>Your success is important to us. Let's chat!</p>
              </div>
              <div className="data-illustration">
                <img src="payment-success.png" alt="Hero Illustration" />
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
