import { createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';

const usersSlice = createSlice({
  name: 'user',
  initialState: {
    userId: null,
    userDisplayName: null,
    userEmail: null,
    userImage: null,
    isLoggedIn: false,
    userCreditsBalance: 0,
    loadingDelightType: 'default',
    stripeMode: 'prod',
    userSubscription: null
  },
  reducers: {
    setLogin: (state, action) => {
      // console.log('setLogin');
      // console.log(action.payload);
      state.userId = action.payload.userId;
      state.userDisplayName = action.payload.userDisplayName;
      state.userEmail = action.payload.userEmail;
      state.userImage = `https://tubethumbs-users.s3.us-west-1.amazonaws.com/${action.payload.userId}.jpg`;
      state.isLoggedIn = true;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    setLogout: (state) => {
      // console.log('logout')
      state.userId = null;
      state.userDisplayName = null;
      state.userEmail = null;
      state.userImage = null;
      state.userCreditsBalance = 0;
      state.isLoggedIn = false;
      localStorage.removeItem('user'); // Correctly clear the user data from localStorage
    },
    setUserSubscription: (state, action) => {
      // console.log(action.payload)
      state.userSubscription = action.payload;
    },
    setUserCreditsBalance: (state, action) => {
      // console.log(action.payload)
      state.userCreditsBalance = action.payload;
    },
    setLoadingDelightType: (state, action) => {
      // console.log(action.payload)
      state.loadingDelightType = action.payload;
    }
  },
});

export const { 
  setLogin, 
  setLogout, 
  setUserCreditsBalance,
  setLoadingDelightType,
  setUserSubscription
 } = usersSlice.actions;

export default usersSlice.reducer;
