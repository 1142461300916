import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thumbnailCanvasReducer from './ThumbnailCanvasSlice';
import usersReducer from './UsersSlice';
import thumbnailSessionsReducer from './ThumbnailSessionsSlice';

// Combine all your reducers
const appReducer = combineReducers({
  thumbnailCanvas: thumbnailCanvasReducer,
  user: usersReducer,
  thumbnailSessions: thumbnailSessionsReducer,
});

// Root reducer that resets the state when `LOGOUT` is dispatched
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined; // Reset the state
  }
  return appReducer(state, action);
};

// Create the Redux store with the root reducer
const store = configureStore({
  reducer: rootReducer, // Use rootReducer here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable the serializable check middleware
    }),
});

export default store;
