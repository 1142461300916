import { createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
import { isEqual } from 'lodash';

const thumbnailSessionsSlice = createSlice({
  name: 'thumbnailSessions',
  initialState: {
    selectedCharacterId: null,
    triggerCreateSessionFromEditor: false,
    isCreatingSession: false,
    isCompositingThumbnail: false,
    checkHasSessions: null,
    lastSelectedSession: 'none',
    sessionThumbnails: [],
    sessions: [],
    compositingQueue: [],
    generatingQueue: [],
    triggerGenerateMore: false,
    // isCreatingNewSession: false,
  },
  reducers: {
    setTriggerGenerateMore(state, action) {
      state.triggerGenerateMore = action.payload;
    },
    setLastSelectedSession(state, action) {
      state.lastSelectedSession = action.payload;
    },
    setTriggerCreateSessionFromEditor(state, action) {
      state.triggerCreateSessionFromEditor = action.payload;
    },
    setSessionThumbnails(state, action) {
      let sessionThumbnails = action.payload;
      let reorderedThumbnails;
      if (sessionThumbnails.length <= 4) {
        // Sort "composited" thumbnails by creationTime first
        const compositedThumbnails = sessionThumbnails
          .filter(thumbnail => thumbnail.thumbnailStatus === 'composited')
          .sort((a, b) => b.thumbnailCreationTime - a.thumbnailCreationTime); // Newest first
        // Append non-composited thumbnails as they are, without sorting
        const nonCompositedThumbnails = sessionThumbnails
          .filter(thumbnail => thumbnail.thumbnailStatus !== 'composited');
        // Combine composited on top and non-composited thumbnails below
        reorderedThumbnails = [...compositedThumbnails, ...nonCompositedThumbnails];
      } else {
        // Sort all thumbnails by creationTime, with the newest at the top
        reorderedThumbnails = sessionThumbnails.sort((a, b) => b.thumbnailCreationTime - a.thumbnailCreationTime);
      }
      // console.log(reorderedThumbnails)
      state.sessionThumbnails = reorderedThumbnails;
    },
    
    addCompositingQueue(state, action) {
      // console.log('addCompositingQueue')
      // console.log(action.payload)
      const exists = state.compositingQueue.some(item => 
        item.thumbnailId === action.payload.thumbnailId
      );
      if (!exists) {
        state.compositingQueue = [
          ...state.compositingQueue,
          action.payload
        ];
      }
      // console.log(state.compositingQueue);
    },
    removeFromCompositingQueue(state, action) {
      const thumbnailId = action.payload;
      // Remove the object with the given thumbnailId
      state.compositingQueue = state.compositingQueue.filter(
        item => item.thumbnailId !== thumbnailId
      );
      // Optionally update other state variables here, if needed
    },
    addGeneratingQueue(state, action) {
      // const exists = state.generatingQueue.some(item =>
      //   isEqual(item, action.payload)
      // );
      const exists = state.generatingQueue.some(item => item.thumbnailId === action.payload.thumbnailId);
      if (!exists) {
        state.generatingQueue = [
          ...state.generatingQueue,
          action.payload
        ];
      }
    },
    updateGeneratingQueue(state, action) {
      const newThumbnail = action.payload;
      const index = state.generatingQueue.findIndex(item => item.thumbnailId === newThumbnail.thumbnailId);

      if (index !== -1) {
        // Replace the existing thumbnail with the new one
        state.generatingQueue[index] = newThumbnail;
      } else {
        // Optionally, if no matching thumbnail is found, add it to the queue
        state.generatingQueue.push(newThumbnail);
      }
    },
    removeFromGeneratingQueue(state, action) {
      const thumbnailId = action.payload;
      // Remove the object with the given thumbnailId
      state.generatingQueue = state.generatingQueue.filter(
        item => item.thumbnailId !== thumbnailId
      );
      // Optionally update other state variables here, if needed
    },
    setSessions: (state, action) => {
      // First, extract the sessions from the payload
      const sessions = action.payload;
    
      // Sort the sessions by the most recent thumbnailCreationTime within each session
      const sortedSessions = sessions.sort((a, b) => {
        // For each session, find the thumbnail with the most recent creation time
        const maxTimeA = Math.max(...(a.thumbnails || []).map(t => t.thumbnailCreationTime));
        const maxTimeB = Math.max(...(b.thumbnails || []).map(t => t.thumbnailCreationTime));
    
        // Compare the most recent times
        return maxTimeB - maxTimeA;
      });
    
      // Update the state with the sorted sessions
      state.sessions = sortedSessions;
    },
    
    updateSession: (state, action) => {
      const updatedSession = action.payload;
      const index = state.sessions.findIndex(session => session.sessionId === updatedSession.sessionId);
      if (index !== -1) {
        state.sessions[index] = updatedSession;
      }
    },
    addSession: (state, action) => {
      const existingSession = state.sessions.find(session => session.sessionId === action.payload.sessionId);
      if (!existingSession) {
        state.sessions.unshift(action.payload); // Only add if it doesn't already exist
      }
    },
    setSelectedCharacterId: (state, action) => {
      // console.log('setSelectedCharacterId')
      // console.log(action.payload)
      state.selectedCharacterId = action.payload;
    },
    setIsCompositingThumbnail: (state, action) => {
      state.isCompositingThumbnail = action.payload;
    },
    setIsCreatingSession: (state, action) => {
      state.isCreatingSession = action.payload;
    },
    // setIsCreatingNewSession: (state, action) => {
    //   state.isCreatingNewSession = action.payload;
    // },
    setCheckHasSessions: (state, action) => {
      state.checkHasSessions = action.payload;
    },
    resetSessions: (state, action) => {
      // For use at logout
      state.selectedCharacterId = null;
      state.triggerCreateSessionFromEditor = false;
      state.isCreatingSession = false;
      state.isCompositingThumbnail = false;
      state.checkHasSessions = true;
      state.sessionThumbnails = [];
      state.sessions = [];
      state.compositingQueue = [];
      state.generatingQueue = [];
    },
    
    // setIsSaving: (state, action) => {
    //   state.isSaving = action.payload;
    // },
  },
});

export const { 
  setLastSelectedSession,
  setSessionThumbnails,
  addCompositingQueue,
  removeFromCompositingQueue,
  addGeneratingQueue,
  removeFromGeneratingQueue,
  updateGeneratingQueue,
  setSessions, 
  updateSession,
  addSession, 
  setIsCompositingThumbnail, 
  setSelectedCharacterId,
  setTriggerCreateSessionFromEditor,
  setIsCreatingSession,
  setTriggerGenerateMore,
  // setIsCreatingNewSession,
  setCheckHasSessions,
  resetSessions,
  // setIsSaving,
} = thumbnailSessionsSlice.actions;

// export const fetchSessions = (userId) => async (dispatch) => {
  
// };

// export const createSession = (
//   userId, 
//   thumbnailSessionPrompt, 
//   thumbnailSeedURL, 
//   thumbnailVideoDescription, 
//   thumbnailPreviewURL, 
//   thumbnailText,
//   selectedCharacterId
// ) => async (dispatch) => {
//   try {
    
//     return response.data.sessionId;
//   } catch (error) {
//     console.error('Error creating session:', error);
//   }
// };

export default thumbnailSessionsSlice.reducer;
