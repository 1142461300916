import Register from './Register';
import React, { createContext, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Modal from 'react-modal';
import { io } from 'socket.io-client';
import ThumbnailHome from './ThumbnailHome';
import ThumbnailCreate from './ThumbnailCreate';
import ThumbnailPublish from './ThumbnailPublish';
import MilaCreative from './MilaCreative';
import About from './About';
import Help from './Help';
import PaymentSuccess from './PaymentSuccess';
import Pricing from './Pricing';
import Terms from './Terms';
import Privacy from './Privacy';
import Account from './Account';
import { setLogout, setLogin, setUserCreditsBalance, setUserSubscription } from './redux/UsersSlice';
import FontFaceObserver from 'fontfaceobserver';
import axios from 'axios';
import ErrorBoundary from './ErrorBoundary';

// Define the SocketContext
const SocketContext = createContext(null);

const fonts = [
  'Open Sans', 'Raleway', 'Lato', 'Montserrat', 'Roboto', 'Poppins', 'Oswald',
  'Inter', 'Bebas Neue', 'Anton', 'League Spartan', 'Playfair Display',
  'Archivo Black', 'Squada One', 'Lilita One', 'Bangers', 'Satisfy',
  'Indie Flower', 'Handjet', 'Noto Sans Display', 'Fira Sans',
];

const App = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId); // Get userId from Redux
  const stripeMode = useSelector((state) => state.user.stripeMode);
  const [socket, setSocket] = useState(null); // Store the socket instance in state

  // Ensure modal is set after component mounts
  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  // Fetch user data from localStorage and update Redux store
  useEffect(() => {
    let userData = localStorage.getItem('user');
    if (userData) {
      try {
        userData = JSON.parse(userData);
        dispatch(setLogin(userData));
      } catch (e) {
        console.error('Error parsing userData from localStorage', e);
      }
    } else {
      dispatch(setLogout());
    }
  }, [dispatch]);

  // Initialize socket and handle cleanup
  const initializeSocket = useCallback(async (environment, stripeMode) => {
    let socketAddress = '';
    try {
      await axios.post('/api/initialize-connection', {
        environment: environment,
        stripeMode: stripeMode,
      });

      if (environment === 'dev') {
        socketAddress = 'http://localhost:5000';
      } else {
        socketAddress = 'wss://www.giggy.ai:6969';
      }

      const newSocket = io(socketAddress, {
        transports: ['websocket'],
      });
      setSocket(newSocket); // Store the socket instance in state

      newSocket.on('connect', () => {
        console.log('Successfully connected to the WebSocket server!');
        if (userId) {
          newSocket.emit('register', { userId });
        }
      });

      newSocket.on('disconnect', (reason) => {
        console.log(`Socket disconnected due to ${reason}. Attempting to reconnect...`);
      });

      newSocket.on('reconnect_attempt', (attemptNumber) => {
        console.log(`Reconnection attempt #${attemptNumber}`);
      });

      newSocket.on('reconnect_failed', () => {
        console.log('Reconnection failed. Please check your connection.');
      });

      newSocket.on('reconnect', () => {
        console.log('Successfully reconnected to the server!');
        if (userId) {
          newSocket.emit('register', { userId });
        }
      });

    } catch (error) {
      console.error('Error during socket initialization:', error);
      setTimeout(() => initializeSocket(environment, stripeMode), 5000); // Retry after 5 seconds
    }
  }, [userId]); // Only depend on `userId`

  // Wait for both userId and stripeMode to initialize the socket
  useEffect(() => {
    let environment = '';
    const currentURL = window.location.href;
    if (currentURL.includes('giggy.ai')) {
      environment = 'prod';
    } else {
      environment = 'dev';
    }

    if (userId && stripeMode) {  // Only initialize socket after userId is available
      initializeSocket(environment, stripeMode);
    }
  }, [userId, stripeMode, initializeSocket]);

  // Cleanup socket connection when component unmounts
  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    };
  }, [socket]);

  useEffect(() => {
    const getCredits = async (userId) => {
      try {
        const response = await axios.post('/api/get-credits-balance', { userId });
        dispatch(setUserCreditsBalance(response.data.userCreditsBalance));
        dispatch(setUserSubscription(response.data.userSubscription));
      } catch (error) {
        console.error('Failed to fetch user credits:', error);
      }
    };

    if (userId !== null && socket !== null) {
      getCredits(userId); // Only fetching credits
    }
  }, [userId, socket, dispatch]);

  // Load fonts and provide feedback if they fail to load
  useEffect(() => {
    const fontPromises = fonts.map((font) => {
      const fontObserver = new FontFaceObserver(font);
      return fontObserver.load();
    });

    Promise.all(fontPromises)
      .then(() => {
        // Fonts loaded successfully
      })
      .catch((err) => {
        console.error('One or more fonts failed to load', err);
      });
  }, []);

  return (
    <ErrorBoundary>
      <SocketContext.Provider value={socket}>
        <Router>
          <Routes>
            <Route path="/" element={<ThumbnailHome />} />
            <Route path="/register" element={<Register />} />
            <Route path="/account" element={<Account />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<About />} />
            <Route path="/help" element={<Help />} />
            <Route path="/paymentsuccess" element={<PaymentSuccess />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/milacreative" element={<MilaCreative />} />
            <Route path="/thumbnails/create" element={<ThumbnailCreate />} />
            <Route path="/thumbnails/create/:selectedSessionId?" element={<ThumbnailCreate key={window.location.pathname} />} />
            <Route path="/thumbnails/settings/:selectedSessionId?" element={<ThumbnailCreate key={window.location.pathname} />} />
            <Route path="/thumbnails/editor/:selectedThumbnailId" element={<ThumbnailCreate key={window.location.pathname} />} />
            <Route path="/thumbnails/publish/:thumbnailId" element={<ThumbnailPublish />} />
          </Routes>
        </Router>
      </SocketContext.Provider>
    </ErrorBoundary>
  );
};

export { SocketContext };
export default App;
