import React from 'react';
import './About.css'; // Ensure this file imports the relevant styles
import MainTopNav from './MainTopNav';

const About = () => {
  return (
    <div className="universal-container">
      <div className="universal-content-container">
        <div className="splash-page-container">
          <div className="splash-page">
            <MainTopNav />
            <div className="animation-wrapper">
              <div className="data-text">
                <h2>About Us</h2>
                <p>At Giggy, our mission is simple: empower YouTube creators to make content as easily as they consume it. Based in San Francisco, CA, our team is a passionate mix of creators and artificial intelligence innovators, working together to bring you the tools that make creating, editing, and optimizing content easier than ever. 
                  <br/>
                  <br/>
                  We’re driven by innovation—and yes, we're also dog lovers. Whether it’s through advanced insights or cutting-edge automation, we’re here to help you level up your content game.</p>
                  <br/>
                  <br/>
                  Woof!
              </div>
              <div className="data-illustration">
                <img src="payment-success.png" alt="Hero Illustration" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
