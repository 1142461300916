import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// import { setLogout } from './redux/UsersSlice';
const MainTopNav = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const location = useLocation();
  const userCreditsBalance = useSelector((state) => state.user.userCreditsBalance);
  const userImage = useSelector((state) => state.user.userImage);
  const userId = useSelector((state) => state.user.userId);
  const [logoUrl, setLogoUrl] = useState('');
  const [navClass, setNavClass] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [desktopMenuClass, setDesktopMenuClass] = useState('');
  const [mobileMenuClass, setMobileMenuClass] = useState('');
  const [hamburgerClass, setHamburgerClass] = useState('');

  useEffect(() => {
    if (location.pathname.startsWith('/thumbnails') || 
    location.pathname.startsWith('/account')) {
      setLogoUrl('../../logo-dark.png');
      setNavClass('main-top-nav-container main-top-nav-dark');
      setDesktopMenuClass('main-top-nav-menu-desktop main-top-nav-menu-desktop-dark');
      setHamburgerClass('main-top-nav-hamburger main-top-nav-hamburger-dark');
      setMobileMenuClass('main-top-nav-menu main-top-nav-menu-dark');
    } else {
      setLogoUrl('logo-light.png');
      setNavClass('main-top-nav-container main-top-nav-light');
      setDesktopMenuClass('main-top-nav-menu-desktop main-top-nav-menu-desktop-light');
      setHamburgerClass('main-top-nav-hamburger main-top-nav-hamburger-light');
      setMobileMenuClass('main-top-nav-menu main-top-nav-menu-light');
    }
  }, [location.pathname]);


  const handleAccountClick = () => {
    navigate('/account');
  };

  const handleCreditsClick = () => {
    navigate('/pricing');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={navClass}>
      <div className="main-top-nav-left">
        <a href="https://www.giggy.ai">
          <img src={logoUrl} alt="Logo" />
        </a>
      </div>
      <div className="main-top-nav-right">
      <nav className={`${mobileMenuClass} ${menuOpen ? 'open' : ''}`}>
        {/* <nav className={`main-top-nav-menu ${menuOpen ? 'open' : ''}`}> */}
          <ul>
            {userId && (
            <li><a href="/thumbnails/create">Create</a></li>
            )}
            <li><a href="/pricing">Pricing</a></li>
            <li><a href="/help">Help</a></li>
            <li><a href="/about">About</a></li>
          </ul>
        </nav>
        <div className={desktopMenuClass}>
          {userId && (
            <a href="/thumbnails/create">Create</a>
          )}
          <a href="/pricing">Pricing</a>
          <a href="/help">Help</a>
          <a href="/about">About</a>
        </div>
        {userImage && (
          <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <span onClick={handleCreditsClick} className='main-top-nav-credits-balance'>
              <strong>Credits:</strong> {userCreditsBalance}
            </span>
            {userImage && (
              <img
                src={userImage}
                alt="User"
                onClick={handleAccountClick}
                className="main-top-nav-user-image"
                style={{ marginLeft: '10px' }}
              />
            )}
          </div>
        )}
       <div className={hamburgerClass} onClick={toggleMenu}>
        ☰
      </div>
      </div>
    </div>
  );
};

export default MainTopNav;
