import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingDelightType } from './redux/UsersSlice';
import './MilaCreative.css';

const MilaCreative = () => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null); // Video preview URL
  const [fileError, setFileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [frames, setFrames] = useState([]);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    handleFileChange(selectedFile);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    handleFileChange(selectedFile);
  };

  const handleFileChange = (selectedFile) => {
    const maxFileSize = 500 * 1024 * 1024; // 500MB for larger video files

    if (selectedFile) {
      if (selectedFile.size > maxFileSize) {
        alert("File is too large. Please select a file smaller than 500MB.");
        setFile(null);
        setFilePreview(null);
      } else if (selectedFile.type.startsWith('video/')) {
        setFile(selectedFile);
        setFileError('');
        const videoURL = URL.createObjectURL(selectedFile); // Create a URL for video file preview
        setFilePreview(videoURL);
      } else {
        setFileError('Please select a valid video file.');
      }
    }
  };

  const handleFileDragOver = (e) => {
    e.preventDefault();
  };

  const handleUploadFile = async () => {
    setIsLoading(true);
    if (!file) {
      setFileError('Please upload a video file.');
      return;
    } else {
      dispatch(setLoadingDelightType('file'));
      try {
        const formData = new FormData();
        formData.append('file', file);
        console.log(file);
        let response = await axios.post('/api/upload-test', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log(response.data);
        setFrames(response.data.descriptions);
        setIsLoading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
        setIsLoading(false);
        alert('Failed to upload file.');
      }
    }
  };

  // Function to format the description into JSX
  const formatDescriptionToJSX = (description) => {
    try {
      const parsedDescription = JSON.parse(description);

      return (
        <ul className="formatted-description">
          {Object.entries(parsedDescription).map(([key, value], index) => (
            <li key={index}>
              <strong>{key.replace(/_/g, ' ')}:</strong> {Array.isArray(value) ? value.join(', ') : value}
            </li>
          ))}
        </ul>
      );
    } catch (error) {
      console.error('Invalid JSON format:', error);
      return 'Invalid description format';
    }
  };

  return (
    <div>
      {isLoading && (
        <>
          <div className="thumbnail-create-loading">
            This will take about a minute...
            <br />
            <div>
              <img src="../../logo-dancing-gray.gif" alt="loading" />
            </div>
          </div>
        </>
      )}
      {!isLoading && frames.length === 0 && (
        <div className="upload-container">
          <div
            className="upload-area"
            onClick={() => fileInputRef.current.click()}
            onDrop={handleFileDrop}
            onDragOver={handleFileDragOver}
          >
            <input
              type="file"
              accept="video/*" // Accept only video files
              onChange={handleFileInputChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            {filePreview ? (
              <p>File selected, hit the "upload video" button below...</p>
            ) : (
              <p>Drag and drop or click to upload a video</p>
            )}
          </div>
          {fileError && <p className="error-text">{fileError}</p>}
          <button onClick={handleUploadFile} disabled={isLoading}>
            {isLoading ? 'Uploading...' : 'Upload Video'}
          </button>
        </div>
      )}
      {frames.length > 0 && (
        <div className="frames-grid">
          {frames.map((frame, index) => (
            <div className="frame-card" key={index}>
              <img src={frame.frameUrl} alt="" className="frame-image" />
              <div className="frame-description">{formatDescriptionToJSX(frame.description)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MilaCreative;
