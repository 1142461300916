import React, { useEffect, useState, useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import "./Pricing.css";
import MainTopNav from './MainTopNav';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

const Pricing = () => {
  const navigate = useNavigate();
  const [annualBilling, setAnnualBilling] = useState(false);
  const [headerText, setHeaderText] = useState('Find the Perfect Plan for You');
  const [productPricing, setProductPricing] = useState([]);
  const [headerP, setHeaderP] = useState("Whether you're just starting out or scaling your content creation, we’ve got you covered.");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlVariable = searchParams.get('q');
  const stripeMode = useSelector((state) => state.user.stripeMode);
  const userId = useSelector((state) => state.user.userId);
  const [stripePromise, setStripePromise] = useState(null);
  const stripeProductsDev = useMemo(() => [
    {
      priceId: 'price_1Pxu4LC5JLz73ToLzL8sopEX',
      price: 500,
      monthlyCreditTopUp: 500
    },
    {
      priceId: 'price_1Pxx4IC5JLz73ToL5JAnEUad',
      price: 4800,
      monthlyCreditTopUp: 500
    },
    {
      priceId: 'price_1Pxx5xC5JLz73ToLL6ThfrIY',
      price: 1500,
      monthlyCreditTopUp: 1600
    },
    {
      priceId: 'price_1Pxx6IC5JLz73ToL0xjvahZn',
      price: 14400,
      monthlyCreditTopUp: 1600
    },
  ], []);
  
  const stripeProductsProd = useMemo(() => [
    {
      priceId: 'price_1PxxcfC5JLz73ToLVmyVKkAu',
      price: 500,
      monthlyCreditTopUp: 500
    },
    {
      priceId: 'price_1PxxcfC5JLz73ToLKNnj8qUZ',
      price: 4800,
      monthlyCreditTopUp: 500
    },
    {
      priceId: 'price_1PxxcXC5JLz73ToL8nvmhNru',
      price: 1500,
      monthlyCreditTopUp: 1600
    },
    {
      priceId: 'price_1PxxcXC5JLz73ToL91nxw8Ej',
      price: 14400,
      monthlyCreditTopUp: 1600
    },
  ], []);
  
  useEffect(() => {
    if (stripeMode === 'dev') {
      setProductPricing(stripeProductsDev)
      setStripePromise(loadStripe('pk_test_mTn4Nk97xOLrpnnYE3etd3Nx'));
    } else {
      setProductPricing(stripeProductsProd)
      setStripePromise(loadStripe('pk_live_VDmw7SHpkmgU9f3du8vDHudT'));
    }
  }, [stripeMode, stripeProductsDev, stripeProductsProd]);
  useEffect(() => {
    if (urlVariable === 'nocredits') {
      setHeaderText("It's Time To Join Our Community");
      setHeaderP('Please upgrade your subscription to continue using Giggy.');
    }
  }, [urlVariable]);
  
  const toggleBilling = () => {
    setAnnualBilling(!annualBilling);
  };

  const calculatePrice = (monthlyPrice) => {
    if (annualBilling) {
      const yearlyPrice = monthlyPrice * 12 * 0.8; // 20% discount for annual billing
      const savings = monthlyPrice * 12 - yearlyPrice;
      return { yearlyPrice: yearlyPrice, savings: savings };
    }
    return { monthlyPrice };
  };

  const handleSubscribe = async (plan) => {
    const stripe = await stripePromise;

    // Call the backend to create a Stripe Checkout session
    if (userId === null) {
      navigate('/register');
    } else {
      let priceId
      if (!annualBilling && plan === 'creator') {
        priceId = productPricing[0].priceId
      } else if (annualBilling && plan === 'creator') {
        priceId = productPricing[1].priceId
      } else if (!annualBilling && plan === 'pro') {
        priceId = productPricing[2].priceId
      } else if (annualBilling && plan === 'pro') {
        priceId = productPricing[3].priceId
      } 
      // console.log(priceId)
      const response = await axios.post('/api/create-checkout-session', {
        priceId: priceId, // Correct the data structure
        userId: userId
      });
      if (response.data === 'Already subscribed') {
        console.log(response.data)
        alert("You already have a subscription")
      } else {
        // console.log(response.data)
        // Redirect the user to Stripe Checkout
        const result = await stripe.redirectToCheckout({
          sessionId: response.data.id,
        });
        if (result.error) {
          console.error(result.error.message);
        }
      }
    }
  };

  return (
    <div className="universal-container">
      <MainTopNav />
      <div className="universal-content-container pricing-splash-page">
        <h1>{headerText}</h1>
        <p>{headerP}</p>
        
        {/* Toggle for Annual Billing */}
        <div className="pricing-billing-toggle">
          <span className={annualBilling ? "" : "pricing-active-label"}>MONTHLY</span>
          <label className="pricing-switch">
            <input type="checkbox" checked={annualBilling} onChange={toggleBilling} />
            <span className="pricing-slider pricing-round"></span>
          </label>
          <span className={annualBilling ? "pricing-active-label" : ""}>YEARLY</span>
          <span className="pricing-free-pill">2 MONTHS FREE</span>
        </div>
        
        <div className="pricing-section">
          {/* Free Plan */}
          <div className="pricing-text">
            <h3>Free</h3>
            <p>Ideal for individuals exploring our YouTube thumbnail generator with no commitment.</p>
            <button className="pricing-cta-button pricing-cta-button-already-active">
              Active
            </button>
            <ul>
              <li><i className="fas fa-times pricing-red-x"></i>No commercial use rights</li>
              <li><i className="fas fa-check pricing-green-check"></i>One-time trial 160 credits </li>
              <li><i className="fas fa-check pricing-green-check"></i>Generate 16 thumbnails </li>
            </ul>
          </div>

          {/* Creator Plan */}
          <div className="pricing-text">
            <h3>Creator</h3>
            <p>Perfect for hobbyists and small creators looking to grow their YouTube presence.</p>
            <div className="pricing-info">
              {annualBilling ? (
                <div>
                  <span>${calculatePrice(5).yearlyPrice}/year</span>
                  <p>2 Months Free. Save ${calculatePrice(5).savings} annually</p>
                </div>
              ) : (
                <span>$5/month</span>
              )}
            </div>
            <div className="pricing-button">
              <button 
                className="pricing-cta-button"
                onClick={() => handleSubscribe('creator')}
              >
                Subscribe
              </button>
            </div>
            <ul>
              <li><i className="fas fa-check pricing-green-check"></i>Commercial use license</li>
              <li><i className="fas fa-check pricing-green-check"></i>500 credits per month</li>
              <li><i className="fas fa-check pricing-green-check"></i>Generate 50 thumbnails per month</li>
              <li><i className="fas fa-check pricing-green-check"></i>Priority Generation Queue</li>
            </ul>
          </div>

          {/* Pro Plan */}
          <div className="pricing-text">
            <h3>Pro</h3>
            <p>Best for content creators producing high-quality videos for a global audience.</p>
            <div className="pricing-info">
              {annualBilling ? (
                <div>
                  <span>${calculatePrice(15).yearlyPrice}/year</span>
                  <p>2 Months Free. Save ${calculatePrice(15).savings} annually</p>
                </div>
              ) : (
                <span>$15/month</span>
              )}
            </div>
            <div className="pricing-button">
              <button 
                className="pricing-cta-button"
                onClick={() => handleSubscribe('pro')}
              >
                Subscribe
              </button>
            </div>
            <ul>
              <li><i className="fas fa-check pricing-green-check"></i>Commercial use license</li>
              <li><i className="fas fa-check pricing-green-check"></i>1600 credits per month</li>
              <li><i className="fas fa-check pricing-green-check"></i>Create 160 thumbnails per month</li>
              <li><i className="fas fa-check pricing-green-check"></i>Priority Generation Queue</li>
              <li><i className="fas fa-check pricing-green-check"></i>Priority Email Support</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
