import React, { useContext, useRef, useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import store from './redux/store';
import axios from 'axios';
import ThumbnailEditor from './ThumbnailEditor';
import ThumbnailSettings from './ThumbnailSettings';
import ThumbnailCreateUI from './ThumbnailCreateUI'; 
import { SocketContext } from './App'; // Import the context
import FontFaceObserver from 'fontfaceobserver';
import { debounce } from 'lodash';
import './ThumbnailTools.css';
import {
  setNewCanvas,
  setSelectedObject,
  setsSelectedObjectType,
  setLeftNavStrokeColor,
  setLeftNavStrokeWidth,
  setLeftNavShadowColor,
  setLeftNavShadowBlur,
  setLeftNavFillColor,
  setLeftNavStrokeOpacity,
  setLeftNavFillOpacity,
  setLeftNavFontFamily,
  setIsTextInputModalOpen,
  setCurrentTextGroup,
  setNewText,
  setIsMouseDown,
  incrementObjectCount,
  addLayer,
  // setLayers,
  setUpdateLayers,
  setLeftNavTextBackgroundColor,
  setLeftNavTextBackgroundOpacity,
} from './redux/ThumbnailCanvasSlice';
import { 
  setSessionThumbnails,
  removeFromCompositingQueue,
  addCompositingQueue,
  removeFromGeneratingQueue,
  setSessions,
  setCheckHasSessions,
  // addGeneratingQueue,
  updateGeneratingQueue
 } from './redux/ThumbnailSessionsSlice';

const ThumbnailCreate = () => {
  const isManual = false
  const { selectedSessionId } = useParams();
  // const navigate = useNavigate();
  const location = useLocation(); // useLocation hook to get the current location
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef(null);
  const fabricObjectsRef = useRef(new Map());
  const selectedObject = useSelector((state) => state.thumbnailCanvas.selectedObject);
  const imageCount = useSelector((state) => state.thumbnailCanvas.imageCount);
  const rectangleCount = useSelector((state) => state.thumbnailCanvas.rectangleCount);
  const circleCount = useSelector((state) => state.thumbnailCanvas.circleCount);
  const triangleCount = useSelector((state) => state.thumbnailCanvas.triangleCount);
  const originalLayerIndex = useSelector((state) => state.thumbnailCanvas.originalLayerIndex);
  const compositingQueue = useSelector((state) => state.thumbnailSessions.compositingQueue);
  const generatingQueue = useSelector((state) => state.thumbnailSessions.generatingQueue);
  const [generationIntervalChecker, setGenerationIntervalChecker] = useState(null);
  // const [savingQueue, setSavingQueue] = useState([]);
  const savingQueueRef = useRef([]);

  const socket = useContext(SocketContext); // Access the socket from context
  const userId = useSelector(state => state.user.userId);
  const [lastTextClick, setLastTextClick] = useState(0);
  const isCompositingThumbnailRef = useRef(false);

  const getRandomBiasLarge = useCallback((min, max) => {
    const randomValue = Math.random();
    const biasedRandomValue = Math.pow(randomValue, 0.5); // Exponent < 1 biases towards higher numbers
    const scaledValue = biasedRandomValue * (max - min + 1);
    return Math.floor(scaledValue) + min;
  }, []);

  function hexAndOpacityToRgba(hex, alpha) {
    // console.log(hex)
    if (hex === undefined) {
      hex = '#fff'
    } else {
      hex = hex.replace(/^#/, '');
    }
    // Remove the hash if it's there
  
    // Convert 3-digit hex to 6-digit hex
    if (hex.length === 3) {
      hex = hex.split('').map(hexChar => hexChar + hexChar).join('');
    }
  
    // Convert hex to RGB values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    // Return the RGBA string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  const rgbaToHexAndOpacity = useCallback((rgba) => {
    if (rgba.startsWith("#")) {
      let hexColor = rgba;
      let opacity = 1;
      return { hexColor, opacity };
    } else {
      const rgbaComponents = rgba.match(/(\d+(\.\d+)?)/g).map(Number);
      const [r, g, b, a] = rgbaComponents;
      const hexColor = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
      const opacity = a !== undefined ? a : 1;  // Default opacity to 1 if not present
      return { hexColor, opacity };
    }
  }, []);

  const isLightColor = useCallback((hex) => {
    const hexToRgb = (hex) => {
      let r = 0, g = 0, b = 0;
      if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
      } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
      }
      return [r, g, b];
    };
  
    const calculateLuminance = (r, g, b) => {
      r = r / 255.0;
      g = g / 255.0;
      b = b / 255.0;
  
      r = r <= 0.03928 ? r / 12.92 : ((r + 0.055) / 1.055) ** 2.4;
      g = g <= 0.03928 ? g / 12.92 : ((g + 0.055) / 1.055) ** 2.4;
      b = b <= 0.03928 ? b / 12.92 : ((b + 0.055) / 1.055) ** 2.4;
  
      return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    };
  
    const [r, g, b] = hexToRgb(hex);
    const luminance = calculateLuminance(r, g, b);
    return luminance > 0.5;
  }, []);

  const isValidHexColor = (hex) => {
    // Regular expression to validate hex color (supports #RGB, #RRGGBB, and #RRGGBBAA formats)
    const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
    return hexRegex.test(hex);
  };

  async function getPixelWidthForText(textInput, fontSize, strokeWidth, fontFamily) {
    return new Promise(async (resolve, reject) => {
      try {
        const textTest = new fabric.Text(textInput, {
          fontSize: fontSize,
          strokeWidth: strokeWidth,
          fontFamily: fontFamily,
        });
        await fabricCanvasRef.current.add(textTest);
        let width = textTest.width 
        // fabricCanvasRef.current.renderAll();
        await fabricCanvasRef.current.remove(textTest);
        // console.log(textInput)
        // console.log(text.width)
        resolve(width);
      } catch (error) {
        reject(error);
      }
    });
  }

  const getLongestWordWidth = useCallback(async (words, fontSize, strokeWidth, fontFamily) => {
    return new Promise(async (resolve, reject) => {
      try {
        let maxWordWidth = 0;
        let wordWidthArray = [];
        for (let i = 0; i < words.length; i++) {
          let width = await getPixelWidthForText(words[i], fontSize, strokeWidth, fontFamily);
          if (width > maxWordWidth) {
            maxWordWidth = width;
          }
          wordWidthArray.push(width);
        }
        let returns = {
          maxWordWidth: maxWordWidth,
          wordWidthArray: wordWidthArray,
        };
        resolve(returns);
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const handleAddText = useCallback((options) => {
    // console.log('handleAddText')
    // console.log(options)
    // const isFontAvailable = (fontName, fontWeight) => {
    //   const canvas = document.createElement('canvas');
    //   const context = canvas.getContext('2d');
    //   context.font = `${fontWeight} 16px ${fontName}`;
    //   return context.font.includes(fontName);
    // };
    
    // if (!isFontAvailable(options.fontFamily, options.fontWeight)) {
    //   console.log('font not available')
    //   console.log(options.fontFamily)
    //   console.log(options.fontWeight)
    //   options.fontFamily = 'Arial Black'; // fallback
    //   options.fontWeight = 'normal'; // fallback
    // }

    // console.log('handleAddText');
    if (!fabricCanvasRef.current) {
        console.error("Canvas is not initialized");
        return;
    }
    // console.log(options.fontColor)
    // console.log(options.backgroundColor)
    let rgbaFontColor = hexAndOpacityToRgba(options.fontColor, options.fontOpacity) 
    let rgbaBackgroundColor = hexAndOpacityToRgba(options.backgroundColor, options.backgroundOpacity) 
    const id = uuidv4();
    let fillObj = {
        // lineHeight: options.lineSpacing,
        left: options.left,
        top: options.top,
        width: options.width,
        fontSize: options.fontSize,
        fill: rgbaFontColor,
        strokeWidth: options.strokeWidth,
        fontFamily: options.fontFamily,
        fontWeight: options.fontWeight,
        textAlign: options.textAlign,
        id: id + '_fill',
        editable: true,
        evented: true,
        shadow: {
            color: '#000000',
            offsetX: 0,
            offsetY: 0,
            blur: options.shadowBlur,
        },
        originY: 'center',  // Align text vertically based on its center
        skewY: options.skewY
    };
    let strokeColorLocal
    if (options.strokeWidth === 0) {
      strokeColorLocal = 'rgba(0, 0, 0, 0)'
    } else {
      strokeColorLocal = options.strokeColor
    }
    let strokeObj = {
        // lineHeight: options.lineSpacing,
        left: options.left,
        top: options.top,
        width: options.width,
        fontSize: options.fontSize,
        fill: 'rgba(0, 0, 0, 0)', // Ensure this is correct
        stroke: strokeColorLocal,
        strokeWidth: options.strokeWidth,
        fontFamily: options.fontFamily,
        fontWeight: options.fontWeight,
        textAlign: options.textAlign,
        selectable: false,
        evented: true,
        id: id + '_stroke',
        // skewY:-10,
        // backgroundColor: 'rgba(0, 0, 0, 0.5)'
        backgroundColor: rgbaBackgroundColor, 
        originY: 'center',  // Align text vertically based on its center
        skewY: options.skewY
    };

    let groupobj = {
        left: options.left,
        top: options.top,
        selectable: true,
        evented: true,
        id: id,
    };
    const textFillObj = new fabric.Text(options.caption, fillObj);
    const textStrokeObj = new fabric.Text(options.caption, strokeObj);
    const group = new fabric.Group([textStrokeObj, textFillObj], groupobj);

    fabricCanvasRef.current.add(group);
    fabricObjectsRef.current.set(id, group);
    const serializedGroup = group.toObject();

    const newLayer = { id, name: options.caption, object: serializedGroup };

    dispatch(addLayer(newLayer));
    fabricCanvasRef.current.renderAll();

    // group.on('mousedown', function (event) {
    //     handleTextGroupMouseDown(group, event);
    // });
  }, [fabricCanvasRef, fabricObjectsRef, dispatch]);

  const applyStrokeToImage = useCallback((image) => {
    try {
      const originalElement = image.getElement();
      if (!(originalElement instanceof HTMLImageElement)) {
        throw new Error("The provided value is not a valid image element");
      }
      const offscreenCanvas = document.createElement('canvas');
      const offscreenCtx = offscreenCanvas.getContext('2d');
      offscreenCanvas.width = originalElement.width;
      offscreenCanvas.height = originalElement.height;
      offscreenCtx.drawImage(originalElement, 0, 0);
      const imageData = offscreenCtx.getImageData(0, 0, offscreenCanvas.width, offscreenCanvas.height);
      const data = imageData.data;
      let pathData = '';
      // Simple edge detection algorithm
      for (let y = 1; y < offscreenCanvas.height - 1; y++) {
        for (let x = 1; x < offscreenCanvas.width - 1; x++) {
          const index = (y * offscreenCanvas.width + x) * 4;
          const alpha = data[index + 3];
          if (alpha > 0) {
            const leftIndex = (y * offscreenCanvas.width + (x - 1)) * 4;
            const rightIndex = (y * offscreenCanvas.width + (x + 1)) * 4;
            const topIndex = ((y - 1) * offscreenCanvas.width + x) * 4;
            const bottomIndex = ((y + 1) * offscreenCanvas.width + x) * 4;
            if (data[leftIndex + 3] === 0 || data[rightIndex + 3] === 0 || data[topIndex + 3] === 0 || data[bottomIndex + 3] === 0) {
              pathData += `M${x} ${y} L${x + 1} ${y} L${x + 1} ${y + 1} L${x} ${y + 1} Z `;
            }
          }
        }
      }
      const strokeWidth = 0;
      const path = new fabric.Path(pathData);
      path.set({
        stroke: '#FFFFFF',
        strokeWidth: strokeWidth, // Adjust stroke width here
        fill: '',
        selectable: false,
        evented: false,
        
      });
      image.set({
        left: strokeWidth / 2,
        top: strokeWidth / 2,
        shadow: {
          color: '#FFFFFF',
          offsetX: 0,
          offsetY: 0,
          blur: 50,
        },
      });
      // Adjust path position to ensure it is centered around the image
      const group = new fabric.Group([path, image], {  // Path first, then image
        left: image.left,
        top: image.top,
        selectable: true,
        evented: true,
      });
      return group;
    } catch (error) {
      console.error("Error applying stroke to image:", error);
    }
  }, []);

  const handleLoadImage = useCallback((options) => {
    let imageURL = options.imageUrl
    // const proxyURL = `http://localhost:5000/proxy?url=${encodeURIComponent(imageURL)}`;
    
    const timestamp = Date.now();
    const proxyURL = `${imageURL}?t=${timestamp}`;

    // console.log(imageURL)
    // console.log(imageURL)
    return new Promise((resolve, reject) => {
      const imgElement = new Image();
      imgElement.crossOrigin = "anonymous";
      imgElement.src = proxyURL;
      imgElement.onload = () => {
        const img = new fabric.Image(imgElement);
        if (img) {
          const group = applyStrokeToImage(img);
          if (group) {
            const id = uuidv4();
            group.set('id', id);
            group.getObjects().forEach((obj, index) => {
              obj.set('id', `${id}_obj${index}`);
            });
  
            // Set the height to 250 and adjust the width proportionally
            const targetHeight = options.targetHeight;
            const scale = targetHeight / group.height;
            group.scale(scale).setCoords();
  
            // Position the image 
            const canvasHeight = fabricCanvasRef.current.height;
            const canvasWidth = fabricCanvasRef.current.width;
            let left
            // console.log(options.targetPosition)
            if (options.targetPosition === 'left') {
              left = options.targetPadding
            } else if (options.targetPosition === 'right') {
              // console.log(options.targetPadding)
              // left = canvasWidth - (group.width * group.scaleX) - options.targetPadding
              left = canvasWidth - (group.width* group.scaleX) - (options.targetPadding)
            } else if (options.targetPosition === 'center') {
              left = (canvasWidth - group.width * group.scaleX) / 2
            }
            group.set({
              left: left, // Center horizontally
              top: canvasHeight - group.height * group.scaleY // Align at the bottom
            });
            // group.set({
            //   left: fabricCanvasRef.current.width - group.width * group.scaleX - 75,
            //   top: fabricCanvasRef.current.height - group.height * group.scaleY
            // });
  
            fabricCanvasRef.current.add(group);
            fabricObjectsRef.current.set(id, group);
            const serializedImage = group.toObject();
            dispatch(incrementObjectCount('image'));
            dispatch(addLayer({ id, name: `Image ${imageCount + 1}`, object: serializedImage }));
            fabricCanvasRef.current.renderAll();
            // console.log('image added');
            // console.log(fabricObjectsRef.current);
            resolve('success');
          } else {
            console.error("Failed to create group from image");
            reject(new Error("Failed to create group from image"));
          }
        } else {
          console.error("Failed to load image");
          reject(new Error("Failed to load image"));
        }
      };
  
      imgElement.onerror = (error) => {
        console.error("Error loading image", error);
        reject(error);
      };
    });
  }, [fabricCanvasRef, fabricObjectsRef, imageCount, dispatch, applyStrokeToImage]);

  const loadBackgroundImage = useCallback((thumbnailBackgroundJPG, retryCount = 3) => {
    return new Promise((resolve, reject) => {
      // const proxyURL = `http://localhost:5000/proxy?url=${encodeURIComponent(thumbnailBackgroundJPG)}`;
      const proxyURL = thumbnailBackgroundJPG
      // console.log(thumbnailBackgroundJPG)
      const imgElement = new Image();
      imgElement.crossOrigin = "anonymous";
  
      imgElement.onload = () => {
        const img = new fabric.Image(imgElement);
        if (img) {
          const id = uuidv4();
          const canvas = fabricCanvasRef.current;
          const canvasWidth = canvas.getWidth();
          const canvasHeight = canvas.getHeight();
  
          // Calculate scaling factors to fit the image within the canvas
          const scaleX = canvasWidth / img.width;
          const scaleY = canvasHeight / img.height;
  
          // Choose the smaller scale factor to fit the image completely within the canvas
          const scale = Math.min(scaleX, scaleY);
          
          // Apply the scaling and position the image at the top-left corner
          img.set({
            scaleX: scale,
            scaleY: scale,
            left: 0,
            top: 0,
            selectable: false, // Optionally make the background non-selectable
            evented: false, // Optionally prevent events from being triggered on the background
          });
  
          fabricCanvasRef.current.add(img);
          fabricObjectsRef.current.set(id, img);
  
          const serializedImage = img.toObject();
          dispatch(incrementObjectCount('image'));
          dispatch(addLayer({ id, name: `Background`, object: serializedImage }));
  
          fabricCanvasRef.current.renderAll();
          resolve('success');
        } else {
          console.error("Failed to load image");
          reject(new Error("Failed to load image"));
        }
      };
  
      imgElement.onerror = (error) => {
        console.error("Error loading image", error);
  
        if (retryCount > 0) {
          console.log(`Retrying... attempts left: ${retryCount - 1}`);
          setTimeout(() => {
            loadBackgroundImage(thumbnailBackgroundJPG, retryCount - 1)
              .then(resolve)
              .catch(reject);
          }, 1000);
        } else {
          console.log(error)
          // reject(new Error("Failed to load image after multiple attempts"));
        }
      };
  
      imgElement.src = proxyURL;
    });
  }, [fabricCanvasRef, fabricObjectsRef, dispatch]);
  
  const handleAddShape = (options) => {
    return new Promise((resolve, reject) => {
      const id = uuidv4();
      let objectParams = {
        left: options.left,
        top: options.top,
        fill: options.fill,
        stroke: options.stroke,
        strokeWidth: options.strokeWidth,
        shadow: options.shadow,
        id: id
      }
      if (!fabricCanvasRef.current) {
        console.error("Canvas is not initialized");
        reject("Canvas is not initialized");
        return;
      }
      let shape
      if (options.type === 'rectangle') {
        objectParams.width = options.width
        objectParams.height = options.height
        shape = new fabric.Rect(objectParams);
      } else if (options.type === 'ellipse') {
        objectParams.rx = options.rx
        objectParams.ry = options.ry
        shape = new fabric.Ellipse(objectParams);
      } else if (options.type === 'triangle') {
        objectParams.width = options.width
        objectParams.height = options.height
        shape = new fabric.Triangle(objectParams);
      } 
      fabricCanvasRef.current.add(shape);
      fabricObjectsRef.current.set(id, shape);
      const serialized = shape.toObject();
      let newLayer
      if (options.type === 'rectangle') {
        newLayer = { id, name: `Rectangle ${rectangleCount + 1}`, object: serialized };
        dispatch(incrementObjectCount('rectangle'));
      } else if (options.type === 'ellipse') {
        newLayer = { id, name: `Circle ${circleCount + 1}`, object: serialized };
        dispatch(incrementObjectCount('circle'));
      } else if (options.type === 'triangle') {
        newLayer = { id, name: `Triangle ${triangleCount + 1}`, object: serialized };
        dispatch(incrementObjectCount('triangle'));
      } 
      dispatch(addLayer(newLayer));
      resolve('success');
    });
  };

  const selectWeightedRandomItem = useCallback((items, weights) => {
    const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
    const randomNum = getRandomInteger(0, totalWeight - 1);
  
    let cumulativeWeight = 0;
    for (let i = 0; i < items.length; i++) {
      cumulativeWeight += weights[i];
      if (randomNum < cumulativeWeight) {
        return items[i];
      }
    }
  }, []);

  const handleObjectCleared = useCallback(() => {
    if (selectedObject && originalLayerIndex !== null) {
      const object = fabricObjectsRef.current.get(selectedObject);
      if (object) {
        fabricCanvasRef.current.moveTo(object, originalLayerIndex);
      }
    }
    dispatch(setSelectedObject(null));
  }, [selectedObject, originalLayerIndex, fabricObjectsRef, fabricCanvasRef, dispatch]);
  
  const identifyObjectType = useCallback((object) => {
    if (!object) return null;
  
    if (object.type === 'rect' || object.type === 'ellipse' || object.type === 'triangle' || object.type === 'line') {
      return 'shape';
    } else if (object.type === 'image') {
      return 'background';
    } else if (object.type === 'group') {
      if (object.getObjects()[0].type === 'textbox' || object.getObjects()[0].type === 'text') {
        return 'textbox';
      } else if (object.getObjects()[0].type === 'path') {
        return 'image';
      }
    }
  
    return null;
  }, []);

  const handleMouseDown = useCallback(() => {
    setIsMouseDown(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsMouseDown(false);
  }, []);

  const handleObjectSelected = useCallback((e) => {
    const object = e.selected[0];
    // let object = null;
    let type 
    if (object.type === 'group') {
      // selectedGroupRef.current = object;
      type = identifyObjectType(object)
    } else {
      type = identifyObjectType(object)
      // object = selected;
    }
    const id = object?.id;
    // console.log(object)
    // if (!object) {
    //   console.error("Selected object is not found");
    //   return;
    // }
    if (type === 'textbox' || type === 'text') {
      const textStroke = object.getObjects()[0];
      const textFill = object.getObjects()[1];
      // console.log(textFill)
      // console.log(textFill.fontWeight)
      // console.log(textFill.fontSize)
      let fillColor = textFill.fill
      let fillOpacity = 1
      let strokeColor = textStroke.stroke
      let strokeOpacity = 1
      let backgroundColor = textStroke.backgroundColor
      let backgroundOpacity = getRandomBiasLarge(70,100)
      // console.log('rgbaToHexAndOpacity from textbox stroke')
      let rgbaTranslate = rgbaToHexAndOpacity(textStroke.stroke)
      if (rgbaTranslate.opacity !== undefined && rgbaTranslate.hexColor !== '#AN') {
        strokeColor = rgbaTranslate.hexColor
        strokeOpacity = rgbaTranslate.opacity
      }
      // console.log('rgbaToHexAndOpacity from textbox fill')
      rgbaTranslate = rgbaToHexAndOpacity(textFill.fill)
      if (rgbaTranslate.opacity !== undefined && rgbaTranslate.hexColor !== '#AN') {
        fillColor = rgbaTranslate.hexColor
        fillOpacity = rgbaTranslate.opacity
      }
      rgbaTranslate = rgbaToHexAndOpacity(textStroke.backgroundColor)
      if (rgbaTranslate.opacity !== undefined && rgbaTranslate.hexColor !== '#AN') {
        backgroundColor = rgbaTranslate.hexColor
        backgroundOpacity = rgbaTranslate.opacity
      }
      // console.log(textStroke.backgroundColor)
      // console.log(backgroundColor)
      // console.log(backgroundOpacity)
      dispatch(setLeftNavTextBackgroundColor(backgroundColor));
      dispatch(setLeftNavTextBackgroundOpacity(backgroundOpacity));
      dispatch(setLeftNavStrokeColor(strokeColor));
      dispatch(setLeftNavStrokeOpacity(strokeOpacity));
      dispatch(setLeftNavFillColor(fillColor));
      dispatch(setLeftNavFillOpacity(fillOpacity));
      dispatch(setLeftNavStrokeWidth(textStroke.strokeWidth));
      dispatch(setLeftNavShadowColor(textFill.shadow.color));
      dispatch(setLeftNavShadowBlur(textFill.shadow.blur));
      dispatch(setLeftNavFontFamily(textFill.fontFamily));
      
    } else if (type === 'image') {
      const imageStroke = object.getObjects()[0];
      const imageFill = object.getObjects()[1];
      let strokeColor = imageStroke.stroke
      let strokeOpacity = 1
      let rgbaTranslate = rgbaToHexAndOpacity(imageStroke.stroke)
      if (rgbaTranslate.opacity !== undefined && rgbaTranslate.hexColor !== '#AN') {
        strokeColor = rgbaTranslate.hexColor
        strokeOpacity = rgbaTranslate.opacity
      }
      dispatch(setLeftNavStrokeColor(strokeColor));
      dispatch(setLeftNavStrokeOpacity(strokeOpacity));
      dispatch(setLeftNavStrokeWidth(imageStroke.strokeWidth));
      dispatch(setLeftNavFillOpacity(imageFill.opacity));
      let shadowColor = '#000000'
      let shadowBlur = 0
      if (imageFill.shadow) {
        shadowColor = imageFill.shadow.color
        shadowBlur = imageFill.shadow.blur
      }
      dispatch(setLeftNavShadowColor(shadowColor));
      dispatch(setLeftNavShadowBlur(shadowBlur));
    } else if (type === 'shape') {
      // Opacity needs to be reflected as an RGBA value in the canvas object. 
      // This section separates out the color and opacity 
      let fillColor = object.fill
      let fillOpacity = 1
      let strokeColor = object.stroke
      let strokeOpacity = 1
      // console.log(object.stroke)
      let rgbaTranslate = rgbaToHexAndOpacity(object.stroke)
      if (rgbaTranslate.opacity !== undefined && rgbaTranslate.hexColor !== '#AN') {
        strokeColor = rgbaTranslate.hexColor
        strokeOpacity = rgbaTranslate.opacity
      }
      rgbaTranslate = rgbaToHexAndOpacity(object.fill)
      // console.log(rgbaTranslate)
      if (rgbaTranslate.opacity !== undefined && rgbaTranslate.hexColor !== '#AN') {
        fillColor = rgbaTranslate.hexColor
        fillOpacity = rgbaTranslate.opacity
      }
      dispatch(setLeftNavStrokeColor(strokeColor));
      dispatch(setLeftNavStrokeOpacity(strokeOpacity));
      dispatch(setLeftNavStrokeWidth(object.strokeWidth));
      dispatch(setLeftNavFillColor(fillColor));
      dispatch(setLeftNavFillOpacity(fillOpacity));
      // console.log(object.shadow)
      if (object.shadow !== null) {
          // console.log(object.shadow.color)
          // console.log(object.shadow.blur)
        dispatch(setLeftNavShadowColor(object.shadow.color));
        dispatch(setLeftNavShadowBlur(object.shadow.blur));
      } else {
        dispatch(setLeftNavShadowColor("#000000"));
        dispatch(setLeftNavShadowBlur(0));
      }
    }

    dispatch(setSelectedObject(id));
    dispatch(setsSelectedObjectType(type));
  }, [dispatch, rgbaToHexAndOpacity, identifyObjectType, getRandomBiasLarge]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedUpdateLayers = useCallback(() => {
    const handler = debounce(() => {
      const canvas = fabricCanvasRef.current;
      const localLayers = store.getState().thumbnailCanvas.currentLayers; // Accessing layers from the correct slice
      const updatedLayers = canvas.getObjects().map((obj) => ({
        id: obj.id,
        name: localLayers.find(layer => layer.id === obj.id)?.name,
        object: obj.toObject(),
      }));
      dispatch(setUpdateLayers(updatedLayers));
    }, 200);
    
    return handler();
  }, [dispatch, fabricCanvasRef]);

  const canvasInit = useCallback(() => {
    // console.log('canvasInit')
    return new Promise((resolve, reject) => {
      try {
        dispatch(setNewCanvas());
        const canvas = new fabric.Canvas(canvasRef.current, {
          backgroundColor: 'white',
          preserveObjectStacking: true,
        });
        fabricCanvasRef.current = canvas;
        fabricCanvasRef.current.setWidth(1280);
        fabricCanvasRef.current.setHeight(720);
        fabricCanvasRef.current.setBackgroundColor('#ffffff')
        if (location.pathname.indexOf('/thumbnails/editor') === 0) { 
          fabricCanvasRef.current.setZoom(0.5);
        } else {
          fabricCanvasRef.current.setZoom(1);
        }
        resolve();
        canvas.on('selection:created', handleObjectSelected);
        canvas.on('selection:updated', handleObjectSelected);
        canvas.on('selection:cleared', handleObjectCleared);
        canvas.on('object:modified', debouncedUpdateLayers);
        // updateCanvasSize();
        // window.addEventListener('resize', updateCanvasSize);
        // window.addEventListener('keydown', handleKeyDown);
        document.addEventListener('mousedown', handleMouseDown, true); // Use capture mode
        document.addEventListener('mouseup', handleMouseUp, true); // Use capture mode
        return () => {
          // window.removeEventListener('resize', updateCanvasSize);
          document.removeEventListener('mousedown', handleMouseDown, true);
          document.removeEventListener('mouseup', handleMouseUp, true);
          canvas.dispose();
        };
      } catch (error) {
        reject(error); // Reject the promise if there's an error
      }
    });
  }, [
    dispatch,
    canvasRef,
    fabricCanvasRef,
    location.pathname,
    handleObjectSelected,
    handleObjectCleared,
    debouncedUpdateLayers,
    // handleKeyDown,
    handleMouseDown,
    handleMouseUp
  ]);

  // const updateCanvasSize = () => {
  //   // if (location.pathname.startsWith('/thumbnails/editor')) {
  //   // console.log('updateCanvasSize')
  //   // console.log(fabricCanvasRef.current)
  //   // if (isCompositingThumbnail) {
  //   //   fabricCanvasRef.current.setZoom(1);
  //   // } else if (location.pathname.indexOf('/thumbnails/editor') === 0) {
  //   //   console.log('resize edit')
  //   //   const aspectRatio = 1280 / 720;
  //   //   console.log(fabricCanvasRef.current)
  //   //   if (fabricCanvasRef.current) {
  //   //     fabricCanvasRef.current.setZoom(0.5);
  //   //   }
  //   // } else if (location.pathname.indexOf('/thumbnails/create') === 0) {
  //   //   // console.log('resize create')
  //   //   if (fabricCanvasRef.current) {
  //   //     fabricCanvasRef.current.setZoom(1);
  //   //     // if (fabricCanvasRef.current.setWidth)
  //   //     // fabricCanvasRef.current.setWidth(1280);
  //   //     // fabricCanvasRef.current.setHeight(720);
  //   //     // fabricCanvasRef.current.calcOffset();
  //   //     // fabricCanvasRef.current.renderAll();
  //   //   }
  //   // }
  // };

  const saveCanvas = useCallback(
    (thumbnailId, saveOutput, reason) => {
    // setSavingQueue((prevQueue) => [...prevQueue, thumbnailId]);
    savingQueueRef.current.push(thumbnailId);
    return new Promise(async (resolve, reject) => {
      try {
        const canvas = fabricCanvasRef.current;
        if (canvas) {
          canvas.setBackgroundColor('white', canvas.renderAll.bind(canvas));
        }
        if (saveOutput === 'full') {
          fabricCanvasRef.current.setZoom(1);
        }
        // Ensure all images have crossOrigin set and reload them
        await setCrossOriginAndReloadImages(canvas);
        // Render the canvas again after setting crossOrigin and reloading images
        canvas.renderAll();
        const dataUrl = canvas.toDataURL({
          format: 'jpeg',
          quality: 1.0,
          backgroundColor: 'white',
        });
        const formData = new FormData();
        formData.append('currentLayers', JSON.stringify(store.getState().thumbnailCanvas.currentLayers));
        formData.append('thumbnailId', thumbnailId);
        formData.append('saveOutput', saveOutput);
        const blob = await (await fetch(dataUrl)).blob();
        formData.append('canvasJPEG', blob);
        // console.log(blob.size);
        const response = await axios.post('/api/save-tubethumbsy-thumbnail', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const { thumbnailData } = response.data;
        // console.log('save-tubethumbsy-thumbnail done')
        // canvas.dispose();
        let timestamp = Date.now();
        thumbnailData.thumbnailJPEG = `https://tubethumbs-thumbnail-previews.s3.us-west-1.amazonaws.com/${thumbnailData.thumbnailId}.jpeg?t=${timestamp}`;
        let sessionThumbnails = store.getState().thumbnailSessions.sessionThumbnails || [];
        // Check if the thumbnailId exists in the array
        const thumbnailExists = sessionThumbnails.some(thumbnail => thumbnail.thumbnailId === thumbnailId);
        let updatedThumbnails;
        if (thumbnailExists) {
          // If it exists, update the thumbnail data
          updatedThumbnails = sessionThumbnails.map(thumbnail =>
            thumbnail.thumbnailId === thumbnailId ? { ...thumbnail, ...thumbnailData } : thumbnail
          );
        } else {
          // If it doesn't exist, push the new thumbnail data
          updatedThumbnails = [...sessionThumbnails, { thumbnailId, ...thumbnailData }];
        }
        // Dispatch the updated thumbnails
        isCompositingThumbnailRef.current = false
        dispatch(removeFromCompositingQueue(thumbnailId));
        dispatch(setSessionThumbnails(updatedThumbnails));
        savingQueueRef.current = savingQueueRef.current.filter(item => item !== thumbnailId);
        // setSavingQueue((prevQueue) => prevQueue.filter(item => item !== thumbnailId));
      // console.log('savecanvas success')
      resolve('success'); // Resolve the promise
        } catch (error) {
          console.error('Error saving canvas:', error);
          reject(error); // Reject the promise if there's an error
        }
      });
    },
    [dispatch]
  );

  const getRandomColorWithContrast = (fontColors, secondaryColors) => {
    const getRandomColor = (colors) => colors[Math.floor(Math.random() * colors.length)];
    
    const ensureContrast = (color1, color2) => {
      if (!isValidHexColor(color1) || !isValidHexColor(color2)) return false;
      return isLightColor(color1) !== isLightColor(color2);
    };
  
    const maxAttempts = 50;
    let attempts = 0;
    let fontColor = getRandomColor(fontColors);
    let secondaryColor = getRandomColor(secondaryColors);
  
    while (fontColor === secondaryColor || !ensureContrast(fontColor, secondaryColor)) {
      if (attempts >= maxAttempts) {
        return { fontColor: '#000000', backgroundColor: '#FFFFFF' }; // Default fallback
      }
      fontColor = getRandomColor(fontColors);
      secondaryColor = getRandomColor(secondaryColors);
      attempts++;
    }
    let returnObject = {
      fontColor: fontColor,
      secondaryColor: secondaryColor,
    }
    
    return returnObject;
  };
  
  const compositeThumbnail = useCallback(async (thumbnailId, thumbnailBackgroundJPG, compositeMethods, fullData) => {
    // console.log('compositeThumbnail')
    // console.log(compositeMethods.textMethod)
    const captionsArray = [
      "Boost Your Dragonfruit Harvest Today!",
      "Secrets to More Dragonfruit Yield",
    ];

    let captionParams = compositeMethods.captionParams
    if (isManual) {
      compositeMethods = {
      characterMethod: "composite",
      textMethod: "composite",
      userHasSelectedText: true,
      characterPosition: "right"
      };
      captionParams = {
        caption: captionsArray[getRandomInteger(0,captionsArray.length-1)],
        captionPosition: "center",
        fillColorHexCode: "white",
        fontColor: "black",
        primaryThemeColorHexCode: "#4CAF50",
        primaryThemeColorPlainEnglishTwoWords: "Vibrant Green",
        secondaryThemeColorHexCode: "#FF4081",
        secondaryThemeColorPlainEnglishTwoWords: "Bright Pink",
        strokeColor: "Bright Pink",
        // strokeColorHexCode: "#4CAF50",
        strokeColorHexCode: "#000",
        strokeType: "stroke"
      };
    }

    const selectedCharacterId = store.getState().thumbnailSessions.selectedCharacterId;
    let characterURL = 'https://tubethumbs-characters.s3.us-west-1.amazonaws.com/'+selectedCharacterId+'.png'
    // console.log('selectedCharacterId ' + selectedCharacterId)
    dispatch(setNewCanvas());
    canvasInit();
    await loadBackgroundImage(thumbnailBackgroundJPG);
    // console.log(compositeMethods.characterMethod)
    // console.log(compositeMethods)
    let characterPosition
    if (compositeMethods.characterMethod === 'composite') {
      if (compositeMethods.characterPosition === null || compositeMethods.characterPosition === undefined) {
        if (compositeMethods.captionParams.captionPosition === "left") {
          characterPosition = 'right'
        } else if (compositeMethods.captionParams.captionPosition === "right") {
          characterPosition = 'left'
        } else {
          // This might not be optimal but why not
          characterPosition = 'right'
        }
      } else {
        characterPosition = compositeMethods.characterPosition
      }
      let imageOptions = {
        imageUrl: characterURL,
        targetPosition: characterPosition,
        targetHeight: getRandomInteger(500, 600),
        targetPadding: getRandomInteger(10,150)
      }
      await handleLoadImage(imageOptions);
    }
    let itemsForText = ['composite','none']
    let weightsForText
    if (thumbnailId.includes("@")) {
      // Marketing use case
      weightsForText = [4,0]
    } else {
      weightsForText = [8,0]
    }
    let localTextMethod = selectWeightedRandomItem(itemsForText, weightsForText);
    // console.log(thumbnailId)
    // console.log(localTextMethod)
    if (localTextMethod === 'composite') {
      const context = fabricCanvasRef.current.getContext();
      let items = []
      let weights = []
      let strokeWidth
      let strokeColor = '#ffffff'
      let fontSize
      let fontFamily
      let fontWeight
      let capitalization
      let backgroundColor = "#000000"
      let backgroundOpacity = 0
      let fontOpacity = 1
      let lineSpacing = 1;
      let fontColor = "#FFFFFF"
      let highlightType
      let highlightColor
      let skewY = 0
      let shadowBlur = 0
      let leftPadding = getRandomBiasSmall(30, 100);
      items = ['Bangers','Lilita One', 'Squada One', 'Arial Black','Impact', 'Bebas Neue','Anton'];
      weights = [1,1,1,1,1,1,1];
      let strongFont = selectWeightedRandomItem(items, weights);
      items = ['Noto Sans Display','Indie Flower','Satisfy', 'Roboto','Lato', 'Open Sans', 'Oswald']
      weights = [1,1,1,1,1,1,1];
      let lightFont = selectWeightedRandomItem(items, weights);
      // console.log(compositeMethods.userHasSelectedText)
      if (compositeMethods.userHasSelectedText) {
        let horizontalAlign = captionParams.captionPosition
        items = ['strongLightFont','strongDarkFont', 'weakFontStroke','weakFontBackground'];
        if (horizontalAlign === 'center') {
          weights = [5,5,0,0];
        } else {
          weights = [5,5,5,0];
        }
        let template = selectWeightedRandomItem(items, weights);
        // console.log(horizontalAlign)
        // verticalAlign
        items = ['bottom', 'center', 'top'];
        if (horizontalAlign === 'center') {
          // Prevent horizontal align items at center from being at top if character is in way
          if (compositeMethods.characterMethod !== 'none') {
            if (characterPosition === 'center') {
              weights = [1,0,0];
            } else {
              weights = [5,0,1];
            }
          } else {
            weights = [2,3,1];
          }
        } else {
          weights = [2,1,1];
        }
        let verticalAlign = selectWeightedRandomItem(items, weights);
        items = ['left', 'center', 'right'];
        if (horizontalAlign === 'left') {weights = [3,1,0];} 
        else if (horizontalAlign === 'right') {weights = [3,3,1];} 
        let textAlign = selectWeightedRandomItem(items, weights);
        // maxLineWidth
        let maxLineWidth
        if (horizontalAlign === 'left' || horizontalAlign === 'right') {
          if (compositeMethods.characterPosition === 'center') {
            maxLineWidth = 1280 * (1 / getRandomInteger(250,350)) * 100
          } else {
            maxLineWidth = 1280 * (1 / getRandomInteger(250,350)) * 100
          }
            // console.log(getRandomBiasSmall(2, 3))
            // console.log(maxLineWidth)
        } else if (horizontalAlign === 'center') {
          maxLineWidth = 1280
          leftPadding = 0
        } 
        if (template.substring(0, 6) === 'strong') {
          // Applies to strongLightFont
          strokeWidth = 0;
          fontFamily = strongFont
          capitalization = 'upperCase';
          lineSpacing = getRandomInteger(100, 130)/100
          leftPadding = getRandomBiasSmall(50, 100)
          if (horizontalAlign === 'center') {
            textAlign = 'center'
            fontSize = getRandomInteger(80, 90);
          } else {
            fontSize = getRandomInteger(70, 100);
          }
          if (strongFont === 'Impact') {
            fontWeight = 'normal';
          } else {
            fontWeight = 900;
          }
          fontColor = '#FFFFFF'
          // Background
          items = ['noBackground','hasBackground'];
          weights = [10,3];
          // weights = [5,2];
          let backgroundType = selectWeightedRandomItem(items, weights);
          let primaryThemeColorHexCode
          let secondaryThemeColorHexCode
          if (isValidHexColor(captionParams.primaryThemeColorHexCode)) {
            primaryThemeColorHexCode = captionParams.primaryThemeColorHexCode
          } else {
            primaryThemeColorHexCode = '#000000'
          }
          if (isValidHexColor(captionParams.secondaryThemeColorHexCode)) {
            secondaryThemeColorHexCode = captionParams.secondaryThemeColorHexCode
          } else {
            secondaryThemeColorHexCode = '#000000'
          }
          if (backgroundType === 'hasBackground') {
            // console.log('hasBackground')
            // items = ["#000000", primaryThemeColorHexCode, secondaryThemeColorHexCode]
            // weights = [1,1,1];
            // let potentialSecondaryColors = ['#000000','#FFFFFF', captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode]
            // let potentialPrimaryColors = ['#000000', '#FFFFFF', captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode];
            // // Creates a contrasting stroke/font color mixLoop until fontColor is different from backgroundColor
            // const maxAttempts = 50; // Fail-safe to prevent infinite loop
            // let attempts = 0;
            // const getRandomColor = (colors) => {
            //   return colors[Math.floor(Math.random() * colors.length)];
            // };
            // const ensureContrast = (color1, color2) => {
            //   if (!isValidHexColor(color1)) {
            //     return false
            //   }
            //   if (!isValidHexColor(color2)) {
            //     return false
            //   }
            //   return isLightColor(color1) !== isLightColor(color2);
            // };
            // fontColor = getRandomColor(potentialPrimaryColors);
            // backgroundColor = getRandomColor(potentialSecondaryColors);
            // while (
            //   fontColor === backgroundColor || 
            //   !ensureContrast(fontColor, backgroundColor)
            // ) {
            //   if (attempts >= maxAttempts) {
            //     fontColor = '#000000'
            //     backgroundColor = '#FFFFFF'
            //   }
            //   fontColor = getRandomColor(potentialPrimaryColors);
            //   backgroundColor = getRandomColor(potentialSecondaryColors);
            //   attempts++;
            // }
            var potentialPrimaryColors = [captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode, '#000000', '#FFFFFF'];
            var potentialSecondaryColors = [captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode, '#000000', '#FFFFFF'];
            
            // Call helper function to get contrasting font and background colors
            let contrastReturns = getRandomColorWithContrast(potentialPrimaryColors, potentialSecondaryColors);
            fontColor = contrastReturns.fontColor
            backgroundColor = contrastReturns.secondaryColor
            backgroundOpacity = getRandomInteger(70,100) / 100
            strokeWidth = 0
            shadowBlur = 0
            lineSpacing = getRandomInteger(110, 140)/100
          } else {
            backgroundOpacity = 0
          }
          // Highlight logic
          items = ['none','background','font'];
          if (backgroundType === 'noBackground') {
            weights = [15,8,2];
          } else {
            weights = [5,0,1];
          }
          highlightType = selectWeightedRandomItem(items, weights);
          // console.log(highlightType)
          // if (highlightType === 'background') {
          //   items = ['#000000',primaryThemeColorHexCode, secondaryThemeColorHexCode];
          //   weights = [5,5,5];
          //   highlightColor = selectWeightedRandomItem(items, weights);
          // } else 
          if (highlightType === 'font') {
            highlightColor = '#f5f512'
          } else {
            potentialPrimaryColors = [fontColor];
            potentialSecondaryColors = [captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode, '#000000', '#FFFFFF'];
            // Call helper function to get contrasting font and background colors
            let contrastReturns = getRandomColorWithContrast(potentialPrimaryColors, potentialSecondaryColors);
            highlightColor = contrastReturns.secondaryColor
          }

          // Skew
          items = [0,-1];
          weights = [5,1];
          skewY = selectWeightedRandomItem(items, weights);
          // Overides for strongDarkFont
          if (template === 'strongDarkFont') {
            fontColor = '#000000'
            highlightType = 'none'
            // if (backgroundType === 'hasBackground') {
            items = ["#FFFFFF", '#f5f512']
            weights = [8,1];
            backgroundColor = selectWeightedRandomItem(items, weights);
            backgroundOpacity = getRandomBiasLarge(70,100)
            strokeWidth = 0
            // }
          }
          // highlightStyle
          // fontColor = '#9fe0ff'
        } else if (template.substring(0, 4) === 'weak') {
          // Applies to weakFontStroke
          strokeWidth = getRandomInteger(15, 20);
          fontFamily = lightFont
          fontWeight = 100
          leftPadding = getRandomBiasSmall(80, 140)
          items = ['firstLetterCapital','titleCase','upperCase'];
          weights = [3,10,1];
          capitalization = selectWeightedRandomItem(items, weights);
          lineSpacing = getRandomInteger(100, 110)/100
          fontSize = getRandomInteger(80, 100);
          // items = ['#FFFFFF','#000000'];
          // weights = [1,1];
          // strokeColor = selectWeightedRandomItem(items, weights);
          let potentialSecondaryColors = [captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode, '#000000','#FFFFFF']
          let potentialPrimaryColors = [captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode, '#000000', '#FFFFFF'];
          // Creates a contrasting stroke/font color mixLoop until fontColor is different from strokeColor
          // let potentialSecondaryColors = ['#000000', '#FFFFFF', captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode];
          // let potentialPrimaryColors = ['#000000', '#FFFFFF', captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode];
          
          // Call helper function to get contrasting font and background colors
          let contrastReturns = getRandomColorWithContrast(potentialPrimaryColors, potentialSecondaryColors);
          fontColor = contrastReturns.fontColor
          strokeColor = contrastReturns.secondaryColor
  
          // const maxAttempts = 50; // Fail-safe to prevent infinite loop
          // let attempts = 0;
          // const getRandomColor = (colors) => {
          //   return colors[Math.floor(Math.random() * colors.length)];
          // };
          // const ensureContrast = (color1, color2) => {
          //   if (!isValidHexColor(color1)) {
          //     return false
          //   }
          //   if (!isValidHexColor(color2)) {
          //     return false
          //   }
          //   return isLightColor(color1) !== isLightColor(color2);
          // };
          // fontColor = getRandomColor(potentialPrimaryColors);
          // strokeColor = getRandomColor(potentialSecondaryColors);
          // while (
          //   fontColor === strokeColor || 
          //   !ensureContrast(fontColor, strokeColor)
          // ) {
          //   if (attempts >= maxAttempts) {
          //     fontColor = '#000000'
          //     strokeColor = '#FFFFFF'
          //   }
          //   fontColor = getRandomColor(potentialPrimaryColors);
          //   strokeColor = getRandomColor(potentialSecondaryColors);
          //   attempts++;
          // }
          items = ['none','background','font'];
          weights = [4,1,1];
          highlightType = selectWeightedRandomItem(items, weights);

          if (highlightType === 'font') {
            // Contrast font with stroke
            potentialPrimaryColors = [strokeColor];
            potentialSecondaryColors = [captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode, '#000000', '#FFFFFF'];
            // Call helper function to get contrasting font and background colors
            contrastReturns = getRandomColorWithContrast(potentialPrimaryColors, potentialSecondaryColors);
            highlightColor = contrastReturns.secondaryColor
          } else {
            // Contrast background with font
            potentialPrimaryColors = [fontColor];
            potentialSecondaryColors = [captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode, '#000000', '#FFFFFF'];
            // Call helper function to get contrasting font and background colors
            contrastReturns = getRandomColorWithContrast(potentialPrimaryColors, potentialSecondaryColors);
            highlightColor = contrastReturns.secondaryColor
          }

          // console.log(highlightType)
          // if (highlightType === 'font') {
          //   if (fontColor === captionParams.primaryThemeColorHexCode) {
          //     highlightColor = captionParams.secondaryThemeColorHexCode
          //   } else if (fontColor === captionParams.secondaryThemeColorHexCode) {
          //     highlightColor = captionParams.primaryThemeColorHexCode
          //   } else if (fontColor === "#000000") {
          //     items = [captionParams.primaryThemeColorHexCode,captionParams.secondaryThemeColorHexCode];
          //     weights = [1,1];
          //     highlightColor = selectWeightedRandomItem(items, weights);
          //   }
          // } if (highlightType === 'background') {
          //   highlightColor = strokeColor
          // }
          // Skew
          items = [0,-1];
          weights = [5,1];
          skewY = selectWeightedRandomItem(items, weights);
          // Override for background
          if (template === 'weakFontBackground') {
            backgroundColor = strokeColor
            backgroundOpacity = getRandomBiasLarge(70,100)
            strokeWidth = 0
            shadowBlur = 0
            lineSpacing = getRandomInteger(110, 120)/100
            highlightType = 'none';
            items = ['none','font'];
            weights = [3,1];
            highlightType = selectWeightedRandomItem(items, weights);
            potentialPrimaryColors = [fontColor];
            potentialSecondaryColors = [ captionParams.primaryThemeColorHexCode, captionParams.secondaryThemeColorHexCode, '#000000', '#FFFFFF'];
            // Call helper function to get contrasting font and background colors
            contrastReturns = getRandomColorWithContrast(potentialPrimaryColors, potentialSecondaryColors);
            highlightColor = contrastReturns.secondaryColor
            // if (fontColor === captionParams.primaryThemeColorHexCode) {
            //   highlightColor = captionParams.secondaryThemeColorHexCode
            // } else if (fontColor === captionParams.secondaryThemeColorHexCode) {
            //   highlightColor = captionParams.primaryThemeColorHexCode
            // } else if (fontColor === "#000000") {
            //   items = [captionParams.primaryThemeColorHexCode,captionParams.secondaryThemeColorHexCode];
            //   weights = [1,1];
            //   highlightColor = selectWeightedRandomItem(items, weights);
            // }
          }
        }
  
        // Overrides
        if(backgroundOpacity===0 && strokeWidth === 0) {
          shadowBlur = 5
        }
  
        // document.body.style.fontFamily = fontFamily;
        
        // let fontWeight = '';
        let captionCapitalized
        switch (capitalization) {
          case 'firstLetterCapital':
            captionCapitalized = captionParams.caption.charAt(0).toUpperCase() + captionParams.caption.slice(1).toLowerCase();
            break;
          case 'titleCase':
            captionCapitalized = captionParams.caption.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
            break;
          case 'upperCase':
            captionCapitalized = captionParams.caption.toUpperCase();
            break;
          default:
            // Default case if none of the other cases match
            captionCapitalized = captionParams.caption;  // Leave the caption unchanged
            break;
        }
        let font = fontSize + 'px ' + fontFamily;
        const fontObserver = new FontFaceObserver('Roboto'); // Replace 'Roboto' with your font name

fontObserver.load().then(() => {
  // console.log('Font loaded successfully');
  // Fonts are loaded, apply any styles or state changes here if needed
}).catch((err) => {
  console.error('Font failed to load', err);
});
        // let strokeWidth = getRandomBiasSmall(10, 10);
          // console.log(thumbnailId)
          // console.log(context.measureText('pruning dragonfruit').width)
          // console.log(context.measureText('').width)
        
        context.font = font;
        // console.log(captionCapitalized)
        const words = captionCapitalized.split(' ');
        // Trims the spaces before and after individual words
        // const processWord = (word) => {
        //   if (word.trim() === '') return '';
        //   return word.replace(/\s+/g, ' ').trim();
        // };
        // const words = wordsRaw.map(processWord).filter(word => word !== '');
        
        let longestWordReturns = await getLongestWordWidth(words, fontSize, strokeWidth, fontFamily);
        let longestWordWidth = longestWordReturns.maxWordWidth
          // console.log('fontsize before ' + fontSize)
        while (longestWordWidth > maxLineWidth && fontSize > 0) {
          fontSize-=3;
          font = fontSize + 'px ' + fontFamily;
          context.font = font;
          longestWordReturns = await getLongestWordWidth(words, fontSize, strokeWidth, fontFamily);
          longestWordWidth = longestWordReturns.maxWordWidth
        }
        let wordWidthArray = longestWordReturns.wordWidthArray
        let totalTextHeight;
        let lineWidths = []
        let lines = [];
        let averageLetterWidth
        do {
          // Reset font size and context font
          font = fontSize + 'px ' + fontFamily;
          context.font = font;
          let currentLineLength = 0;
          let splitIndex = [];
          // console.log(words)
          let totalLetters = 0
          let totalWordWidth = 0
          for (let i=0; i<words.length;i++) {
            totalLetters += words[i].length
            totalWordWidth += wordWidthArray[i]
          }
          averageLetterWidth = totalWordWidth / totalLetters
          // Creates an index of where to split words. lineWidths is an array of the width of each line
  
          for (let i = 0; i < words.length; i++) {
            // let testLineLength = wordWidthArray[i] + currentLineLength;
            let testLineLength = wordWidthArray[i] + averageLetterWidth + currentLineLength;
            // console.log(words[i])
            // console.log(testLineLength)
            // console.log(wordWidthArray[i])
            // console.log(averageLetterWidth)
            // console.log(currentLineLength)
            // console.log(lineWidths)
            if (testLineLength > maxLineWidth) {
              lineWidths.push(currentLineLength)
              splitIndex.push(i);
              currentLineLength = wordWidthArray[i]; // Start new line with the current word's width
            } else {
              currentLineLength = testLineLength;
            }
            // Function to sum an array
            const sumArray = (array) => array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            // Accounts for lsat word as the difference between the array lengths
            if (i === words.length - 1) {
                const totalWordWidth = sumArray(wordWidthArray);
                const totalLineWidth = sumArray(lineWidths);
                lineWidths.push(totalWordWidth - totalLineWidth);
            }
          }
          if (!splitIndex.includes(words.length)) {
            splitIndex.push(words.length);
          }
          let start = 0;
          for (let i = 0; i < splitIndex.length; i++) {
              const end = splitIndex[i];
              const lineWords = words.slice(start, end);
              lines.push(lineWords.join(' '));
              start = end;
          }
          // Add the last line if it's not already added
          if (start < words.length) {
              const lineWords = words.slice(start);
              lines.push(lineWords.join(' '));
          }
          // Calculate the total height based on the current font size and stroke width
          var totalLineHeight = (fontSize + strokeWidth) * lineSpacing;
          totalTextHeight = lines.length * totalLineHeight;
          // If the total height exceeds 650, decrease the font size and recalculate
          if (totalTextHeight > 650) {
            fontSize -= 3;
          }
        } while (totalTextHeight > 650 && fontSize > 0);
        
        // One more pass at accurate line widths
        let actualLineMaxWidth = 0
        for (let i = 0; i<lines.length; i++) {
          let width = await getPixelWidthForText(lines[i], fontSize, strokeWidth, fontFamily)
          lineWidths[i] = width
          // console.log(width)
          actualLineMaxWidth = Math.max(actualLineMaxWidth,width)
        }
        // console.log(lineWidths)
        items = ['none','bottom', 'top', 'firstHalf','lastHalf'];
        // weights = [0,1,1,1,1];
        weights = [0,1,1,1,1];
        let highlightMethod = selectWeightedRandomItem(items, weights);
        let highlightStartEnd = [-1,-1]
        if (highlightMethod === 'bottom') {highlightStartEnd = [lines.length-1,lines.length-1]}
        else if (highlightMethod === 'top') {highlightStartEnd = [0,0]}
        else if (highlightMethod === 'firstHalf') {highlightStartEnd = [0,Math.floor(lines.length/2)-1]}
        else if (highlightMethod === 'lastHalf') {highlightStartEnd = [Math.floor(lines.length/2)-1,lines.length-1]}
    
        // Calculate top position based on vertical alignment setting
        let topMargin;
        if (verticalAlign === "top") {
          topMargin = leftPadding;
        } else if (verticalAlign === "center") {
          topMargin = (720 - totalTextHeight) / 2;
        } else if (verticalAlign === "bottom") {
          topMargin = 720 - (totalLineHeight * lines.length) - leftPadding;
          // topMargin = 720 - totalTextHeight - leftPadding;
        }
        
        // Position textboxes with selected horizontal alignment
        let fontColorOriginal = fontColor
        let strokeColorOriginal = strokeColor
        let lineWidth
        let compositeParams = {
          thumbnailId: thumbnailId,
          fullData: fullData,
          horizontalAlign: horizontalAlign,  // This is set based on captionPosition or center
          verticalAlign: verticalAlign,  // top, center, bottom based on selection
          template: template,  // strongLightFont, strongDarkFont, weakFontStroke, weakFontBackground
          maxLineWidth: maxLineWidth,  // Calculated based on the alignment and character position
          leftPadding: leftPadding,  // Randomly generated padding
          fontColor: fontColorOriginal,  // The original font color before any highlight modifications
          strokeColor: strokeColorOriginal,  // The original stroke color before any highlight modifications
          highlightColor: highlightColor,  // The original stroke color before any highlight modifications
          fontFamily: fontFamily,  // Selected font family
          highlightType: highlightType,  // none, bottom, top, firstHalf, lastHalf
          lines: lines,
          lineWidths: lineWidths
        };
        // console.log(compositeParams)
        // removes blank lines, sometimes there's a highlight that shows up without any text on it otherwise
        // lines.reverse().filter(line => line.trim() !== '' || lines.some(l => l.trim() !== ''));
  //       let lastNonEmptyIndex = lines.length - 1;
  // // Traverse backwards to find the last line that isn't just spaces
  //         while (lastNonEmptyIndex >= 0 && lines[lastNonEmptyIndex].trim() === '') {
  //           lastNonEmptyIndex--;
  //         }
        let localLines = []
        for (let i=0; i<lines.length;i++) {
          if (lines[i] !== ' ') {
            localLines.push(lines[i])
          }
        }
        lines = localLines
        for (const [index, line] of lines.entries()) {
        // lines.forEach(async (line, index) => {
            if (horizontalAlign === 'center') {
              lineWidth = lineWidths[index] + averageLetterWidth
            } else {
              // Shrinks width to length of words in the line. Adds adequate buffers off the edge
              lineWidth = lineWidths[index] + averageLetterWidth 
            }
            if (highlightType !== 'none' && lines.length>1) {
              if (index >= highlightStartEnd[0] && index <= highlightStartEnd[1]) {
                if (highlightType === 'background') {
                  backgroundColor = highlightColor
                  backgroundOpacity = getRandomBiasLarge(70,100)
                  // console.log('trigger bg change')
                } else if (highlightType === 'font') {
                  fontColor = highlightColor
                } else if (highlightType === 'stroke') {
                fontWeight = 500
                strokeColor = highlightColor
                }
              } else{
                // Need this even in false case to change the default back
                  if (highlightType === 'font') {
                    fontColor = fontColorOriginal
                  } else if (highlightType === 'stroke') {
                    strokeColor = strokeColorOriginal
                  } else if (highlightType === 'stroke') {
                    backgroundOpacity = 0
                  }
              }
            }
    
            let leftPosition;
            if (horizontalAlign === "left") {
              leftPosition = leftPadding;
            } else if (horizontalAlign === "center") {
              leftPosition = (1280 - lineWidths[index]) / 2;
            } else if (horizontalAlign === "right") {
              // totalLineHeight ensures a buffer
              let rightPadding = Math.max(leftPadding,totalLineHeight)
              // leftPosition = 1280 - actualLineMaxWidth - rightPadding;
              leftPosition = 1280 - actualLineMaxWidth - rightPadding;
            }
            // console.log(actualLineMaxWidth)
            // console.log(fontFamily)
            // console.log(topMargin + (index * totalLineHeight))
            // console.log('maxLineWidth ' + maxLineWidth)
            // console.log('measureText(line).width ' + context.measureText(line).width)
            // console.log('leftPosition ' + leftPosition)
            // console.log(line)
            let options = {
              thumbnailId: thumbnailId,
              left: leftPosition,
              top: topMargin + (index * totalLineHeight),
              // top: topMargin + (index * (fontSize + (strokeWidth * 2)) * lineSpacing),
              width: lineWidth,
              fontSize: fontSize,
              fontFamily: fontFamily,
              fontWeight: fontWeight,
              fontOpacity: fontOpacity,
              strokeWidth: strokeWidth,
              backgroundColor: backgroundColor,
              backgroundOpacity: backgroundOpacity,
              // lineSpacing: lineSpacing,
              highlightType: highlightType,
              highlightColor: highlightColor,
              textAlign: textAlign,
              caption: line,
              fontColor: fontColor,
              strokeColor: strokeColor,
              skewY: skewY,
              shadowBlur: shadowBlur,
              // These don't impact anything, for logging only
              maxLineWidth: maxLineWidth,
              template: template,
              horizontalAlign: horizontalAlign,
              verticalAlign: verticalAlign,
              leftPadding: leftPadding
            };
            // console.log(options)
            await handleAddText(options);
          };
        }
      }
    saveCanvas(thumbnailId, 'preview', 'composite');
  }, [dispatch, canvasInit, getLongestWordWidth, handleAddText, handleLoadImage, getRandomBiasLarge, isLightColor, isManual, loadBackgroundImage, saveCanvas, selectWeightedRandomItem]);

  const manageThumbnailCompositingQueue = useCallback(async () => {
    const compositingQueue = store.getState().thumbnailSessions.compositingQueue;
    const sessionThumbnails = store.getState().thumbnailSessions.sessionThumbnails; // Retrieve sessionThumbnails from store
  
    if (!isCompositingThumbnailRef.current) {
      for (const data of compositingQueue) {
        const isInSavingQueue = savingQueueRef.current.includes(data.thumbnailId);
        if (isInSavingQueue) {
          console.log("already saving");
          dispatch(removeFromCompositingQueue(data.thumbnailId));
          return;
        }
  
        // Check if the thumbnail status in sessionThumbnails is consistent
        const thumbnail = sessionThumbnails.find(thumbnail => thumbnail.thumbnailId === data.thumbnailId);
        if (thumbnail) {
          if (thumbnail.thumbnailStatus === 'composited') {
            // console.log("Thumbnail status mismatch, removing...");
            dispatch(removeFromCompositingQueue(data.thumbnailId));
          }
        }
  
        if (data.thumbnailStatus === 'generated' && data.compositeMethods !== undefined) {
          isCompositingThumbnailRef.current = true;
          // Proceed with compositing thumbnail
          await compositeThumbnail(data.thumbnailId, data.thumbnailBackgroundJPG, data.compositeMethods, data);
          return;
        } else if (data.thumbnailStatus === 'generated' && data.compositeMethods === undefined) {
          dispatch(removeFromCompositingQueue(data.thumbnailId));
          const timestamp = new Date().getTime();
          const response = await fetch(`https://tubethumbs-thumbnail-params-jsons.s3.us-west-1.amazonaws.com/${data.thumbnailId}.json?t=${timestamp}`);
          const result = await response.json();
          dispatch(addCompositingQueue(result));
        }
        if (data.thumbnailStatus === 'composited') {
          dispatch(removeFromCompositingQueue(data.thumbnailId));
        }
      }
    }
  }, [dispatch, compositeThumbnail]);

  const handleThumbnailReady = useCallback((data) => {
    // console.log(data)
    if (data.message === 'success') {
      dispatch(addCompositingQueue(data))
    } else {
      console.log('failed, trying again')
      console.log(data)
    }
  }, [dispatch]);

  useEffect(() => {
    if (userId && socket) {
      // Define a wrapper function to log the incoming data
      const handleThumbnailReadyWithLogging = (data) => {
        console.log(data); // Log the incoming data
        handleThumbnailReady(data); // Call the original handler
      };
  
      // Set up the event listener with logging
      socket.on('thumbnailReady', handleThumbnailReadyWithLogging);
  
      // Cleanup function to remove the event listener
      return () => {
        socket.off('thumbnailReady', handleThumbnailReadyWithLogging);
      };
    }
  }, [userId, socket, handleThumbnailReady]);

  const initGenerationChecker = () => {
    if (!generationIntervalChecker) {
      const id = setInterval(() => {
        checkGeneratingQueue()
      }, 10000); 
      setGenerationIntervalChecker(id);
    }
  }

  const checkGeneratingQueue = useCallback(debounce(() => {
    // console.log('checkGeneratingQueue')
    let generatingQueue = store.getState().thumbnailSessions.generatingQueue;
    const sessionThumbnails = store.getState().thumbnailSessions.sessionThumbnails;
    const userId = store.getState().user.userId;
    if (generatingQueue.length > 0) {
      // console.log('checkGeneratingQueue')
      // console.log(generatingQueue)
      generatingQueue.forEach(async element => {
        const thumbnail = sessionThumbnails.find(thumbnail => thumbnail.thumbnailId === element.thumbnailId);
        if (thumbnail) {
          if (thumbnail.thumbnailStatus === "generated" || thumbnail.thumbnailStatus === "composited") {
            dispatch(removeFromGeneratingQueue(element.thumbnailId));
          } else if (thumbnail.thumbnailStatus === 'generating') {
            let timeFromStart
            if (element.serverRetrievalTime) {
              // If page is reloading, will get this from api
              const timeDifference = element.clientRetrievalTime - element.serverRetrievalTime;
              timeFromStart = Date.now() - timeDifference - element.thumbnailCreationTime;
            } else (
              // Otherwise use client side tiem from when the thumb was created
              timeFromStart = Date.now() - element.clientThumbnailCreationTime
            )
            // console.log(timeFromStart)
            // console.log(timeFromStart)
            if (timeFromStart > 45000 && userId && selectedSessionId && selectedSessionId!=='new') {
              console.log('trigger compositing queue check')
              manageThumbnailCompositingQueue()
              console.log('trigger api generation check')
              try {
                // Trigger the API call
                const response = await axios.post('/api/check-tubethumbsy-generation', {
                  userId,  // Replace with the appropriate user ID
                  selectedSessionId,  // Replace with the appropriate session ID
                  // generateReason: 'regenerate',
                  thumbnailId: element.thumbnailId  // Pass the thumbnail ID for regeneration
                });
                  console.log(response.data)
                if (response.data.thumbnailStatus === 'regenerate') {
                  console.log('regenerating')
                  let generateReason = 'regenerate'
                  // removeFromGeneratingQueue(element.thumbnailId)
                  let thumbnailLocal = { ...element, thumbnailCreationTime: response.data.currentTime };
                  const response2 = await axios.post('/api/generate-tubethumbsy', { 
                    userId, 
                    generateReason,
                    selectedSessionId,
                    thumbnailId: response.data.thumbnailId, 
                    regenerationAttempts: response.data.regenerationAttempts, 
                  });
                  updateGeneratingQueue(thumbnailLocal);
                } else if (response.data.thumbnailStatus === 'generated') {
                  console.log('generated, removing from queue')
                  handleThumbnailReady(response.data)
                  dispatch(removeFromGeneratingQueue(element.thumbnailId));
                  removeFromGeneratingQueue(element.thumbnailId)
                }
                // console.log('Thumbnail regeneration triggered');
              } catch (error) {
                console.error('Error triggering thumbnail regeneration:', error);
              }
              dispatch(removeFromGeneratingQueue(element.thumbnailId));
            } else {
              // console.log('not 20s yet for api generation check')
            }
          }
        }
      });
    } else {
      // console.log('clear generationIntervalChecker')
      clearInterval(generationIntervalChecker);  // Clear the interval
      setGenerationIntervalChecker(null); 
    }
  }, 1000), [dispatch]);

  const getSessions = useCallback(async () => {
    try {
      const response = await axios.post('/api/get-tubethumbsy-sessions', { userId });
      dispatch(setSessions(response.data.sessions));
      // if (!response.data.hasSessions) {
      //   dispatch(setCheckHasSessions(false));
      // } else {
      // Confirm API call has completed
      dispatch(setCheckHasSessions(true));
      // }
      return response.data.hasSessions;
    } catch (error) {
      console.error('Error fetching sessions:', error);
      return false;
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (userId) {
      getSessions()
    }
  }, [userId, getSessions]);

  useEffect(() => {
    // console.log('compositingQueue')
    if (compositingQueue && compositingQueue.length > 0) {
      manageThumbnailCompositingQueue()
    }
  }, [compositingQueue])

  useEffect(() => {
    if (generatingQueue && generatingQueue.length > 0) {
      checkGeneratingQueue(generatingQueue)
    }
  }, [generatingQueue, checkGeneratingQueue])

  // const checkGeneratingQueue = () => {
  //   // console.log('checkGeneratingQueue')
  //   let generatingQueue = store.getState().thumbnailSessions.generatingQueue; // Accessing layers from the correct slice
  //   // console.log(generatingQueue)
  //   const sessionThumbnails = store.getState().thumbnailSessions.sessionThumbnails; 
  //   generatingQueue.forEach(async element => {
  //     const thumbnail = sessionThumbnails.find(thumbnail => thumbnail.thumbnailId === element.thumbnailId); 
  //     // console.log(thumbnail)
  //     if (thumbnail.thumbnailStatus === "generated" || thumbnail.thumbnailStatus === "composited") {
  //       // console.log('removing ' + thumbnail.thumbnailId + 'due to completion')
  //       dispatch(removeFromGeneratingQueue(element.thumbnailId))
  //     }
  //     if (thumbnail.thumbnailStatus === 'generating') {
  //       // Account for differences in client and server time
  //       // If client is 0:05, server is 0:00, difference = 5
  //       const timeDifference = element.clientRetrievalTime - element.serverRetrievalTime
  //       // If now 0:50, should rebalance to 0:45
  //       const timeFromStart = Date.now() - timeDifference - element.thumbnailCreationTime;
  //       // console.log('timeFromStart ' + timeFromStart)
  //       const selectedCharacterId = store.getState().thumbnailSessions.selectedCharacterId; // Accessing layers from the correct slice
  //       // Check if the time difference is greater than 45 seconds (45000 milliseconds)
  //       if (timeFromStart > 45000) {
  //         triggerRegeneration(element.thumbnailId, selectedCharacterId, element.thumbnailPremises, element.thumbnailCaptions)
  //         dispatch(removeFromGeneratingQueue(element.thumbnailId))
  //       // 5s prevents a fresh run from triggering this
  //       }
  //     }
  //   })
  //   // Refresh it to check again
  //   generatingQueue = store.getState().thumbnailSessions.generatingQueue; // Accessing layers from the correct slice
  // }

  useEffect(() => {
    if (fabricCanvasRef.current && lastTextClick) {
      fabricCanvasRef.current.on('mouse:down', function (event) {
        let type = identifyObjectType(event.target);
            console.log(' click')
            console.log(type)
            if (type === 'textbox') {
              let currentTime = new Date().getTime();
              if ((currentTime - lastTextClick) < 300) {
                console.log('double click')
                dispatch(setCurrentTextGroup(event.target));
                dispatch(setNewText(event.target.getObjects()[1].text));
                dispatch(setIsTextInputModalOpen(true));
              }
              setLastTextClick(currentTime)
            }
      });
    }
    return () => {
      if (fabricCanvasRef.current) {
        fabricCanvasRef.current.off('mouse:down');
      }
    };
  }, [dispatch, lastTextClick, identifyObjectType]);
  
  // const handleKeyDown = (e) => {
  //   // if (e.key === 'Delete' || e.key === 'Backspace') {
  //   //   handleDeleteObject();
  //   // } else if (e.ctrlKey && e.key === 'c') {
  //   //   handleCopy();
  //   // } else if (e.ctrlKey && e.key === 'v') {
  //   //   handlePaste();
  //   // } else if (e.ctrlKey && e.key === 'z') {
  //   //   dispatch(undo());
  //   // } else if (e.ctrlKey && e.key === 'y') {
  //   //   dispatch(redo());
  //   // }
  // };

  
  // This is needed otherwise the crossorigin gets all screwed up upon saving the canvas
  const setCrossOriginAndReloadImages = async (canvas) => {
    const loadImage = (img) => {
      return new Promise((resolve, reject) => {
        const src = img.getSrc();
  
        // Append a timestamp query parameter to force the browser to bypass cache
        const timestamp = Date.now();
        const cacheBustedSrc = `${src}?t=${timestamp}`;
  
        const newImgElement = new Image();
        newImgElement.crossOrigin = "Anonymous";
        newImgElement.src = cacheBustedSrc; // Use the cache-busted URL
        newImgElement.onload = () => {
          img.setElement(newImgElement);
          resolve(img);
        };
        newImgElement.onerror = (error) => {
          console.error("Error loading image:", error);
          reject(error);
        };
      });
    };
  
    const traverseAndReload = async (obj) => {
      if (obj.type === 'image') {
        await loadImage(obj);
      }
      if (obj.type === 'group' || obj.type === 'activeSelection') {
        for (const child of obj._objects) {
          await traverseAndReload(child);
        }
      }
    };
  
    const objects = canvas.getObjects();
    for (const obj of objects) {
      await traverseAndReload(obj);
    }
  };

  function getRandomInteger(min, max) {
    const range = max - min + 1;
    const randomBuffer = new Uint32Array(1);
    window.crypto.getRandomValues(randomBuffer);
    const randomNumber = randomBuffer[0] / (0xffffffff + 1); // Normalize to [0, 1)
    return Math.floor(randomNumber * range) + min;
  }

  function getRandomBiasSmall(min, max) {
    // Generate a random number between 0 and 1, and square it to bias toward lower numbers
    let randomFactor = Math.random() ** 5;
    // Scale the random number to the desired range
    return Math.floor(min + (max - min) * randomFactor);
  }

  // function getContrastYIQ(hexColor) {
  //   // Remove the hash at the start if it's there
  //   hexColor = hexColor.replace(/^#/, '');

  //   // Parse the hex color to get the RGB values
  //   let r = parseInt(hexColor.substr(0, 2), 16);
  //   let g = parseInt(hexColor.substr(2, 2), 16);
  //   let b = parseInt(hexColor.substr(4, 2), 16);

  //   // Calculate the luminance
  //   let yiq = (r * 299 + g * 587 + b * 114) / 1000;

  //   // If the luminance is high, use black text; otherwise, use white text
  //   return yiq >= 128 ? 'black' : 'white';
  // }
  
  

  return (
    <>
      {location.pathname.startsWith('/thumbnails/editor') && (
        <ThumbnailEditor 
          canvasRef={canvasRef}
          fabricObjectsRef={fabricObjectsRef}
          fabricCanvasRef={fabricCanvasRef}
          debouncedUpdateLayers={debouncedUpdateLayers}
          identifyObjectType={identifyObjectType}
          handleLoadImage={handleLoadImage}
          handleAddShape={handleAddShape}
          handleAddText={handleAddText}
          saveCanvas={saveCanvas}
          canvasInit={canvasInit} // Ensure props are passed even in conditional rendering
        />
      )}
      {
        location.pathname.startsWith('/thumbnails/create') && 
        <ThumbnailCreateUI 
          manageThumbnailCompositingQueue={manageThumbnailCompositingQueue} 
          saveCanvas={saveCanvas}
          // triggerRegeneration = {triggerRegeneration}
          getSessions={getSessions}
          initGenerationChecker = {initGenerationChecker}
        />
      }
      {
        location.pathname.startsWith('/thumbnails/settings') && 
        <ThumbnailSettings
        />
      }
    </>
  );
};

export default ThumbnailCreate;
