import React, { useState, useEffect, useRef, useMemo } from 'react';
import './ThumbnailHome.css'; // Import CSS for animations
import { useLocation } from 'react-router-dom';

const ThumbnailHomeHeroAnimation = () => {
  const [animationPhase, setAnimationPhase] = useState(0);
  const [loading, setLoading] = useState(true);
  const inputRef = useRef(null);
  const animationIndexRef = useRef(0); // Use ref for animationIndex
  const [isHome, setIsHome] = useState(0);
  const timeoutsRef = useRef([]);
  const intervalsRef = useRef([]);
  const location = useLocation();

  const textArray = useMemo(() => [
    "How to fix a flat bicycle tire",
    "K-pop music video drop reaction! First watch",
    "My side hustle now brings in $25K/month",
    "Top 10 Travel Destinations for 2025",
  ], []);

  const selfieArray = [
    "thumbnail-animation-1.jpg",
    "thumbnail-animation-2.jpg",
    "thumbnail-animation-3.jpg",
    "thumbnail-animation-4.jpg",
  ];

  const images = useMemo(() => [
    [
      'thumbnail-animation-1-1.jpg',
      'thumbnail-animation-1-3.jpg',
      'thumbnail-animation-1-4.jpg',
      'thumbnail-animation-1-5.jpg',
      'thumbnail-animation-1-2.jpg',
    ],
    [
      'thumbnail-animation-2-1.jpg',
      'thumbnail-animation-2-2.jpg',
      'thumbnail-animation-2-5.jpg',
      'thumbnail-animation-2-4.jpg',
      'thumbnail-animation-2-3.jpg',
    ],
    [
      'thumbnail-animation-3-1.jpg',
      'thumbnail-animation-3-2.jpg',
      'thumbnail-animation-3-4.jpg',
      'thumbnail-animation-3-5.jpg',
      'thumbnail-animation-3-3.jpg',
    ],
    [
      'thumbnail-animation-4-1.jpg',
      'thumbnail-animation-4-2.jpg',
      'thumbnail-animation-4-4.jpg',
      'thumbnail-animation-4-5.jpg',
      'thumbnail-animation-4-3.jpg',
    ],
  ], []);

  const [animationCurrentImages, setAnimationCurrentImages] = useState(images[0]);

  const clearAllTimeoutsAndIntervals = () => {
    timeoutsRef.current.forEach(clearTimeout);
    intervalsRef.current.forEach(clearInterval);
    timeoutsRef.current = [];
    intervalsRef.current = [];
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setIsHome(true);
    } else {
      clearAllTimeoutsAndIntervals();
      setIsHome(false);
    }
    if (location.pathname !== '/' && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [location.pathname]);

  useEffect(() => {
    // Preload images and set to false once all are loaded
    const preloadImages = async () => {
      const flattenedImages = images.flat();
      const promises = flattenedImages.map((src) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
        });
      });
      await Promise.all(promises);
      setLoading(false);
      setAnimationPhase(1); // Start animation after everything has loaded
    };

    preloadImages();

    return () => {
      clearAllTimeoutsAndIntervals();
    };
  }, [images]);

  useEffect(() => {
    if (isHome && animationPhase === 1 && inputRef.current && location.pathname === '/') {
      const typingDelay = setTimeout(() => {
        let text = textArray[animationIndexRef.current];
        let index = 0;
        const typingInterval = setInterval(() => {
          if (inputRef.current) { // Add this check
            inputRef.current.value = text.slice(0, index + 1);
          }
          index += 1;
          if (index === text.length) {
            clearInterval(typingInterval);
            setTimeout(() => setAnimationPhase(2), 500);
          }
        }, 30);
        intervalsRef.current.push(typingInterval);
      }, 500);
  
      timeoutsRef.current.push(typingDelay);
  
      return () => clearTimeout(typingDelay);
    }
  }, [animationPhase, isHome, textArray]);

  useEffect(() => {
    if (isHome && animationPhase === 2 && location.pathname === '/') {
      const moveInputTimeout = setTimeout(() => {
        setAnimationPhase(2.5);
      }, 200); // Timing to move the input
      setAnimationCurrentImages(images[animationIndexRef.current]);

      timeoutsRef.current.push(moveInputTimeout);

      return () => clearTimeout(moveInputTimeout);
    }
  }, [animationPhase, images, isHome, location.pathname]);

  useEffect(() => {
    if (isHome && animationPhase === 2.5 && location.pathname === '/') {
      const hideWhiteDivTimeout = setTimeout(() => {
        setAnimationPhase(3);
      }, 2400); // 3-second delay for the white div

      timeoutsRef.current.push(hideWhiteDivTimeout);

      return () => clearTimeout(hideWhiteDivTimeout);
    }
  }, [animationPhase, isHome, location.pathname]);

  useEffect(() => {
    if (isHome && animationPhase === 3 && location.pathname === '/') {
      const waitForImagesTimeout = setTimeout(() => {
        setAnimationPhase(4);
      }, 3000); // Adjust this timing to match the duration of image animation

      timeoutsRef.current.push(waitForImagesTimeout);

      return () => clearTimeout(waitForImagesTimeout);
    }
  }, [animationPhase, isHome, location.pathname]);

  // Return input to the middle, backspace, and input new text
  useEffect(() => {
    if (location.pathname === '/') {
      if (isHome && animationPhase === 4 && inputRef && inputRef.current) {
        let text = textArray[animationIndexRef.current];
        let index = text.length;
        const backspaceInterval = setInterval(() => {
          if (inputRef.current && location.pathname === '/') {
            inputRef.current.value = text.slice(0, index);
            index -= 1;
            if (index < 0) {
              clearInterval(backspaceInterval);
              setTimeout(() => {
                let nextAnimationIndex;
                if (animationIndexRef.current === textArray.length - 1) {
                  nextAnimationIndex = 0;
                } else {
                  nextAnimationIndex = animationIndexRef.current + 1;
                }
                animationIndexRef.current = nextAnimationIndex; // Update the ref value
                let newText = textArray[animationIndexRef.current];
                let newIndex = 0;
                const typingInterval = setInterval(() => {
          if (inputRef.current && location.pathname === '/') {
                  inputRef.current.value = newText.slice(0, newIndex + 1);
                  newIndex += 1;
                  if (newIndex === newText.length) {
                    clearInterval(typingInterval);
                    setTimeout(() => setAnimationPhase(2), 500);
                  }
                }
                }, 30);
                intervalsRef.current.push(typingInterval);
              }, 900); // Small pause before typing new text
            }
          }
        }, 10); // Speed of backspacing
        intervalsRef.current.push(backspaceInterval);
      }
    }
  }, [animationPhase, location.pathname, isHome, textArray]);

  if (loading) {
    return null; // Don't render anything until everything is loaded
  }

  // const getRandomSpeed = () => {
  //   const baseDuration = 3; // Base duration is 4 seconds
  //   const variation = 1;
  //   return baseDuration * variation; // Adjusted duration
  // };

  return (
    <div className="animation-container">
      <div className={`input-container ${animationPhase >= 2 && animationPhase < 4 ? 'input-move-up' : ''} ${animationPhase === 4 ? 'input-back-to-middle' : ''} ${animationPhase >= 5 && animationPhase <= 6 ? 'input-move-up' : ''} ${animationPhase >= 7 ? 'input-back-to-middle' : ''}`}>
        <input
          type="text"
          ref={inputRef}
          placeholder="Your YouTube Video Title"
          className="animated-input"
          readOnly
        />
      </div>
      {animationPhase === 2.5 && (
        <div className="white-popup-div">
          <div>
            <img src={selfieArray[animationIndexRef.current]} alt="Sample" />
          </div>
          <div className="white-popup-div-text">
            Upload a photo
          </div>
        </div>
      )}
      {animationPhase >= 3 && animationPhase < 6 && (
        <div className="image-container">
          <img
            key={1}
            className={`animated-image early1 image-pos-1 delay-0`}
            src={animationCurrentImages[0]}
            alt="Sample"
            style={{
              animationDuration: `3s`, // Apply random speed
            }}
          />
          <img
            key={2}
            className={`animated-image early2 image-pos-2 delay-1`}
            src={animationCurrentImages[1]}
            alt="Sample"
            style={{
              animationDuration: `3s`, // Apply random speed
            }}
          />
          <img
            key={3}
            className={`animated-image early3 image-pos-3 delay-2`}
            src={animationCurrentImages[2]}
            alt="Sample"
            style={{
              animationDuration: `3s`, // Apply random speed
            }}
          />
          <img
            key={4}
            className={`animated-image early4 image-pos-4 delay-3`}
            src={animationCurrentImages[3]}
            alt="Sample"
            style={{
              animationDuration: `2s`, // Apply random speed
            }}
          />
          <img
            key={5}
            className={`animated-image early5 image-pos-5 delay-4`}
            src={animationCurrentImages[4]}
            alt="Sample"
            style={{
              animationDuration: `2s`,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ThumbnailHomeHeroAnimation;
